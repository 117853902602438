import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";

export const TextareaDiv = (props: {
  pageTitle?: string;
  buttonTitle?: string;
  inputTitle: string;
  buttonOnClickFunction?: Function;
  inputOnChangeFunction: Function;
  inputText: string;
  inputTextCount?: number;
  inputMaxTextCount?: number;
  disableButton?: boolean;
}) => {
  const {
    pageTitle,
    buttonTitle,
    inputTitle,
    buttonOnClickFunction,
    inputOnChangeFunction,
    inputText,
    inputTextCount,
    disableButton,
  } = props;
  return (
    <div>
      {/* <Form.Label htmlFor="input-div"><p className='h5'><b>{inputTitle}</b> </p></Form.Label> */}
      <InputGroup className="mb-3">
        <Form.Control
          id="input-div"
          as="textarea"
          rows={8}
          onChange={(e) => {
            inputOnChangeFunction(e.target.value);
          }}
          value={inputText}
        />
      </InputGroup>
      {inputTextCount !== undefined && pageTitle === "summaryForKids" && (
        <Row style={{ marginBottom: "1.2rem" }}>
          <Col>
            {inputTextCount} / {"1500"}
          </Col>
        </Row>
      )}
      {inputTextCount !== undefined &&
        (pageTitle === "learningByQuestion" ||
          pageTitle === "questionShortAnswer") && (
          <Row style={{ marginBottom: "1.2rem" }}>
            <Col>
              {inputTextCount} / {"2000"}
            </Col>
          </Row>
        )}
      {buttonTitle && (
        <Button
          disabled={disableButton !== null && disableButton ? true : false}
          variant="primary"
          type="button"
          onClick={() => {
            buttonOnClickFunction && buttonOnClickFunction();
          }}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};
