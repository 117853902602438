const HOSTNAME: string = process.env.REACT_APP_BACKEND_HOST
    ? process.env.REACT_APP_BACKEND_HOST
    : '';
const GOOGLE_OAUTH_KEY: string = process.env.REACT_APP_GOOGLE_OAUTH_KEY
    ? process.env.REACT_APP_GOOGLE_OAUTH_KEY
    : '';
const USERINFO_API_URL: string = process.env.REACT_APP_USERINFO_API_URL
    ? process.env.REACT_APP_USERINFO_API_URL
    : '';
const LOGIN_API_URL: string = process.env.REACT_APP_LOGIN_API_URL
    ? HOSTNAME + process.env.REACT_APP_LOGIN_API_URL
    : '';

// 로그인 리스폰 변수 이름
const RESPONSE_DATA_KEY1: string = process.env.REACT_APP_RESPONSE_DATA_KEY1
    ? process.env.REACT_APP_RESPONSE_DATA_KEY1
    : '';
const RESPONSE_DATA_KEY2: string = process.env.REACT_APP_RESPONSE_DATA_KEY2
    ? process.env.REACT_APP_RESPONSE_DATA_KEY2
    : '';
const RESPONSE_DATA_KEY3: string = process.env.REACT_APP_RESPONSE_DATA_KEY3
    ? process.env.REACT_APP_RESPONSE_DATA_KEY3
    : '';

// 로그인 쿠키 변수 이름
const COOKIE_LOGIN_NAME1: string = process.env.REACT_APP_COOKIE_LOGIN_NAME1
    ? process.env.REACT_APP_COOKIE_LOGIN_NAME1
    : '';
const COOKIE_LOGIN_NAME2: string = process.env.REACT_APP_COOKIE_LOGIN_NAME2
    ? process.env.REACT_APP_COOKIE_LOGIN_NAME2
    : '';
const COOKIE_LOGIN_NAME3: string = process.env.REACT_APP_COOKIE_LOGIN_NAME3
    ? process.env.REACT_APP_COOKIE_LOGIN_NAME3
    : '';
const COOKIE_LOGIN_NAME4: string = process.env.REACT_APP_COOKIE_LOGIN_NAME4
    ? process.env.REACT_APP_COOKIE_LOGIN_NAME4
    : '';
const COOKIE_LOGIN_NAME5: string = process.env.REACT_APP_COOKIE_LOGIN_NAME5
    ? process.env.REACT_APP_COOKIE_LOGIN_NAME5
    : '';
    
    export const CHATBOT_API_URL: string = process.env.REACT_APP_CHATBOT_API_URL
    ? HOSTNAME + process.env.REACT_APP_CHATBOT_API_URL
    : '';
    
    export const GRAMMARLY_CORRECTION_API: string = process.env.REACT_APP_GRAMMARLY_CORRECTION_API
    ? HOSTNAME + process.env.REACT_APP_GRAMMARLY_CORRECTION_API
    : '';
    
    export const GRAMMARLY_SIMILAR_SENTENCE_API: string = process.env.REACT_APP_GRAMMARLY_SIMILAR_SENTENCE_API
    ? HOSTNAME + process.env.REACT_APP_GRAMMARLY_SIMILAR_SENTENCE_API
    : '';
    
    export const GRAMMARLY_DIALOG_API: string = process.env.REACT_APP_GRAMMARLY_DIALOG_API
    ? process.env.REACT_APP_GRAMMARLY_DIALOG_API
    : '';
    
    export const GRAMMARLY_PRE_API: string = process.env.REACT_APP_GRAMMARLY_PRE_API
    ? process.env.REACT_APP_GRAMMARLY_PRE_API
    : '';
    
    const CORE_NLP_URL: string = process.env.REACT_APP_CORE_NLP_URL
    ? HOSTNAME + process.env.REACT_APP_CORE_NLP_URL
    : '';
    
    const QNA_URL: string = process.env.REACT_APP_QNA_URL
    ? HOSTNAME + process.env.REACT_APP_QNA_URL
        : '';

const TEXT_QA_URL: string = process.env.REACT_APP_TEXT_QA_URL 
    ? HOSTNAME + process.env.REACT_APP_TEXT_QA_URL : '';

const ESSAY_OUTLINE_URL: string = process.env.REACT_APP_ESSAY_OUTLINE_URL 
    ? HOSTNAME + process.env.REACT_APP_ESSAY_OUTLINE_URL : '';
const STUDY_NOTES_URL: string = process.env.REACT_APP_STUDY_NOTES_URL 
    ? HOSTNAME + process.env.REACT_APP_STUDY_NOTES_URL : '';
const SUMMARY_FOR_KIDS_URL: string = process.env.REACT_APP_SUMMARY_FOR_KIDS_URL 
    ? HOSTNAME + process.env.REACT_APP_SUMMARY_FOR_KIDS_URL : '';
const SOLUTION_PROVIDER_URL: string = process.env.REACT_APP_SOLUTION_PROVIDER_URL 
    ? HOSTNAME + process.env.REACT_APP_SOLUTION_PROVIDER_URL : '';
const DIARY_ASSISTANT_URL: string = process.env.REACT_APP_DIARY_ASSISTANT_URL 
    ? HOSTNAME + process.env.REACT_APP_DIARY_ASSISTANT_URL : '';
const CONVERSATION_EVALUATOR_URL: string = process.env.REACT_APP_CONVERSATION_EVALUATOR_URL 
    ? HOSTNAME + process.env.REACT_APP_CONVERSATION_EVALUATOR_URL : '';
const SCENARIO_RESTAURANT_CHAT: string = process.env.REACT_APP_SCENARIO_RESTAURANT_CHAT 
    ? HOSTNAME + process.env.REACT_APP_SCENARIO_RESTAURANT_CHAT : '';
const SCENARIO_RESTAURANT_HELP: string = process.env.REACT_APP_SCENARIO_RESTAURANT_HELP 
    ? HOSTNAME + process.env.REACT_APP_SCENARIO_RESTAURANT_HELP : '';
const SCENARIO_RESTAURANT_CHECK: string = process.env.REACT_APP_SCENARIO_RESTAURANT_CHECK 
    ? HOSTNAME + process.env.REACT_APP_SCENARIO_RESTAURANT_CHECK : '';
const POLY_WRITING_CENTER_URL: string = process.env.REACT_APP_POLY_WRITING_CENTER_URL 
    ? HOSTNAME + process.env.REACT_APP_POLY_WRITING_CENTER_URL : '';
const LOGIN_VALUE_CHECK: string = process.env.REACT_APP_LOGIN_VALUE_CHECK 
    ? HOSTNAME + process.env.REACT_APP_LOGIN_VALUE_CHECK : "";

const GRAMMARLY_SERVER:string = process.env.REACT_APP_GRAMMARLY_SERVER
    ? HOSTNAME + process.env.REACT_APP_GRAMMARLY_SERVER : '';
const UA_HS_USERS_ROLE_CHECK_URL:string = process.env.REACT_APP_UA_HS_USERS_ROLE_CHECK_URL
    ? HOSTNAME + process.env.REACT_APP_UA_HS_USERS_ROLE_CHECK_URL:'';
export const CONFIG = {
    GOOGLE_OAUTH: {
        KEY: GOOGLE_OAUTH_KEY,
        URL: USERINFO_API_URL,
        BACKEND_URL: LOGIN_API_URL,
        
    },
    RESPONSE_DATE_LOGIN: {
        KEY1: RESPONSE_DATA_KEY1,
        KEY2: RESPONSE_DATA_KEY2,
        KEY3: RESPONSE_DATA_KEY3,
    },
    COOKIE_LOGIN: {
        KEY1: COOKIE_LOGIN_NAME1,
        KEY2: COOKIE_LOGIN_NAME2,
        KEY3: COOKIE_LOGIN_NAME3,
        KEY4: COOKIE_LOGIN_NAME4,
        KEY5: COOKIE_LOGIN_NAME5,
    },
    CORE_NLP_URL,
    QNA_URL,
    TEXT_QA_URL,
    ESSAY_OUTLINE_URL,
    STUDY_NOTES_URL,
    SUMMARY_FOR_KIDS_URL,
    SOLUTION_PROVIDER_URL,
    DIARY_ASSISTANT_URL,
    CONVERSATION_EVALUATOR_URL,
    SCENARIO_RESTAURANT_CHAT,
    SCENARIO_RESTAURANT_HELP,
    SCENARIO_RESTAURANT_CHECK,
    POLY_WRITING_CENTER_URL,
    LOGIN_VALUE_CHECK,
    GRAMMARLY_SERVER,
    UA_HS_USERS_ROLE_CHECK_URL,
};
