import React from "react";
import { Container } from "react-bootstrap";

export default function Error() {
  return (
    <Container className="main-container">
      <h1>Error</h1>
    </Container>
  );
}
