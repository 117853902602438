import React, { useState } from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'

export const ConversationEvaluatorResult = (props) => {
    const { responseData, i } = props
    const evaluate_values = responseData[1][0];
    const e_key: string[] = Object.keys(evaluate_values)
    const bgColors = ['#00cc00', 'orange', 'skyblue', 'ghostwhite'];

    return (
        <div
            className={style.basic}
            key={i}>
            <p style={{ fontSize: '2em', fontWeight: 'bold', margin: '0 0 0.5rem 0' }}><b>{i}. Evaluation Result</b> - <span style={{ fontSize: '1.5rem' }}>{responseData[0]}</span></p>
            {e_key && e_key.map((value, idx) => (
                <div key={idx} className={style.basic} style={{ backgroundColor: bgColors[idx] }}>
                    <b style={{ 'padding': '0.5rem' }}>
                        {value}
                    </b>
                    <p style={{ 'padding': '0.5rem', 'margin': '0px' }}>{`Level : ${evaluate_values[value].level}`}</p>
                    <p style={{ 'padding': '0.5rem' }}>
                        {`${evaluate_values[value].evaluate}`}
                    </p>
                </div>
            ))}
        </div>
    );

}