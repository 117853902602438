import React, { useEffect, useState } from 'react';
import style from './SemanticAnalysis.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ExamplesDiv } from '../../components/commonConst/example/example';
import { InputDiv } from 'components/commonConst/input/input';
import { TextareaDiv } from 'components/commonConst/input/textarea';
import { ApiOpen } from 'util/CallAPI';
import { QuestionShortAnswerAPI } from 'util/QuestionShortAnswerAPI';
import { NLUSemanticAnalysisDiv } from 'components/commonConst/NLUSemantic/NLUResult';
import { CONFIG } from 'config';
import { CreateQuestion } from 'components/commonConst/LearningByQuestion/LBQResult';
import { ShortAnswerResult } from 'components/commonConst/QuestionShortAnswer/ShortAnswerResult';
import { EssayOutlinerResult } from 'components/commonConst/EssayOutliner/EssayOutlinerResult';
import { StudyNoteResult } from 'components/commonConst/StudyNote/StudyNoteResult';
import { SummaryForKidsResult } from 'components/commonConst/SummaryForKids/SummaryForKidsResult';
import { SolutionProviderResult } from 'components/commonConst/SolutionProvider/SolutionProviderResult';
import { DiaryAssistantResult } from 'components/commonConst/DiaryAssistant/DiaryAssistantResult';
import { ConversationEvaluatorResult } from 'components/commonConst/ConversationEvaluator/ConversationEvaluatorResult';
import { POLYWritingCenterResult } from 'components/POLYWritingCenter/POLYWritingCenterResult';
import usePOLYDTStore from '../../store/usePOLYDTStore';


interface IResultData {
    originText: string;
    text: any[];
    parseResult: string;
    levelCount: { 1: number; 2: number; 3: number; 4: number; 5: number; 6: number };
    multiWordLevels: any[];
    cefr: {
        averageLevel: string;
        levelAverageNumber: number;
        levelCount: { A1: number; A2: number; B1: number; B2: number; C1: number; C2: number };
        multiWordLevels: any[];
    };
}

interface IBasicLayoutPageProps {
    pageTitle: string;
    pageProps: any;
}


export default function BasicLayoutPage(props: IBasicLayoutPageProps) {

    // Store
    const { setAllPOLYDTStateStore, allPOLYDTStateStore, inputValueStore, setInputValueStore } = usePOLYDTStore();

    // Props
    const { pageTitle, pageProps } = props;
    const [pagePath, setPagePath] = useState('');
    const [inputText, setInputText] = useState<any>('');

    const [responseFlag, setResponseFlag] = useState(false);
    const [activeQuestionTitle, setActiveQuestionTitle] = useState<any>();

    const [inputTextCount, setInputTextCount] = useState<number>(0);
    // 전체 누적 결과
    const [allData, setAllData] = useState<any>([]);
    // learningByQuestion 누적 결과
    const [allLearningByQuestion, setAllLearningByQuestion] = useState<any>([]);
    // questionShortAnswer 누적 결과
    const [allQuestionShortAnswer, setAllQuestionShortAnswer] = useState<any>([]);
    // essayOutliner 누적 결과
    const [allEssayOutliner, setAllEssayOutliner] = useState<any>([]);
    // studyNote 누적 결과
    const [allStudyNote, setAllStudyNote] = useState<any>([])
    // summaryForKids 누적 결과
    const [allSummaryFodKids, setAllSummaryForKids] = useState<any>([]);
    // solutionProvider 누적결과
    const [allSolutionProvider, setAllSolutionProvider] = useState<any>([]);
    const [dumyInput, setDumyInput] = useState(false)
    // diaryAssistant 누적 결과
    const [allDiaryAssistant, setAllDiaryAssistant] = useState<any>([]);
    // conversationEvaluator 누적 결과
    const [allConversationEvaluator, setAllConversationEvaluator] = useState<any>([]);
    const [settingsHideAndShow, setSettingsHideAndShow] = useState<boolean>(false);
    // POLYWritingCenter 누적 결과
    // const [allPolyWritingCenter, setAllPolyWritingCenter] = useState<any>([]);



    useEffect(() => {
        if (pageTitle === 'diaryAssistant') {
            let dumyData = {
                inputDate: pageProps.examples[0].today,
                inputMemorableEvent: pageProps.examples[0].event,
                inputTodayFeelingsAbout: pageProps.examples[0].feeling,
                inputFuture: pageProps.examples[0].question
            }
            setInputText(dumyData);
            setDumyInput(true);
        } else if (pageTitle === 'conversationEvaluator') {
            let dumyData = {
                target: pageProps.examples[0].target,
                based: pageProps.examples[0].based,
                question: pageProps.examples[0].question
            }
            setInputText(dumyData)
        }

    }, [])

    useEffect(() => {
        if (pagePath === '') {
            setPagePath(pageTitle)
        }
        if (pageTitle !== pagePath) {
            setPagePath(pageTitle)
            if (pageTitle === 'questionShortAnswer') {
                setInputText(pageProps.examples[0].text)
                setActiveQuestionTitle(pageProps.examples[0].question)
                setInputTextCount(pageProps.examples[0].text.length)
            } else if (pageTitle === 'diaryAssistant') {
                let dumyData = {
                    inputDate: pageProps.examples[0].today,
                    inputMemorableEvent: pageProps.examples[0].event,
                    inputTodayFeelingsAbout: pageProps.examples[0].feeling,
                    inputFuture: pageProps.examples[0].question
                }
                setInputText(dumyData);
            } else if (pageTitle === 'conversationEvaluator') {
                let dumyData = {
                    target: pageProps.examples[0].target,
                    based: pageProps.examples[0].based,
                    question: pageProps.examples[0].question
                }
                setInputText(dumyData)
            } else {
                setInputText(pageProps.examples[0].question)
            }
        }
        if (pageTitle === 'summaryForKids') {
            if (inputText.length > 1500) {
                let dumyInput = inputText.slice(0, 1500);
                setInputText(dumyInput)
                setInputTextCount(dumyInput.length)
            }
        } else if (pageTitle === 'learningByQuestion' || pageTitle === 'questionShortAnswer') {
            if (inputText.length > 2000) {
                let dumyInput = inputText.slice(0, 2000);
                setInputText(dumyInput)
                setInputTextCount(dumyInput.length)
            }
        } else if (pageTitle === 'polyWritingCenter') {
            setInputValueStore(pageProps.examples[0].question);
        } else {
            setInputTextCount(inputTextCount)
        }
    }, [pageTitle])
    useEffect(() => {
        if (pageTitle === 'summaryForKids' || pageTitle === 'learningByQuestion' || pageTitle === 'questionShortAnswer') {
            // setInputText(value);
            // setInputTextCount(value.length || 0)
            if (pageTitle === 'summaryForKids') {
                if (inputText.length > 1500) {
                    let dumyInput = inputText.slice(0, 1500);
                    setInputText(dumyInput)
                    setInputTextCount(dumyInput.length)
                }
            } else if (pageTitle === 'learningByQuestion' || pageTitle === 'questionShortAnswer') {
                if (inputText.length > 2000) {
                    let dumyInput = inputText.slice(0, 2000);
                    setInputText(dumyInput)
                    setInputTextCount(dumyInput.length)
                }
            }
        }

    }, [inputTextCount])

    const examplesDivSubActiveText = (value: string) => {
        setActiveQuestionTitle(value)
        setInputTextCount(value.length);
    }

    const examplesDivActiveText = (value: string | any, multiFlagged?: number) => {
        if (pageTitle === 'summaryForKids' || pageTitle === 'learningByQuestion' || pageTitle === 'questionShortAnswer') {
            // setInputText(value);
            // setInputTextCount(value.length || 0)
            if (pageTitle === 'summaryForKids') {
                if (inputText.length > 1500) {
                    let dumyInput = inputText.slice(0, 1500);
                    setInputText(dumyInput)
                    setInputTextCount(dumyInput.length)
                } else {
                    setInputText(value);
                    setInputTextCount(value.length || 0)
                }
            } else if (pageTitle === "learningByQuestion" || pageTitle === "questionShortAnswer") {
                if (inputText.length > 2000) {
                    let dumyInput = inputText.slice(0, 2000);
                    setInputText(dumyInput)
                    setInputTextCount(dumyInput.length)
                } else {
                    setInputText(value);
                    setInputTextCount(value.length || 0)
                }
            }
        } else if (pageTitle === 'diaryAssistant') {
            let dumpInputText = inputText;
            if (multiFlagged === 0) {
                // date
                dumpInputText.inputDate = value;
                setInputText(dumpInputText);
                // setInputText()
            } else if (multiFlagged === 1) {
                // event
                dumpInputText.inputMemorableEvent = value;
                setInputText(dumpInputText)
            } else if (multiFlagged === 2) {
                // feeling
                dumpInputText.inputTodayFeelingsAbout = value;
                setInputText(dumpInputText)
            } else if (multiFlagged === 3) {
                // future
                dumpInputText.inputFuture = value;
                setInputText(dumpInputText)
            }
        } else if (pageTitle === 'conversationEvaluator') {
            let dumpInputText = inputText;
            if (multiFlagged === 0) {
                dumpInputText.target = value;
                setInputText(dumpInputText)
            } else if (multiFlagged === 1) {
                dumpInputText.based = value;
                setInputText(dumpInputText)
            } else if (multiFlagged === 2) {
                dumpInputText.question = value;
                setInputText(dumpInputText)
            }
        } else if (pageTitle === 'polyWritingCenter') {
            setInputValueStore(value)
        } else {
            setInputText(value);
        }

    };
    const examplesDivActiveQuestionTitle = (value: string) => {
        setActiveQuestionTitle(value);
    }

    const onAsk = async () => {
        setResponseFlag(true);
        if (pageTitle === 'diaryAssistant') {
            let dumyText = {
                inputDate: inputText.inputDate.replace(/^\s+|\s+$/g, ''),
                inputFuture: inputText.inputFuture.replace(/^\s+|\s+$/g, ''),
                inputMemorableEvent: inputText.inputMemorableEvent.replace(/^\s+|\s+$/g, ''),
                inputTodayFeelingsAbout: inputText.inputTodayFeelingsAbout.replace(/^\s+|\s+$/g, ''),
            }
            let textLen = dumyText;
            if (textLen.inputDate === '') return;
            if (textLen.inputFuture === '') return;
            if (textLen.inputMemorableEvent === '') return;
            if (textLen.inputTodayFeelingsAbout === '') return;
            let pattern = /[a-zA-Z|0-9|~!\,\.\?\"\'\;\:]/;
            if (!pattern.test(textLen.inputDate)) {
                alert('영어만 쓰자!');
                return;
            }
            if (!pattern.test(textLen.inputFuture)) {
                alert('영어만 쓰자!');
                return;
            }
            if (!pattern.test(textLen.inputMemorableEvent)) {
                alert('영어만 쓰자!');
                return;
            }
            if (!pattern.test(textLen.inputTodayFeelingsAbout)) {
                alert('영어만 쓰자!');
                return;
            }
        } else if (pageTitle === 'conversationEvaluator') {
            for (const idx in inputText) {
                const targetInput = inputText[idx].replace(/^\s+|\s+$/g, '');
                if (targetInput === '') {
                    let alertMessage = `Please input ${idx === 'target' ? 'Target Name' : 'Conversation Data'}.`;
                    alert(alertMessage)
                    setResponseFlag(false)
                    return;
                }
                const pattern = /[a-zA-Z|0-9|~!\,\.\?\"\'\;\:]/;
                if (!pattern.test(targetInput)) {
                    alert('Please use English.');
                    setResponseFlag(false)
                    return;
                }
            }
        } else {
            let textLen = inputText.replace(/^\s+|\s+$/g, '');
            if (textLen === '') return;
            let pattern = /[a-zA-Z|0-9|~!\,\.\?\"\'\;\:]/;
            if (!pattern.test(textLen)) {
                alert('영어만 쓰자!');
                return;
            }
        }




        // 매 회 데이터 누적시키기
        if (pageTitle === 'semanticAnalysis') {
            const response = await ApiOpen(inputText, CONFIG.CORE_NLP_URL);
            let dumyAllData = reprocessResultResponseData(response.data);
            setAllData([...dumyAllData]);
        } else if (pageTitle === 'learningByQuestion') {
            let dump_array_all_data = [...allLearningByQuestion]
            const response = await ApiOpen(inputText, CONFIG.QNA_URL);
            const setAllData = response.data.data.text.map((v, i) => {
                v.selectChoice = 0
                return v
            })
            dump_array_all_data.push(setAllData)
            // 회차
            const apiNum = allLearningByQuestion.length;
            // 문제 넘버
            const qNum = 3;
            // 객관식 넘버
            const answersNum = 4;
            for (let i = 0; i < apiNum; i++) {
                for (let j = 0; j < qNum; j++) {
                    for (let z = 0; z < answersNum; z++) {
                        document.getElementById(i + 'Q' + (j + 1) + 'radio' + (z + 1))?.removeAttribute('checked')
                    }
                }
            }
            setAllLearningByQuestion(dump_array_all_data);
        } else if (pageTitle === 'questionShortAnswer') {
            const response = await QuestionShortAnswerAPI(inputText, activeQuestionTitle, CONFIG.TEXT_QA_URL)
            const dumyAllData: any = response.data.data;
            const reAnswer = dumyAllData.answer;
            const reText = dumyAllData.text;
            const pushData = {
                answer: reAnswer,
                text: reText,
                question: activeQuestionTitle
            }
            allQuestionShortAnswer.push(pushData);
            setAllQuestionShortAnswer(allQuestionShortAnswer);
        } else if (pageTitle === 'essayOutliner') {
            const response = await ApiOpen(inputText, CONFIG.ESSAY_OUTLINE_URL);
            //setAllEssayOutliner 받은 값 세팅
            const dumyAllData: any = response.data.data;
            const dumyTopic = inputText;
            const dumyText = dumyAllData.text;
            const pushData = {
                topic: dumyTopic,
                text: dumyText
            }
            allEssayOutliner.push(pushData)
            setAllEssayOutliner(allEssayOutliner);
        } else if (pageTitle === 'studyNote') {
            const response = await ApiOpen(inputText, CONFIG.STUDY_NOTES_URL);
            // setAllStudyNote 값 세팅
            const dumyAllData: any = response.data.data;
            const dumyTopic = inputText;
            const dumyText = dumyAllData.text;
            const pushData = {
                topic: dumyTopic,
                text: dumyText,
            }
            allStudyNote.push(pushData);
            setAllStudyNote(allStudyNote);
        } else if (pageTitle === 'summaryForKids') {
            const response = await ApiOpen(inputText, CONFIG.SUMMARY_FOR_KIDS_URL);
            const dumyAllData: any = response.data.data;
            const dumyText = dumyAllData.text;
            const pushData = dumyText;
            allSummaryFodKids.push(pushData);
            setAllSummaryForKids(allSummaryFodKids);
        } else if (pageTitle === 'solutionProvider') {
            const response: any = await ApiOpen(inputText, CONFIG.SOLUTION_PROVIDER_URL);
            const dumyAllData: any = response.data.data
            const dumyProblem = inputText;
            const dumyText = dumyAllData;
            const pushData = {
                problem: dumyProblem,
                text: dumyText,
            }
            allSolutionProvider.push(pushData);
            setAllSolutionProvider(allSolutionProvider);

        } else if (pageTitle === 'diaryAssistant') {
            const rowData = {
                date: inputText.inputDate,
                event: inputText.inputMemorableEvent,
                feelings_mood: inputText.inputTodayFeelingsAbout,
                feelings_future: inputText.inputFuture
            }
            const response: any = await ApiOpen(rowData, CONFIG.DIARY_ASSISTANT_URL, pageTitle)
            const dumyAllData: any = response.data.data;
            allDiaryAssistant.push(dumyAllData);
            setAllDiaryAssistant(allDiaryAssistant);
        } else if (pageTitle === 'conversationEvaluator') {
            const rowData = {
                based: inputText.based,
                conversation: inputText.question,
                target: inputText.target
            }
            const response: any = await ApiOpen(rowData, CONFIG.CONVERSATION_EVALUATOR_URL, pageTitle).then((res) => {
                return res.data.data;
            })
            const dumyAllData: any = response.text;
            allConversationEvaluator.push([inputText.based, dumyAllData]);
            setAllConversationEvaluator(allConversationEvaluator);
        } else if (pageTitle === 'polyWritingCenter') {
            let dumyData: any = allPOLYDTStateStore;
            const response = await ApiOpen(inputValueStore, CONFIG.ESSAY_OUTLINE_URL);
            const dumyAllData: any = response;
            // allPOLYDTStateStore.push([inputText, dumyAllData.data.data.text]);
            // setAllPolyWritingCenter(allPolyWritingCenter);
            let text = dumyAllData.data.data.text.split('\n');
            let textlength = text.length;
            for (let i = 0; i < textlength; i++) {
                text.splice(((i * 2) + 1), 0, '');
            }
            let pushAskValue = {
                topic: inputValueStore,
                text: text
            }
            let roundPOLYState = {
                askResult: pushAskValue,
                showResult: {
                    userText: [],
                    aiText: []
                }
            }
            dumyData.push(roundPOLYState);

            setAllPOLYDTStateStore(dumyData);

        }
        setResponseFlag(false);
    };
    // const repo

    const reprocessResultResponseData = (responseData: any) => {

        let dumyResponseData: any = [];
        for (const index in responseData) {
            const rData = responseData[index];
            let dumpResponseData: IResultData = {
                originText: inputText,
                text: rData.dependency_result,
                parseResult: rData.parse_result,
                levelCount: rData.level_count,
                multiWordLevels: rData.multi_word_levels,
                cefr: {
                    averageLevel: rData.cefr_average_level,
                    levelAverageNumber: rData.cefr_level_average_number,
                    levelCount: rData.cefr_level_count,
                    multiWordLevels: rData.cefr_multi_word_levels,
                },
            };
            dumyResponseData.push(dumpResponseData);
        }
        let dumyAllData = allData;
        dumyAllData?.push(dumyResponseData);
        return dumyAllData;
    }

    const resultXMLDiv = (v: any, i?: any) => {
        // 1회
        if (pageTitle === 'semanticAnalysis') {
            return (
                <div className={style.answers} key={i}>

                    <NLUSemanticAnalysisDiv i={i} mess={v} />
                </div>
            )
        } else if (pageTitle === 'learningByQuestion') {
            return (
                <Row key={i} style={{ 'maxWidth': '99.5%' }}>
                    <Col xxl='12' style={{ "border": "1px solid lightgray", "margin": '10px -20px 10px 0px', 'padding': '20px' }}>
                        <CreateQuestion parent_i={i} responseData={v} setLearningByQuestion={(value: any) => {
                            let dump_allData = allLearningByQuestion;
                            dump_allData[i] = value;
                            setAllLearningByQuestion(dump_allData);
                        }} />
                    </Col>
                </Row>
            )
        } else if (pageTitle === 'questionShortAnswer') {
            return (
                <div key={i} className={style.answers} >
                    <ShortAnswerResult responseData={v} />
                </div>
            )
        } else if (pageTitle === 'essayOutliner') {
            return (
                <div className={style.answers} key={i}>
                    <EssayOutlinerResult responseData={v} />
                </div>
            )
        } else if (pageTitle === 'studyNote') {
            return (
                // <div style={{ marginBottom: "20px" }} key={i}>
                <div className={style.answers} key={i}>
                    <StudyNoteResult responseData={v} />
                </div>
                // </div>
            )
        } else if (pageTitle === 'summaryForKids') {
            let maxLength = allSummaryFodKids.length;
            let currentIdx = maxLength - i;
            return (
                <div className={style.answers} key={currentIdx} >
                    <SummaryForKidsResult responseData={v} i={currentIdx} />
                </div>
            )
        } else if (pageTitle === 'solutionProvider') {
            return (
                <div key={i} className={style.answers}>
                    <SolutionProviderResult responseData={v} i={i} />
                </div >
            )
        } else if (pageTitle === 'diaryAssistant') {
            let maxLength = allDiaryAssistant.length - i;
            return (
                <div key={i} className={style.answers}>
                    <DiaryAssistantResult responseData={v} i={maxLength} key={i} />
                </div>
            )
        } else if (pageTitle === 'conversationEvaluator') {
            let maxLength = allConversationEvaluator.length - i;
            return (
                <div className={style.answers} key={i}>
                    <ConversationEvaluatorResult responseData={v} i={maxLength} />
                </div>
            )
        } else if (pageTitle === 'polyWritingCenter') {
            return (
                <div className={style.answers} key={i}>
                    <POLYWritingCenterResult POLYDTStateStore={v} i={i} />
                </div>
            )
        }

    };
    // Render Const Functions
    const resultXMLRender = () => {
        if (pageTitle === 'semanticAnalysis') {
            return allData.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
        } else if (pageTitle === 'learningByQuestion') {
            return allLearningByQuestion.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
        } else if (pageTitle === 'questionShortAnswer') {
            return allQuestionShortAnswer.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
            // return resultXMLDiv(allQuestionShortAnswer);
        } else if (pageTitle === 'essayOutliner') {
            return allEssayOutliner.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
        } else if (pageTitle === 'studyNote') {
            return allStudyNote.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
        } else if (pageTitle === 'summaryForKids') {
            return allSummaryFodKids.slice(0).reverse().map((v, i) => resultXMLDiv(v, i))
        } else if (pageTitle === 'solutionProvider') {
            return allSolutionProvider.slice(0).reverse().map((v, i) => resultXMLDiv(v, i))
        } else if (pageTitle === 'diaryAssistant') {
            return allDiaryAssistant.slice(0).reverse().map((v, i) => resultXMLDiv(v, i))
        } else if (pageTitle === 'conversationEvaluator') {
            return allConversationEvaluator.slice(0).reverse().map((v, i) => resultXMLDiv(v, i))
        } else if (pageTitle === 'polyWritingCenter') {
            // if (allPOLYDTStateStore[0].askResult !== undefined) {
            return allPOLYDTStateStore.slice(0).reverse().map((v, i) => resultXMLDiv(v, i));
            // }
        }

    }

    const renderInputAreaDiv = () => {
        if (pageTitle === 'semanticAnalysis') {
            return (
                <Row>
                    <InputDiv
                        buttonTitle={'ASK'}
                        inputTitle={'Input'}
                        buttonOnClickFunction={() => onAsk()}
                        inputText={inputText}
                        inputOnChangeFunction={examplesDivActiveText}
                    />
                </Row>
            )

        } else if (pageTitle === 'learningByQuestion') {
            return (
                <Row>
                    <TextareaDiv
                        pageTitle={'learningByQuestion'}
                        buttonTitle={'Generate'}
                        inputTitle={'Input Text'}
                        buttonOnClickFunction={() => onAsk()}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                        inputTextCount={inputTextCount}
                    />
                </Row>
            )
        } else if (pageTitle === 'questionShortAnswer') {
            return (
                <>
                    <Row>
                        <TextareaDiv
                            pageTitle={'questionShortAnswer'}
                            // buttonTitle={'Ask'}
                            inputTitle={'Enter Text'}
                            // buttonOnClickFunction={()=>onAsk()}
                            inputOnChangeFunction={examplesDivActiveText}
                            inputText={inputText}
                            inputTextCount={inputTextCount}
                        />
                    </Row>
                    <Row>
                        <InputDiv
                            buttonTitle={'Ask'}
                            inputTitle={'Question'}
                            buttonOnClickFunction={onAsk}
                            inputOnChangeFunction={examplesDivSubActiveText}
                            inputText={activeQuestionTitle || ""} />
                    </Row>
                </>
            )
        } else if (pageTitle === 'newPage') {
            return (
                <Row>
                    <TextareaDiv
                        buttonTitle={'Send'}
                        inputTitle={'Input Text'}
                        buttonOnClickFunction={() => onAsk()}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                    />
                </Row>
            )
        } else if (pageTitle === 'studyNote') {
            return (
                <Row>
                    <InputDiv
                        buttonTitle={'Ask'}
                        inputTitle={'Enter Essay Title'}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                    />
                </Row>
            )
        } else if (pageTitle === 'essayOutliner') {
            return (
                <Row>
                    <InputDiv
                        buttonTitle={'Ask'}
                        inputTitle={'Enter Stody Note Title'}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                    />
                </Row>
            )
        } else if (pageTitle === 'summaryForKids') {
            return (
                <Row>
                    <TextareaDiv
                        pageTitle={'summaryForKids'}
                        buttonTitle={'Ask'}
                        inputTitle={'Input Text'}
                        buttonOnClickFunction={() => onAsk()}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                        inputTextCount={inputTextCount}

                    />
                </Row>
            )
        } else if (pageTitle === 'solutionProvider') {
            return (
                <Row>
                    <InputDiv
                        buttonTitle={'Ask'}
                        inputTitle={'Enter Your Problems'}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                    />
                </Row>
            )
        } else if (pageTitle === 'diaryAssistant') {
            return (
                <Row>
                    <InputDiv
                        pageTitle={pageTitle}
                        buttonTitle={'Ask'}
                        inputTitle={'Based on the feelings you described above, what would you do in the future?'}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                    />
                    {/* </InputDiv> */}
                </Row>
            )
        } else if (pageTitle === 'conversationEvaluator') {
            return (
                <Row>
                    <InputDiv
                        pageTitle={pageTitle}
                        buttonTitle={pageProps.buttons}
                        inputTitle={pageProps.inputs}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputText}
                        multiButtonClickEventFunction={[
                            onAsk,
                            () => { document.location.reload(); },
                            () => { setSettingsHideAndShow(!settingsHideAndShow) }
                        ]}
                        hideAndExtendFlagged={settingsHideAndShow}
                    />
                </Row>
            )
        } else if (pageTitle === 'polyWritingCenter') {
            return (
                <Row>
                    <InputDiv
                        pageTitle={pageTitle}
                        buttonTitle={'Ask'}
                        inputTitle={'Enter Essay Title'}
                        buttonOnClickFunction={onAsk}
                        inputOnChangeFunction={examplesDivActiveText}
                        inputText={inputValueStore || ""}
                    />
                    {/* </InputDiv> */}
                </Row>
            )
        }
    }


    return (
        <div className={style.container}>
            <Row>
                <Row style={{ padding: 0 }}>
                    <p className={style.title}>{pageProps.title}</p>
                </Row>
                <Row style={{ padding: "0", marginBottom: "0.5rem" }}>
                    <Col xs md="7">
                        {pageProps.desc}
                    </Col>



                </Row>

            </Row>
            <Row>
                {pageTitle === 'learningByQuestion' && <Col style={{ padding: 0 }}>  <p className='h5'><b>{"Input Text"}</b> </p></Col>}
                {pageTitle === 'questionShortAnswer' && <Col style={{ padding: 0 }}>  <p className='h5'><b>{"Enter Text"}</b> </p></Col>}
                {pageTitle === 'newPage' && <Col style={{ padding: 0 }}>  <p className='h5'><b>{"Input Text"}</b> </p></Col>}
                {pageTitle === 'summaryForKids' && <Col style={{ padding: 0 }}>  <p className='h5'><b>{"Input Text"}</b> </p></Col>}
                {pageTitle === 'diaryAssistant' || pageTitle === 'conversationEvaluator' ? <div></div> :
                    (pageTitle === "polyWritingCenter" || pageTitle === "conversationEvaluator" || pageTitle === "diaryAssistant" || pageTitle === "solutionProvider" || pageTitle === "essayOutliner" || pageTitle === "studyNote" || pageTitle === "semanticAnalysis" ?
                        <>
                            <Col xs md="7" style={{ display: 'flex', alignItems: 'end', justifyContent: 'start', padding: 0 }}>
                                <p className='h5'>
                                    {/* <b>{`Input Text`}</b> */}
                                </p>
                            </Col>
                            <Col xs md="5" style={{ display: 'flex', alignItems: 'end', justifyContent: 'start', padding: 0 }}>
                                <p className='h5'>
                                    <b>{`Example`}</b>
                                </p>
                            </Col>
                        </>
                        :
                        (<Col xs md="5" style={{ display: 'flex', alignItems: 'end', justifyContent: 'start', padding: 0 }}>
                            <p className='h5'>
                                <b>{`Example`}</b>
                            </p>
                        </Col>))
                }
            </Row>
            <Row>
                <Row>
                    {/* <div style={{ display: "flex" }}> */}
                    {(pageTitle === 'diaryAssistant' || pageTitle === 'conversationEvaluator')
                        ?
                        <Col xs md="12" style={{ padding: 0 }}>
                            {renderInputAreaDiv()}
                        </Col>
                        :
                        <Col xs md="7" style={{ padding: 0 }}>
                            {renderInputAreaDiv()}
                        </Col>

                    }
                    <Col xs md="5">
                        {/* <div> */}
                        {pageTitle !== 'diaryAssistant' && pageTitle !== 'conversationEvaluator'
                            ? <>
                                <ExamplesDiv pageTitle={pageTitle} examples={pageProps.examples} activeText={examplesDivActiveText} actioveQuestionTitle={examplesDivActiveQuestionTitle} />
                            </>
                            : ''}
                        {/* </div> */}
                    </Col>
                    {/* </div> */}
                </Row>
            </Row>
            {/* {(pageTitle === 'summaryForKids') && this.characterText(1500,badgeStyle,textLen,'too long your text.','Please write Summarize.','true')} */}
            {responseFlag && <div className={style.modal}> <div className={style.ldsRing}><div /><div /><div /><div /></div></div>}
            <Row>
                {resultXMLRender()}
            </Row>
        </div>
    );
}
