export const NewPageProps = {
    title: 'New Page',
    desc: <div>
        <p className="h5"><b>New Page의 더미 LayPage</b></p>
    <p className="h5">Copy and paste the text of the article you want to make questions.
    The longer article you enters, the more questions you can create.</p>
    </div>,
    inputs: [{
        title: 'Input Text',
        type: 'textarea',
        name: 'question',
    }],
    examples:[{
        question: '첫 번쨰 example',
    }, {
        question: '두 번쨰 example'
    }, {
        question: '세 번째 example',
    }, {
        question: '네 번째 example',
    }, {
        question: '다섯 번째 example',
    }, {
        question: `여섯 번째 example`,
    }],
    renderAnswer: { type: 'newPage'},
};