import React, { Component, createRef } from 'react';
import cn from 'classnames';

export type Language = 'en' | 'mu' | 'ko';

interface NerClassProps {
    label: string;
    color: string;
    text?: string;
    tip?: string;
}

interface NerClassState {
    clicked: boolean;
    leftTooltip: string;
}

export class NerClass extends Component<NerClassProps, NerClassState> {
    ref: React.RefObject<HTMLSpanElement>;
    tooltipRef: React.RefObject<HTMLDivElement>;

    constructor (props: NerClassProps) {
        super(props);
        this.ref = createRef();
        this.tooltipRef = createRef();
        this.state = {
            clicked: false,
            leftTooltip: '',
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.tooltipRef.current) {
            if (this.tooltipRef.current.getClientRects()[0].left < 140) {
                this.setState({ leftTooltip: 'leftTooltip' });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: any) => {
        if (this.ref && !this.ref.current!.contains(event.target)) {
            this.setState({ clicked: false });
        }
    }

    render () {
        const { label, color, text, tip } = this.props;
        const { clicked, leftTooltip } = this.state;
        return (
          <span
            className="card margin_r"
            style={{ backgroundColor: color, cursor: text ? 'help' : 'inherit',  display:"inline-block" }}
            onClick={() => this.setState({ clicked: !clicked })}
            ref={this.ref}
          >
            {`${label}`}
            {tip && <span className="innerTip">{tip}</span>}
            {(text) && (
              <div
                className={cn('tooltip', leftTooltip, clicked ? 'activeTooltip' : '')}
                ref={this.tooltipRef}
              >
                <div className="tooltipInner" style={{ backgroundColor: color }}>{text}</div>
              </div>
            )}
          </span>
        );
    }
}
