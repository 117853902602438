import React from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'
export const EssayOutlinerResult = (props) => {
    const { responseData, } = props
    const arr = responseData.text.split('\n');
    const head_arr = ["I.", "II.", "III.", "IV.", "V.", "VI.", "VII.", "VIII.", "IX.", "X."];


    return (
        <div className={style.basic}>
            <p>{'Topic : '}{responseData.topic}</p>
            {arr.map((v, i) => {
                const test_str = v.split(' ');
                let check_head = false;
                const not_head_str = v.split('.');
                head_arr.filter((v) => {
                    if (test_str[0] === v) {
                        check_head = true;
                    }
                })
                if (check_head) {
                    return <p style={{ "fontSize": "1.4rem", "marginBottom": "0.7rem", "marginTop": "12px" }} key={i}>{v}</p>
                } else {
                    if (not_head_str[0].match(/[0-9]/g)) {
                        // head === number
                        return <p style={{ "paddingLeft": "2.6rem", "fontSize": "1rem", "marginBottom": "0.3rem" }} key={i}>{v}</p>
                    } else {
                        return <p style={{ "paddingLeft": "1.2rem", "fontSize": "1.2rem", "marginBottom": "0.5rem" }} key={i}>{v}</p>
                    }
                }
            })}
        </div>
    )
}