/**
 * checkingValuesTypes : 걸러낼 문자 종류
 * Kor : 한글만 false
 * Eng : 영어만 false
 * Num : 숫자만 false
 * Spe : 특수문자만 false -> warning log정보가 계속 떠서 사용 안함 -> 다른 방법을 찾으면 추가 예정
 */
export function checkChatInputValues(
  checkValues: string,
  checkingValuesTypes: string
) {
  if (checkingValuesTypes === "Eng") {
    //영어, 숫자, 특문 가능하게
    return regExFnc(checkValues, "base");
  }
}

function regExpCustomNLP(values: string, regExp: RegExp) {
  const regExpR = regExp;
  const check = regExpR.test(values);
  // console.log('check =',check)
  if (check) {
    return false;
  } else {
    return true;
  }
}
function regExpCustomNotCheck(values: string, regExp: RegExp) {
  const regExpR = regExp;
  const check = regExpR.test(values);
  // console.log('check =',check)
  if (check) {
    return true;
  } else {
    return false;
  }
}

function regExFnc(val: string, checkTypes: string) {
  console.log('test1',val, 'test2=',checkTypes)
  if (checkTypes === "Kor") {
    // 한글이 있으면 false
    const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    return regExpCustomNLP(val, regExp);
  } else if (checkTypes === "Eng") {
    // 영어가 있으면 false
    const regExp = /[a-zA-Z]/g;
    return regExpCustomNLP(val, regExp);
  } else if (checkTypes === "Num") {
    // 숫자가 있으면 false
    const regExp = /[0-9]/g;
    return regExpCustomNLP(val, regExp);
  } else if (checkTypes === "Spe") {
    // 특수 문자가 있으면 false
    // const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    // return regExpCustomNLP(val, regExp);
  } else if (checkTypes === "base") {
    // 영어, 숫자, 특문 외의 문자가 있는 경우 false
    // \“\” 유니코드 8220 8221
    const regExp = /^[a-zA-Z0-9\s\“\”\\[\]{}/?.,;:|()*~`!@#$%^&-_+="]*$/g;
    return regExpCustomNotCheck(val, regExp);
  } else {
    // 없는 타잎일 경우 false
    return false;
  }
}
