import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CONFIG } from "../config";
import { getCookie } from "../util/Cookie";

interface PrivateRouteProps {
  children?: ReactElement; // Router.tsx에서 PrivateRoute가 감싸고 있는 Component Element
  authentication: boolean; // true : 인증을 반드시 해야만 접속가능, false : 인증을 반드시 안해야만 접속 가능
}

export default function PrivateRoute({
  /** 접근 권한 */
  authentication,
}: PrivateRouteProps): any {

  /**
   * 로그인 했는지 여부
   * 로그인 했을 경우 : true 라는 텍스트 반환
   * 로그인 안했을 경우 : null or false(로그아웃 버튼 눌렀을 경우 false로 설정)반환
   */
  // const isAuthenticated = getCookie(CONFIG.COOKIE_LOGIN.KEY3);
  const value1 = CONFIG.COOKIE_LOGIN.KEY1;
  const value2 = CONFIG.COOKIE_LOGIN.KEY2;
  const value3 = CONFIG.COOKIE_LOGIN.KEY3;
  const value4 = CONFIG.COOKIE_LOGIN.KEY4;
  const value5 = CONFIG.COOKIE_LOGIN.KEY5;
  const checkValues = () => {
    const coockieEmail = getCookie(value1);
    const coockieUsername = getCookie(value2);
    const coockieLogin = getCookie(value3);
    const coockieHd = getCookie(value4);
    const coockieRole = getCookie(value5);
    if (coockieEmail===null||coockieEmail===undefined||coockieEmail===false) {
      return false;
    }
    if (coockieUsername===null||coockieUsername===undefined||coockieUsername===false) {
      return false;
    }
    if (coockieLogin===null||coockieLogin===undefined||coockieLogin===false) {
      return false;
    }
    if (coockieHd===null||coockieHd===undefined||coockieHd===false) {
      return false;
    }
    if (coockieRole===null||coockieRole===undefined||coockieRole===false) {
      return false;
    }
    return true;
  }

  // 접근 권한이 필요하고
  if (authentication) {
    // 로그인이 되지 않았으면
    if (!checkValues()) {
      return <Navigate to="/en/signin" />
    } else {
      // 접근 권한이 필요하고 로그인 됐으면
      return <Outlet />
    }
    // 접근권한이 필요없고
  } else {
    // 로그인 되지 않았으면
    if (!checkValues()) {
      return <Outlet />
    } else {
      // 로그인 됐으면
      return <Outlet />
    }
  }
}
