import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CookiesProvider from "react-cookie/cjs/CookiesProvider";
import "bootstrap/dist/css/bootstrap.css";
import { CONFIG } from "./config";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
const root = document.getElementById("root");
if (root) {
  ReactDOM.createRoot(root).render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId={CONFIG.GOOGLE_OAUTH.KEY}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </GoogleOAuthProvider>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
