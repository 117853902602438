import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Nav.module.scss";
import { removeCookie } from "util/Cookie";
import { CONFIG } from "config";
import { googleLogout } from "@react-oauth/google";

function Nav() {
  const [hover_flag, setHover_flag] = useState(false);


  useEffect(() => {
    if (hover_flag) {
      document.getElementById('sideMenuButtonDiv')?.click();
    }

  }, [hover_flag])

  const ROUTES = {
    en: [
      // Index 0 는 기본 페이지 -> speech Advisor
      { title: "Speech Advisor", link: "speechAdvisor" },
      // Index 1 은 Chatbot 페이지 -> Ella
      // Index 2  부터는 정상적으로 표시
      // A.I. Learning Tools
      { title: "DoriDori", link: "doridori" },
      { title: "Semantic Analysis", link: "semanticAnalysis" },
      { title: "Questioner (Multiple Choice)", link: "learningByQuestion" },
      { title: "Qusetioner (Short Answer)", link: "questionShortAnswer" },
      { title: "", link: "" },
      { title: "Essay Outliner", link: "essayOutliner" },
      { title: "Study Note", link: "studyNote" },
      { title: "Summary for Kids", link: "summaryForKids" },
      { title: "Solution Provider", link: "solutionProvider" },
      { title: "Diary Assistant", link: "diaryAssistant" },
      { title: "", link: "" },
      { title: "Grammar Correction", link: "grammarCorrection" },
      { title: "", link: "" },
      { title: "Conversation  Evaluator", link: "conversationEvaluator" },
      // { title: "newPage", link: "newPage" },
      // { title: 'POLY DT Writing Center', link: 'polyWritingCenter' },
      { title: "", link: "" },

      // A.I. Persona Tools
      { title: 'Roleplay with AI', link: 'RolePlay' },
    ],
  };

  const renderNavLinks = (lang: "en", nums: number) => {
    const links = ROUTES[lang];
    return links.map((item, index: number) => {
      if (nums === 0) {
        if (index === 1) {
          return (
            <li className="menu-list-item" key={index}>
              <NavLink
                style={{ textDecoration: 'none', fontSize: '15px' }}
                to={`/${lang}/${item.link}`}
                className={({ isActive }) => {
                  return isActive ? "activeLink" : "navLink";
                }}
                onClick={() => {
                  if (hover_flag) {
                    setHover_flag(false);
                    document.getElementById('sideMenuButtonDiv')?.click();
                  }
                }}
              >
                <span>
                  <img
                    className={styles.headerImg}
                    alt={`${item.title}`}
                    src="../../images/ella_logo.png"
                    data-at2x="../../images/ella_logo.png"
                  />
                </span>
                {/* {item.title} */}
              </NavLink>
            </li>
          );
        }
      } else if (nums === 1) {
        if (index != 1 && index <= 15) {
          return (
            <li
              className="menu-list-item"
              key={index}
              style={{ marginLeft: "20px" }}
            >
              <NavLink
                style={{ textDecoration: 'none', fontSize: '15px' }}
                to={`/${lang}/${item.link}`}
                className={({ isActive }) => {
                  return isActive ? "activeLink" : "navLink";
                }}
                onClick={() => {
                  if (hover_flag) {
                    setHover_flag(false);
                    document.getElementById('sideMenuButtonDiv')?.click();
                  }
                }}
              >
                {item.title}
              </NavLink>
            </li>
          );
        }
      } else if (nums === 2) {
        if (index > 15 && index <= 17) {
          return (
            <li
              className="menu-list-item"
              key={index}
              style={{ marginLeft: "20px" }}
            >
              <NavLink
                style={{ textDecoration: 'none', fontSize: '15px' }}
                to={`/${lang}/${item.link}`}
                className={({ isActive }) => {
                  return isActive ? "activeLink" : "navLink";
                }}
                onClick={() => {
                  if (hover_flag) {
                    setHover_flag(false);
                    document.getElementById('sideMenuButtonDiv')?.click();
                  }
                }}
              >
                {item.title}
              </NavLink>
            </li>
          );
        }
      } else if (nums === 3) {
        if (index > 17) {
          return (
            <li
              className="menu-list-item"
              key={index}
              style={{ marginLeft: "25px" }}
            >
              <NavLink
                style={{ textDecoration: 'none', fontSize: '15px' }}
                to={`/${lang}/${item.link}`}
                className={({ isActive }) => {
                  return isActive ? "activeLink" : "navLink";
                }}
                onClick={() => {
                  if (hover_flag) {
                    setHover_flag(false);
                    document.getElementById('sideMenuButtonDiv')?.click();
                  }
                }}
              >
                {item.title}
              </NavLink>
            </li>
          );
        }
      }
    });
  };
  const logout = async () => {
    const value1 = CONFIG.COOKIE_LOGIN.KEY1;
    const value2 = CONFIG.COOKIE_LOGIN.KEY2;
    const value3 = CONFIG.COOKIE_LOGIN.KEY3;
    const value4 = CONFIG.COOKIE_LOGIN.KEY4;
    const value5 = CONFIG.COOKIE_LOGIN.KEY5;
    // 쿠키 삭제
    await removeCookie(value1);
    await removeCookie(value2);
    await removeCookie(value3);
    await removeCookie(value4);
    await removeCookie(value5);
    await googleLogout();

    window.location.replace("/en/speechAdvisor");
  };

  return (
    <>
      {/* // <!-- start left menu --> */}
      <header
        className="side-menu menu-style-2"
        onMouseLeave={() => {
          if (hover_flag) {
            setHover_flag(false)
            document.getElementById("sideMenuButtonDiv")?.click();
          }
        }}
        onMouseEnter={() => {
          if (!hover_flag) {
            setHover_flag(true);
          }
        }}
      >
        {/* <!-- start sidebar strip --> */}
        <div
          className=" sidebar-nav-action"
          style={{ display: "block", backgroundColor: "#000000e7" }}
        >
          <div className="sidebar-nav-action-main">
            <a className="logo">
              <img
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "3px",
                }}
                alt="UNITED ASSOCIATES"
                src="../../images/Logo_UA_white.png"
                data-at2x="../../images/Logo_UA_white.png"
              />
            </a>
            <div className="side-menu-button" id="sideMenuButtonDiv"></div>
          </div>
        </div>
        {/* <!-- end sidebar strip --> */}
        {/* <!-- start navigation --> */}
        <div className="bg-black sidebar-nav-menu navbar-expand-lg">
          <div className="bg-extra-dark-gray d-none d-lg-block"></div>
          {/* <!-- start menu --> */}
          <div
            className="col-12 padding-1-half-rem-lr sm-padding-1-rem-lr"
            style={{ height: "auto", minHeight: "80%" }}
          >
            <div
              className={`menu-list-wrapper` + styles.webscrollbarOptions}
              data-scroll-options='{ "theme": "light" }'
              style={{ overflowY: "auto" }}
            >
              <ul className="menu-list alt-font">
                <li className="menu-list-item" style={{ paddingTop: "30px" }}>
                  <a style={{ paddingTop: "0px" }}>
                    <p style={{ paddingTop: "0px" }}>
                      <span style={{ color: "#828282", paddingTop: "0px" }}>
                        UNITED ASSOCIATES
                      </span>
                      <br />
                      <span>
                        <span style={{ color: "#0096FF" }}>NLP DEMO</span>
                        <span style={{ color: "#FF2F92" }}>*</span>
                      </span>
                    </p>
                  </a>
                </li>
                {/* <!-- start menu item --> */}
                {renderNavLinks("en", 0)}
                {/* <!-- end menu item --> */}
                {/* <!-- start menu item --> */}
                <li className="menu-list-item">
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    to="#"
                    onClick={() => {
                      document.getElementById("menuToggleSub1")?.click();
                    }}
                  >
                    A.I. Learning Tools
                  </NavLink>
                  <span
                    id="menuToggleSub1"
                    className="menu-toggle"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-menu-1"
                    aria-expanded="false"
                  ></span>
                  <ul className="sub-menu-item collapse" id="sub-menu-1">
                    {renderNavLinks("en", 1)}
                  </ul>
                </li>
                <li className="menu-list-item">
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    to="#"
                    onClick={() => {
                      document.getElementById("menuToggleSub2")?.click();
                    }}
                  >
                    A.I. Persona Tools
                  </NavLink>
                  <span
                    id="menuToggleSub2"
                    className="menu-toggle"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-menu-2"
                    aria-expanded="false"
                  ></span>
                  <ul className="sub-menu-item collapse" id="sub-menu-2">
                    {renderNavLinks("en", 2)}
                  </ul>
                </li>

                <li className="menu-list-item">
                  <NavLink style={{ textDecoration: 'none' }} to="#" onClick={() => {
                    document.getElementById('menuToggleSub5')?.click();
                    const url = 'https://demo.ella.school/poly/Guideline_for_AI_Assisted_Dialogue.html';
                    window.open(url)
                  }}>Guideline for A.I. Assisted Dialogue</NavLink>
                </li>

                {/* <!-- end menu item --> */}
              </ul>
            </div>
          </div>
          {/* <!-- end menu --> */}
          {/* <!-- start footer --> */}
          <footer className="footer-dark footer-left-menu-modern bg-black">
            <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
              <div className="container">
                <div className="row align-items-center">

                  {/* {isAuthenticatedFlag && */}
                  <div className="col-12 text-center last-paragraph-no-margin sm-margin-20px-bottom">
                    <div
                      id="logoutBTN"
                      className={styles.logoutBtn}
                      onClick={async () => await logout()}
                    >
                      <span>{"Logout"}</span>
                    </div>
                  </div>
                  {/* } */}
                </div>
              </div>
            </div>
          </footer>
        </div>
        {/* <!-- end navigation --> */}
      </header>
    </>
  );
}

export default Nav;
