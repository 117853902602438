import React, { useState } from 'react';
import style from './Scenario.module.scss';
import { } from 'react-bootstrap';
import CustomSubmitButton from './components/recognitionButton';
import { apiMissionCheck, dialogApi, dialogApiDingdong } from 'util/RolePlay';
import { DdingdongHelp } from './components/DdingdongHelp';
import { checkChatInputValues } from './components/checkRegularExpression/checkRegExEvent';
import useLoadingStore from 'store/useLoadingStore';
import { loginTimeCheckAPI } from 'util/CallAPI';

// import { dialogApi } from '../../util/RolePlay'
export default function Scenario(props) {
  const { loading, setLoadingState } = useLoadingStore();
  const [checkedBox, setCheckedBox] = useState([false, false, false])
  const [missionChecked, setMissionChecked] = useState([false, false, false])

  const [inputValue, setInputValue] = useState("");
  const [helpInputValue, setHelpInputValue] = useState("");
  const [submitButtonDisabledFlag, setSubmitButtonDisabledFlag] = useState<boolean>(false);


  const [modalAgainValue, setModalAgainValue] = useState<string>('');
  const [modalAgainResult, setModalAgainResult] = useState<boolean>(false)
  const [modalRecommandedValue, setModalRecommandedValue] = useState<any>('');
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [dumyHelpInputValue, setDumyHelpInputValue] = useState<any>('');
  const [startFlag, setStartFlag] = useState<number>(0);
  // history
  const [history, setHistory] = useState<any>([["Ella", "Welcome to the restaurant. May I take your order?"]]);
  // ai
  const [ai, setAi] = useState<string>('Ella');
  // child
  const [child, setChild] = useState<string>('Yuna');
  // const [captionItems, setCaptionItems] = useState<any>([]);
  // popup
  const [popup, setPopup] = useState<any>({ open: false });


  // mission checkbox change handler
  const onChangeCheckedHandler = (check, i: number) => {
    let dumpChecked: any = setCheckedBox;
    dumpChecked[i] = check;

    setCheckedBox(dumpChecked)
  }


  const say = async (txt: string) => {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    try {
      const voiceUrl = `https://demo.ella.school:22111/aws-polly/${encodeURIComponent(txt)}`;
      const tts = document.getElementById('tts');
      if (tts) {
        tts.setAttribute('autoplay', 'true');
        tts.setAttribute('src', voiceUrl);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // this.setState({inputValue:''})
      setInputValue('')
    }
  }


  //String Levenshtein Distance
  const levenshteinDistance = (str1: string, str2: string) => {
    const track = Array(str2.length + 1).fill(null).map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i++) {
      track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j++) {
      track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j++) {
      for (let i = 1; i <= str1.length; i++) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1, //deletion
          track[j - 1][i] + 1, //insertion
          track[j - 1][i - 1] + indicator, //substitution
        );
      }
    }
    return track[str2.length][str1.length];
  }
  const diag = async (stc: string[]) => {
    // var { history, ai, child } = this.state;
    if (stc.length > 0) {
      history.push(stc)
    } else {
      // history = [];
      history.push()
    }
    const rsp = await dialogApi(history, ai, child);
    let rtnStr = rsp.data.data.text;
    history.push([rtnStr[0], rtnStr[1]]);

    setHistory(history)
    say(history[history.length - 1])
  }

  // STT Submit
  const submitUserMessage = async () => {
    setDumyHelpInputValue(helpInputValue)

    // 영어, 숫자, 특문 외의 문자 입력 막기
    let checkingV = checkChatInputValues(dumyHelpInputValue, 'Eng');

    if (checkingV) {
      let leven = levenshteinDistance(modalRecommandedValue.replace(/\s|\,|\.\s*$/g, '').toLowerCase(), dumyHelpInputValue.replace(/\s|\,|\.\s*$/g, '').toLowerCase());
      if (leven < 40) {
        if (leven < 1) {
          alert('Perfect!')
        } else if (leven >= 1 && leven < 5) {
          alert('Great!')
        } else {
          alert('good')
        }
        console.log('잘 읽었어요:', dumyHelpInputValue)
        //대화 진행
        await diag([child, modalRecommandedValue]);

        setInputValue('');
        setDumyHelpInputValue('');
        setPopup({
          open: false
        });

        setModalFlag(false);
      } else {
        alert('아쉬워요 다시 말해주세요');
      }
    }
  }

  //mission Restaurant Menu
  const divMenuinRestaurant = () => {
    return (
      <div className={style.italianMenu} id='italianMenu'>
        <div>
          <b>{`Italian restaurant`}</b>
        </div>
        <div>
          <i>{`Menu`}</i>
        </div>
        <div className={style.italianSubMenu}>
          <div className={style.italianSubMenu1}>
            <div className={style.italianSubMenu1Title}>{`- Main Dish -`}</div>
            {`Combo Pizza  $10.00`}<br />
            {`Tomato Spaghetti $8.00`}<br />
            {`Cream Spaghetti $8.50`}<br />
            {`Beef Lasagna $9.00`}<br />
            {`Grilled Steak $10.00`}<br />
          </div>
          <div className={style.italianSubMenu2}>
            <div className={style.italianSubMenu2Title}>{`- Beverage -`}</div>
            {`Water  $1.00`}<br />
            {`Coca-cola $1.00`}<br />
            {`Sprite $1.00`}<br />
            {`Orange Juice $2.00`}<br />
          </div>
          <div className={style.italianSubMenu3}>
            <div className={style.italianSubMenu3Title}>{`- Dessert -`}</div>
            {`Ice cream $0.50`}<br />
            {`Tea $ 0.50`}<br />
          </div>
        </div>
      </div>
    );
  }

  const mapMissionList = (v: string, i: number) => {
    return (
      <div key={i} className={`${style.missionCheck} h5`} >
        <input
          type='radio'
          id={'check' + i}
          checked={setCheckedBox[i]}
          disabled
          value={"" || ""}
          onChange={(e) => {
            // aria-readonly로 미션 성공 여부 확인 후 글자색 빨강, 초록으로 변경
            onChangeCheckedHandler(e.currentTarget.checked, i);
          }} />
        <label id={'check' + i + 'label'} htmlFor={'check' + i}
          aria-readonly={setMissionChecked[i] ? true : false} >
          {v.split(' - ').length === 1 && (
            <span id={'check' + i + 'span'} >
              {'Mission ' + (i + 1) + ' : ' + v}</span>
          )}
          {v.split(' - ').length !== 1 && (
            <span>{'Mission ' + (i + 1) + ' : ' + v.split(' - ')[0]}<br />
              {v.split(' - ').map((splitValue, j) => {
                return (
                  <span key={j}>{j !== 0 && '- ' + splitValue}</span>
                )
              })}
            </span>

          )}
        </label>
      </div>
    )
  }


  const missionList = props.missionList.map((v, i) => mapMissionList(v, i))

  const captionItems = history.slice(0).reverse().map((h, i) => {
    /** TODO: HARD CODED proper */
    const lineStyle = h[0] === ai ? style.doriLine : style.doriLineJulie;
    const elStyle = h[0] === ai ? 'doridori-caption' : 'doridori-caption-julie';
    return (
      <div key={i} className={lineStyle}>
        <div className={elStyle} id='caption'>
          {h[1]}
        </div>
      </div>
    )
  })

  const onSubmitMessageEvent = async () => {


    // history string[][]
    if (inputValue.length > 1) {
      history.push(["Yuna", inputValue]);
      const response = await dialogApi(history, ai, child);

      // response push
      history.push(response.data.data.text);
      // setHistory(history);
      setInputValue('');
    }
  }

  const controlStyle = { position: 'absolute', right: '0.5rem', top: '0.5rem' } as React.CSSProperties;




  const dingModal = async () => {
    // const { dialogApiDingdong } = this.props;
    // const { history, ai, child } = this.state;

    const rsp = await dialogApiDingdong(history, ai, child);
    let rtnStr = rsp.data.data.text;
    if (rtnStr.length > 1) {
      setModalRecommandedValue(rtnStr[1])
      say(rtnStr[1]);
    } else {
      setModalRecommandedValue(rtnStr)
      say(rtnStr);
    }
  }

  const setPopupButton = () => {

    setPopup({
      open: false
    })
    setModalFlag(false)
  }

  // mission check function
  const onClickMissionCheck = async () => {
    if (history.length < 2) {
      alert('Please Start chat.')
      return false;
    }
    setLoadingState(true);
    document.getElementById('check0')?.removeAttribute('disabled')
    document.getElementById('check1')?.removeAttribute('disabled')
    document.getElementById('check2')?.removeAttribute('disabled')
    //Mission1
    const rsp = await apiMissionCheck(history, ai, child);
    let missionChecked1 = rsp.data.data.mission1.split(',');
    if (missionChecked1[0].trim() === 'Yes') {
      document.getElementById('check0')?.click();
    } else {
      document.getElementById('check0label')?.setAttribute('aria-readonly', 'true');
    }
    //Mission2
    if (missionChecked1) {
      let missionChecked2 = rsp.data.data.mission2.split(',')
      if (missionChecked2[0].trim() === 'Yes') {
        document.getElementById('check1')?.click();
      } else {
        document.getElementById('check1label')?.setAttribute('aria-readonly', 'true');
      }
      //Mission3
      if (missionChecked2) {
        let missionChecked3 = rsp.data.data.mission3.split(',')
        if (missionChecked3[0].trim() === 'Yes') {
          document.getElementById('check2')?.click();
        } else {
          document.getElementById('check2label')?.setAttribute('aria-readonly', 'true');
        }
        document.getElementById('check0')?.setAttribute('disabled', '');
        document.getElementById('check1')?.setAttribute('disabled', '');
        document.getElementById('check2')?.setAttribute('disabled', '');
      }
    }
    setLoadingState(false);

  }

  return (
    <div>
      <div className={style.container}>
        <p className={style.title}>{props.title}</p>
        <div className={style.missionLists}>{props.desc}
          {missionList}
        </div>
        <div className={style.button} onClick={onClickMissionCheck}>{'Mission Check'}</div>
        <hr className={style.hrLine} />
      </div>
      <div>
        <div className={style.doridoriMain}>
          {divMenuinRestaurant()}
          <div className={style.doridoriCaptionContainer}>
            {/* 대화 div들을 담아두는 곳 */}
            {/* <div style={{ "border": "1px solid lightgray", "margin": "2vh", "width": "40vw", "height": "80vh", "display": "flex", "justifyContent": "space-between" }}> */}
            <div className="doridori-chats-container">
              {/* {history} */}
              {captionItems}
            </div>
            {/* 입력창 */}
            <div style={{ 'position': 'relative' }}>

              <textarea
                // rows={1}
                // style={{ 'resize': 'none', 'width': '40vw', 'margin': '2vh' }}
                placeholder={'Input Your message'}
                value={inputValue || ""}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                onKeyUp={async (e) => {
                  if (e.key === 'Enter') {
                    // Submit Event
                    if (inputValue.length > 1) {
                      await onSubmitMessageEvent()
                      setInputValue('');
                    }
                  }
                }}
              />
              {/* Submit button */}
              <div style={controlStyle}>
                {!modalFlag &&
                  <CustomSubmitButton
                    currentValue={inputValue}
                    disabled={submitButtonDisabledFlag}
                    onChange={setInputValue}
                    onSubmitMessage={onSubmitMessageEvent}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ "marginLeft": "6vw" }}
        >
          <hr className={style.hrLine} />

          <div
            className={style.ddingdongsButtonDiv}
            onClick={() => {
              document.getElementById('dingdong-button-scenario')?.click()
            }}
          >
            <button
              id='dingdong-button-scenario'
              type='button'
              className={style.ddingdongs}
              onClick={async () => {
                setPopup({
                  open: true
                })
                setModalFlag(true);
                await dingModal()
              }}
            >
            </button>
            {` I can help you if you need it. What should you say? Click here!`}
          </div>
        </div>
      </div>
      <br /><br /><br />
      <audio id="tts" />
      <div>
        <DdingdongHelp
          open={popup.open}
          setPopup={setPopupButton}
          setModalAgainResult={setModalAgainResult}
          modalAgainValue={modalAgainValue}
          modalRecommandedValue={modalRecommandedValue}
          say={say}
          modalValue={helpInputValue}
          submitUserMessage={submitUserMessage}
          dumyHelpInputValue={dumyHelpInputValue}
          disabled={submitButtonDisabledFlag}
          onModalChange={setHelpInputValue}
        />
      </div>
    </div>
  )
}
