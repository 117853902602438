import { ExamplesDiv } from "components/commonConst/example/example";
import { TextareaDiv } from "components/commonConst/input/textarea";
import { IExample } from "components/commonConst/NLUSemantic/semanticAnalysisProps";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./MyCustomGrammarly.module.scss";
import { loginTimeCheckAPI } from "util/CallAPI";
import { CONFIG } from "config";

type Props = {};

interface KeyObj {
  key: number;
}

interface KeyObj {
  key: number;
}

interface PhraseObj {
  str: string;
  //0 - default. 1 - color text. 2 - color strike through text, 3 - color backround
  type: string;
}

const green: string = "#13B617";
const red: string = "#DB1606";

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

function Default(str: string, keyObj: KeyObj): JSX.Element {
  return <span key={keyObj.key++}>{str}</span>;
}

function ColoredBackground(
  str: string,
  keyObj: KeyObj,
  color: string = green
): JSX.Element {
  return (
    <span style={{ backgroundColor: color }} key={keyObj.key++}>
      {str}
    </span>
  );
}


function ColoredStrikethrough(
  str: string,
  keyObj: KeyObj,
  color: string = red
): JSX.Element {
  return (
    <span
      style={{
        textDecoration: "line-through",
        color,
        backgroundColor: "#ffe4e4",
      }}
      key={keyObj.key++}
    >
      {str}
    </span>
  );
}

function Color(
  str: string,
  keyObj: KeyObj,
  color: string = green
): JSX.Element {
  const returnObj: JSX.Element[] = [];
  for (let i = 0; i < str.length; i++) {
    returnObj.push(
      <span style={{ color, backgroundColor: "#e4f4dc" }} key={keyObj.key++}>
        {str[i]}
      </span>
    );
  }
  return <span key={keyObj.key++}>{returnObj}</span>;
}

async function Middleware(
  text: string
): Promise<{ returnObj: JSX.Element[]; prev: string }> {
  const keyObj = { key: 0 };
  return await postData(CONFIG.GRAMMARLY_SERVER, {
    text: text,
  })
    .then((data) => {
      return data.json();
    })
    .then(async (data) => {
      const inputArr: PhraseObj[] = data.data.data;
      const prevResponse: string = data.data.corrected;
      const returnObj: JSX.Element[] = [];
      inputArr.forEach((x) => {
        if (x.type === "default") {
          returnObj.push(Default(x.str, keyObj));
        } else if (x.type === "add") {
          if (x.str === " ") {
            returnObj.push(ColoredBackground(x.str, keyObj));
          } else returnObj.push(Color(x.str, keyObj));
        } else if (x.type === "remove") {
          returnObj.push(ColoredStrikethrough(x.str, keyObj));
        } else {
          console.log(x.type);
          throw Error();
        }
      });
      return { returnObj, prev: prevResponse };
    })
    .catch((error: Error) => {
      throw new Error("Could not fetch data: " + text + " " + error.message);
    });
}

async function MainProcess(
  text: string,
  callback: (a: JSX.Element) => void,
  setBusy: (a: boolean) => void
) {
  try {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    const displayArr: JSX.Element[] = [];
    setBusy(true);
    const { returnObj, prev } = await Middleware(text);
    returnObj.forEach((x) => displayArr.push(x));
    setBusy(false);
    callback(<div>{displayArr}</div>);
  } catch (e: any) {
    setBusy(false);
    console.log(e);
    callback(
      <div>
        {Color(
          "Cannot process inputs. Try other inputs, or try again later",
          { key: 1 },
          red
        )}
      </div>
    );
  }
}

function MyCustomGrammarly(props: Props) {
  const [text, setText] = useState("");
  const [output, setOutput] = useState(<div></div>);
  const [busy, setBusy] = useState(false);
  const title = "Grammar Correction";
  const serverOK = true;
  const examples: IExample[] = [
    {
      text: "I remember the day I went to school on a very hot and humiday...",
      question:
        "I remember the day I went to school on a very hot and humiday. first I woke up I on a fan but the fan is no working I felt angry. I go to the school the clouths is wets I felt sad. in the school is cool because airconditioner was on I was happy. I felt sad but in the class I felt happy thank you listing.\n\nI want on the best camping trip ever last summer. First,I swam in clear blue water.and I saw fishes. Next,I bake a steak.We barbecued over the campfire it tast is very delicious. Then,we slept outside.I look at the stars it is beatiful and we listn to insects. This camping trip was fun~~! thank you for listning!!\n\nI went on the best camping trip ever last summer. During the day, we jumped into a huge lake, we swam in clear blue water and saw fish. In the evening, we grilled steak and ate dinner toghter. We barbecued ate the campfire, it was very pasty. At night,we slept outside under the night sky. Looked at the stars and listned to insecks. I stongly recommend you go camping for your next holiday.\n\nLast wensd feay was terrible for me. In the morning I wake up from a terrible nightmare forgot my homework and my teacher was angry felt awful. Then I had a noisy stomachache at school. stomachache big noise in classes everybody heard it and I felt embrrassed. Finally in history class I failed the test had a headache and forgot a test answers and I got upset. that was the worst day I have ever had.",
    },
    {
      text: 'I have read a beok, Lazy Jack. In the book "Lazy Jack" the...',
      question:
        'I have read a beok, Lazy Jack. In the book "Lazy Jack" the poor boy Jack playing the hero. Howevers, Jack have an indolent disposition. So people are call him "Lazy Jack".',
    },
    {
      text: "I like all the season, but winter is my favor weather. When...",
      question:
        "I like all the season, but winter is my favor weather. When it snow, I like to build snowman with my friends. I make a big snowballs and use carrot and rocks for the face. Also, I like to go sled on snowy days. I like to go to the big hill by my house and race down the hills. Finally, my favorite things in winter is having snowballs fights. I like to go to playground and play with my neighbor friends. I think winter is best season for these fun activities.",
    },
    {
      text: "When planning a class trip, there are lots of places you...",
      question:
        "When planning a class trip, there are lots of places you can consider. However, I think a trip to Everland would be the best idea because there is so much to do. First, Everland offers lots of things to do. For example, we can look at animals there. Also, we can eat snacks like popcorn, candy and hotdogs. If you don't like these things, you can always ride attractions or watch parades and fireworks. These is something for everyone! In addition, Everland is big enough for everybody. You can bring lots of people without feeling crowded. There will be always somehing to do. Plus, there is plenty of room to hang out with friends. In conclusion, Everland is the best place to have a class trip. There is lots to do, and it is big engouh for everyone!",
    },
  ];
  return (
    <>
      <div className={style.container}>
        {!serverOK ? (
          <h3
            style={{
              color: red,
              textAlign: "center",
              border: "1px solid",
              borderColor: "red",
              borderRadius: 10,
              padding: 10,
              marginTop: 20,
              marginBottom: 50,
            }}
          >
            ***The Grammar Correction is under development right now.***{" "}
          </h3>
        ) : null}
        <Row>
          <p className={style.title}>{title}</p>
        </Row>
        <Row style={{ padding: "0", marginBottom: "0.5rem" }}>
          <Col xs md="7">
            <p className="h5">
              <b> Grammar, spelling, punctuation correction.</b>
            </p>
          </Col>

          <Col
            xs
            md="5"
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <p className="h5">
              <b>{`Example`}</b>
            </p>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0.5rem" }}></Row>
        <Row>
          <Col xs md="7">
            <Row>
              <TextareaDiv
                pageTitle={"learningByQuestion"}
                buttonTitle={"Correction"}
                inputTitle={"Input Text"}
                buttonOnClickFunction={() => {
                  setOutput(<></>);
                  MainProcess(text, setOutput, setBusy);
                }}
                inputOnChangeFunction={setText}
                inputText={text}
                inputTextCount={text.length}
                disableButton={busy}
              />
            </Row>

            <div
              style={{
                border: "1px solid",
                borderColor: "green",
                borderRadius: 10,
                padding: 10,
                marginTop: 20,
                marginBottom: 50,
                whiteSpace: "pre-wrap",
              }}
            >
              {output}
            </div>
          </Col>
          <Col xs md="5">
            <div>
              <ExamplesDiv
                pageTitle={"learningByQuestion"}
                examples={examples}
                activeText={(example: string) => {
                  setText(example);
                }}
                actioveQuestionTitle={(text: string) => {}}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MyCustomGrammarly;
