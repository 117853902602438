// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/loginElla.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Signin_loginForm__yiKM1 {\n  width: 100%;\n  height: 100%;\n}\n\n.Signin_ellaImage__Nl85b {\n  max-width: 1060px;\n  max-height: 1080px;\n  height: 1080px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n}", "",{"version":3,"sources":["webpack://./src/pages/signin/Signin.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;AACF","sourcesContent":[".loginForm {\n  width: 100%;\n  height: 100%;\n}\n\n.ellaImage {\n  max-width: 1060px;\n  max-height: 1080px;\n  height: 1080px;\n  background-image: url(\"../../images/loginElla.png\");\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "Signin_loginForm__yiKM1",
	"ellaImage": "Signin_ellaImage__Nl85b"
};
export default ___CSS_LOADER_EXPORT___;
