import axios from 'axios';
import React from 'react';
// import skillWrapper, { ScenarioSkillProps } from '../ScenarioSkill';
import { useSpeechRecognition } from "react-speech-recognition";
import { useState } from "react";
import Scenario from 'pages/Scenario/Scenario';
// import dotenv from 'dotenv'
// dotenv.config({path: '.env', encoding: 'utf8'})

const scenario_chat_url = process.env.REACT_APP_SCENARIO_RESTAURANT_CHAT ? process.env.REACT_APP_SCENARIO_RESTAURANT_CHAT : '';
const scenario_help_url = process.env.REACT_APP_SCENARIO_RESTAURANT_HELP ? process.env.REACT_APP_SCENARIO_RESTAURANT_HELP : '';
const scenario_check_url = process.env.REACT_APP_SCENARIO_RESTAURANT_CHECK ? process.env.REACT_APP_SCENARIO_RESTAURANT_CHECK : '';


async function dialogApi(history?: string[][], ai?: string, child?: string): Promise<any> {
    const promptParams = {
        "history": history,
        "human_name": child,
        "ai_name": ai
    }
    if (scenario_chat_url !== '') {
        return await axios.post(scenario_chat_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
            }
        });
    }
}

async function dialogApiDingdong(history?: string[][], ai?: string, child?: string): Promise<any> {
    const promptParams = {
        "history": history,
        "human_name": child,
        "ai_name": ai
    }
    if (scenario_help_url !== '') {
        return await axios.post(scenario_help_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
            }
        });
    }
}

// apiOpen
async function apiMissionCheck(history?: string[][], ai?: string, child?: string): Promise<any> {

    const promptParams = {
        "history": history,
        "human_name": child,
        "ai_name": ai
    }
    if (scenario_check_url !== '') {
        return await axios.post(scenario_check_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
            }
        });
    }
};

function todaysDate() {
    let today = new Date();
    let todayStr = today.toString();
    //Thu, Nov.11, 2021
    //today.getFullYear() : 2021
    let year = today.getFullYear();
    //today.getDay() 요일(숫자)
    const WEEKDAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let day = WEEKDAY[today.getDay()];
    //today.getMonth()+1 : 월
    let month = todayStr.slice(4, 7);
    //today.getDate() : 일
    let date = today.getDate();
    let currentDay = day + ', ' + month + '.' + date.toString() + ', ' + year.toString();
    return currentDay;
}

async function preApi(): Promise<any> {
    const reqUrl = '/chitchat/pre';
    return await axios.get(reqUrl, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// const ScenarioSkill = skillWrapper('rolePlayen');
export default function ScenarioProps() {
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    const today = todaysDate();
    const config = {
        title: 'Roleplay with AI (AI와 역할놀이)',
        desc:
            <div>
                <p className='h5'><b>play role-playing conversation with AI.</b></p>
                <p className='h5'>If you don't know what to say in the conversation,</p>
                <p className='h5'>Click on the <img style={{ 'width': '22px', 'height': 'auto' }} src={require('../../../pages/Scenario/image/dingdong.png')} />("Dingdong") button or speak "hey ding-dong" to call Dingdong.</p>
                <p className='h5'>If you want to use Dingdong's words, then speak following the sentence recommended by Dingdong.</p>
            </div>,
        dialogApi: dialogApi,
        dialogApiDingdong: dialogApiDingdong,
        apiMissionCheck: apiMissionCheck,
        preApi: preApi,
        lang: 'en',
        transcript: transcript,
        resetTranscript: resetTranscript,
        setIsListening: setIsListening,
        isListening: isListening,
        placeholder: 'Type the message ...',
        recognitionPlaceholder: 'Listening ...',
        diaryText: today + `\nToday Ella met Yuna for the first time.`,
        // missions
        missionList: [
            'Please order a main dish at the restaurant.',
            'Please order a beverage at the restaurant.',
            'Please ask for the bill to the waitress after you were finished eating. - If you have made the payment, please ask if it has been completed.',
        ]
    };

    return <Scenario {...config} />

}