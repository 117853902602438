import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "react-bootstrap";
import axios from "axios";
import useLoginStore from "../../store/useLoginStore";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../util/Cookie";
import { CONFIG } from "../../config";

export default function GoogleLoginButton() {
  const navigate = useNavigate();
  const { setUserInfo } = useLoginStore();
  /**
   * 로그인 했는지 여부
   * 로그인 했을 경우 : true 라는 텍스트 반환
   * 로그인 안했을 경우 : null or false(로그아웃 버튼 눌렀을 경우 false로 설정)반환
   */

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get(CONFIG.GOOGLE_OAUTH.URL, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      const data = {
        tokenResponse: {
          access_token: tokenResponse.access_token,
          hd: tokenResponse.hd,
          scope: tokenResponse.scope,
          token_type: tokenResponse.token_type,
        },
        userInfo: userInfo,
        rUrl: '3000'
      };

      const rsp = await axios.post(CONFIG.GOOGLE_OAUTH.BACKEND_URL, data, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }).then(async rsp => {
        
        if (data['role']!==0) {
          const role_url = CONFIG.UA_HS_USERS_ROLE_CHECK_URL;
          const adminHeckRsp = await axios.post(role_url, {
            access_email: data.userInfo.email,
            access_name: data.userInfo.name,
            access_hd: data.tokenResponse.hd
          },{
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }).then(res => {
            return res.data.role;
          });
          setCookie(CONFIG.COOKIE_LOGIN.KEY5, adminHeckRsp);
        }
        return rsp;
      });
      const datas = rsp.data;
      // 스토어 변수 저장
      const value1 = datas[CONFIG.RESPONSE_DATE_LOGIN.KEY1];
      const value2 = datas[CONFIG.RESPONSE_DATE_LOGIN.KEY2];
      const value3 = datas[CONFIG.RESPONSE_DATE_LOGIN.KEY3];
      const value4 = data.userInfo.hd;
      setUserInfo(value1, value2, value3);
      // let maxAge: number = 10;
      setCookie(CONFIG.COOKIE_LOGIN.KEY1, value1);
      setCookie(CONFIG.COOKIE_LOGIN.KEY2, value2);
      setCookie(CONFIG.COOKIE_LOGIN.KEY3, value3);
      setCookie(CONFIG.COOKIE_LOGIN.KEY4, value4)

      const isAuthenticated = getCookie(CONFIG.COOKIE_LOGIN.KEY3);

      if (isAuthenticated) {
        return navigate(`/en/speechAdvisor`);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return <Button onClick={() => googleLogin()}>Login with Google 🚀</Button>

}
