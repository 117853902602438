import axios from 'axios';
import React from 'react';
import { CONFIG } from '../config';
import { loginTimeCheckAPI } from './CallAPI';

const scenario_chat_url = CONFIG.SCENARIO_RESTAURANT_CHAT
const scenario_help_url = CONFIG.SCENARIO_RESTAURANT_HELP
const scenario_check_url = CONFIG.SCENARIO_RESTAURANT_CHECK
export async function dialogApi(history?: string[][], ai?: string, child?: string): Promise<any> {
    if (loginTimeCheckAPI !== undefined) {
        await loginTimeCheckAPI();
    }
    const promptParams = {
        "history": history,
        "human_name": child,
        "ai_name": ai
    }
    if (scenario_chat_url !== '') {
        return await axios.post(scenario_chat_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            }
        })
    }
}


export async function dialogApiDingdong(history?: string[][], ai?: string, child?: string): Promise<any> {
    if (loginTimeCheckAPI !== undefined) {
        await loginTimeCheckAPI();
    }
    const promptParams = {
        "history":history,
        "human_name":child,
        "ai_name":ai
    }
    if (scenario_help_url!=='') {
        return await axios.post( scenario_help_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json", 
            }
        });
    }
}

export async function apiMissionCheck(history?: string[][], ai?: string, child?: string): Promise<any>{
    if (loginTimeCheckAPI !== undefined) {
        await loginTimeCheckAPI();
    }
    const promptParams = {
        "history": history,
        "human_name": child,
        "ai_name":ai
    }
    if(scenario_check_url !== '') {
        return await axios.post(scenario_check_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            }
        });
    }
} 