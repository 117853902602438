import React from 'react';

export const SolutionProviderProps = {
    title: 'Solution Provider',
    desc: <div>
        <p className='h5'><b>If you're stuck on a problem, it can be helpful to brainstorm a list of potential solutions.</b></p>
        <p className='h5'><b>This is the list of 10 potential solution ideas for you to explore, based on your given problem.</b></p>
    </div>,
    inputs: [{
        title: 'Enter Your Problems',
        type: 'text',
        name: 'question',
    }],
    examples: [{
        question: 'I want to be fluent in English.',
    }, {
        question: 'I want to become famous and rich.',
    }, {
        question: 'I want to win the game.',
    }, {
        question: 'I want to make a great and shaped body.',
    }, {
        question: 'I am a single and I would like to make a girlfriend.',
    }],
    renderAnswer: { type: 'solutionProvider' },
}