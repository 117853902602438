import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import React from 'react';
import { loginTimeCheckAPI } from 'util/CallAPI';

export const InputDiv = (props: {
    buttonTitle: any;
    inputTitle: any;
    buttonOnClickFunction: Function;
    inputOnChangeFunction: Function;
    inputText?: any;
    pageTitle?: string;
    multiButtonClickEventFunction?: Function[];
    hideAndExtendFlagged?: boolean;
}) => {
    const {
        pageTitle,
        buttonTitle,
        inputText,
        buttonOnClickFunction,
        inputOnChangeFunction,
        inputTitle,
        multiButtonClickEventFunction,
        hideAndExtendFlagged
    } = props;

    return (
        <div>
            {pageTitle === 'diaryAssistant' &&
                <>
                    <br />
                    {/* Date */}
                    <Form.Label htmlFor="input-div"><h5>{`What is today's date?`}</h5></Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            id="input-div"
                            onChange={(e) => {
                                inputOnChangeFunction(e.target.value, 0);
                            }}
                            defaultValue={inputText.inputDate}
                        // value={inputText.inputDate}
                        />
                    </InputGroup>
                    {/* Event */}
                    <Form.Label htmlFor="input-div"><h5>{'Please descripbe the most memorable event happened today.'}</h5></Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            as='textarea'
                            id="input-div1"
                            style={{ "height": "30vh" }}
                            onChange={(e) => {
                                inputOnChangeFunction(e.target.value, 1);
                            }}
                            defaultValue={inputText.inputMemorableEvent}
                        // value={inputText.inputDate}
                        />
                    </InputGroup>
                    {/* Future */}
                    <Form.Label htmlFor="input-div"><h5>{'How would you describe your feelings about what happened today?'}</h5></Form.Label>

                    <InputGroup className="mb-3">
                        <Form.Control
                            type='textarea'
                            height={"300px"}
                            id="input-div1"
                            onChange={(e) => {
                                inputOnChangeFunction(e.target.value, 3);
                            }}
                            defaultValue={inputText.inputFuture}
                        // value={inputText.inputDate}
                        />
                    </InputGroup>
                    {/* Feeling */}
                    <Form.Label htmlFor="input-div"><h5>{inputTitle}</h5></Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type='textarea'
                            height={"300px"}
                            id="input-div1"
                            onChange={(e) => {
                                inputOnChangeFunction(e.target.value, 2);
                            }}
                            defaultValue={inputText.inputTodayFeelingsAbout}
                        // value={inputText.inputDate}
                        />
                    </InputGroup>
                </>
            }
            {(pageTitle === 'conversationEvaluator') && inputTitle.map((value, idx) => {
                if (idx === 1) {
                    return (
                        <Row key={idx}>
                            <Form.Label htmlFor="input-div" hidden={hideAndExtendFlagged}><h5>{value.title}</h5></Form.Label>
                            <InputGroup className='mb-3' hidden={hideAndExtendFlagged}>
                                <Form.Select
                                    as={'select'}
                                    aria-label={value.title}
                                    id="input-div"
                                    onChange={(e) => {
                                        inputOnChangeFunction(e.target.value, idx)
                                    }}
                                    defaultValue={inputText[value.name]}
                                >
                                    <option value={value.defaultValues[0]}>{value.defaultValues[0]}</option>
                                    <option value={value.defaultValues[1]}>{value.defaultValues[1]}</option>
                                </Form.Select>
                            </InputGroup>
                        </Row>
                    )
                } else {
                    return (
                        <Row key={idx}>
                            <Form.Label htmlFor="input-div" hidden={hideAndExtendFlagged}><h5>{value.title}</h5></Form.Label>
                            <InputGroup className="mb-3" hidden={hideAndExtendFlagged}>
                                <Form.Control
                                    as={'textarea'}
                                    rows={idx === 0 ? 1 : 10}
                                    id="input-div"
                                    placeholder={value.placeHorder ? value.placeHorder : ''}
                                    onChange={(e) => {
                                        inputOnChangeFunction(e.currentTarget.value, idx);
                                    }}
                                    onKeyUp={(e) => {
                                        inputOnChangeFunction(e.currentTarget.value, idx);
                                    }}
                                    defaultValue={inputText[value.name] !== '' ? inputText[value.name] : ''}
                                />
                            </InputGroup>
                        </Row>
                    )
                }
            })}
            {(pageTitle !== 'diaryAssistant' && pageTitle !== 'conversationEvaluator') &&
                <InputGroup className="mb-3">
                    <Form.Control
                        id="input-div"
                        onChange={(e) => {
                            inputOnChangeFunction(e.target.value, 0);
                        }}
                        value={inputText}
                    />
                </InputGroup>
            }
            {/* Button Div */}
            {(pageTitle === 'conversationEvaluator') && buttonTitle.map((value, idx) => {
                return (

                    <Button key={idx}
                        style={{ marginBottom: "2rem" }} variant="primary" type="button"
                        onClick={async () => {
                            if (loginTimeCheckAPI !== undefined) {
                                await loginTimeCheckAPI();
                            }
                            if (multiButtonClickEventFunction !== undefined) {
                                multiButtonClickEventFunction[idx]();
                            }
                        }}>
                        {value.length !== 2 ? value : (hideAndExtendFlagged ? value[1] : value[0])}
                    </Button>
                )
            })}
            {pageTitle !== 'conversationEvaluator' &&
                <Button style={{ marginBottom: "2rem" }} variant="primary" type="button" onClick={() => buttonOnClickFunction()}>
                    {buttonTitle}
                </Button>
            }
        </div>

    );
};
