import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import Error from "./pages/error/Error";
import Nav from "./pages/nav/Nav";
import style from "./App.module.scss";
import Router from "./routes";
import useLoadingStore from "store/useLoadingStore";

function App() {
  const { loading, setLoadingState } = useLoadingStore();

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <BrowserRouter>
        {/* Nav */}
        <Nav />
        {loading && <div className={style.modal} />}
        <main className={style.main}>
          <Router />
        </main>
        {/* Footer */}
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
