import { Route, Routes } from 'react-router-dom';
import Signin from '../pages/signin/Signin';
import Home from '../pages/home/Home';
import NotFound from '../pages/notFound/NotFound';
import SpeechAdvisor from '../pages/speechAdvisor/SpeechAdvisor';
import PrivateRoute from './PrivateRoute';
import DoriDori, { DoriDoriSkillProps } from '../pages/DoriDori';
import { useSpeechRecognition } from 'react-speech-recognition';
import { useState } from 'react';
import { dialogApi, dialogApiHint, preApi } from '../util/chatAPI';
import BasicLayoutPage from 'pages/Basic/BasicLayoutPage';
import { NLUSemanticAnalysisProps } from '../components/commonConst/NLUSemantic/semanticAnalysisProps';
import { LearningByQuestionProps } from '../components/commonConst/LearningByQuestion/LearningByQuestionProps';
import { NewPageProps } from 'components/commonConst/NewPage/newPageProps';
import { questionShortAnswerProps } from 'components/commonConst/QuestionShortAnswer/QuestionShortAnswerProps';
import { EssayOutlinerProps } from 'components/commonConst/EssayOutliner/EssayOutlinerProps';
import { StudyNoteProps } from 'components/commonConst/StudyNote/StudyNoteProps';
import { SummaryForKids } from 'components/commonConst/SummaryForKids/SummaryForKidsProps';
import { SolutionProviderProps } from 'components/commonConst/SolutionProvider/SolutionProviderProps';
import { DiaryAssistantProps } from 'components/commonConst/DiaryAssistant/DiaryAssistantProps';
import { ConversationEvaluatorProps } from 'components/commonConst/ConversationEvaluator/ConversationEvaluatorProps';
import ScenarioProps from 'components/commonConst/Scenario/ScenarioProps';
import { polyWritingCenterProps } from 'components/POLYWritingCenter/POLYWritingCenterProps';
import MyCustomGrammarly from 'pages/grammarly/MyCustomGrammarly';

export default function Router() {
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    const config: DoriDoriSkillProps = {
        title: '',
        desc: <div style={{ marginTop: '1em' }}></div>,
        dialogApi: dialogApi,
        preApi: preApi,
        lang: 'en',
        transcript: transcript,
        resetTranscript: resetTranscript,
        setIsListening: setIsListening,
        isListening: isListening,
        placeholder: 'Type the message ...',
        recognitionPlaceholder: 'Listening ...',
        dialogApiHint: dialogApiHint,
    };

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/en/signin" element={<Signin />} />
            {/* 접근 권한이 필요 X */}
            <Route element={<PrivateRoute authentication={false} />}>
                <Route path="/en/signin" element={<Signin />} />
            </Route>

            {/* 접근 권한이 필요 */}
            <Route element={<PrivateRoute authentication={true} />}>
                <Route path="/en/speechAdvisor" element={<SpeechAdvisor />} />
                <Route path="/en/doridori" element={<DoriDori {...config} />} />
                <Route path="en/semanticAnalysis" element={<BasicLayoutPage pageTitle='semanticAnalysis' pageProps={NLUSemanticAnalysisProps} />} />
                <Route path="en/learningByQuestion" element={<BasicLayoutPage pageTitle={'learningByQuestion'} pageProps={LearningByQuestionProps} />} />
                <Route path="en/newPage" element={<BasicLayoutPage pageTitle={'newPage'} pageProps={NewPageProps} />} />
                <Route path="en/questionShortAnswer" element={<BasicLayoutPage pageTitle={'questionShortAnswer'} pageProps={questionShortAnswerProps} />} />
                <Route path="en/essayOutliner" element={<BasicLayoutPage pageTitle={"essayOutliner"} pageProps={EssayOutlinerProps} />} />
                <Route path="en/studyNote" element={<BasicLayoutPage pageTitle={"studyNote"} pageProps={StudyNoteProps} />} />
                <Route path="en/summaryForKids" element={<BasicLayoutPage pageTitle={"summaryForKids"} pageProps={SummaryForKids} />} ></Route>
                <Route path="en/solutionProvider" element={<BasicLayoutPage pageTitle={"solutionProvider"} pageProps={SolutionProviderProps} />}></Route>
                <Route path="en/diaryAssistant" element={<BasicLayoutPage pageTitle={"diaryAssistant"} pageProps={DiaryAssistantProps} />}></Route>
                <Route path="en/conversationEvaluator" element={<BasicLayoutPage pageTitle={"conversationEvaluator"} pageProps={ConversationEvaluatorProps} />} />
                <Route path="en/polyWritingCenter" element={<BasicLayoutPage pageTitle={"polyWritingCenter"} pageProps={polyWritingCenterProps} />} />
                <Route path="en/grammarCorrection" element={<MyCustomGrammarly />} />
                <Route path="en/RolePlay" element={<ScenarioProps />} />
            </Route>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
    );
}
