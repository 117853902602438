import {create} from "zustand";

const useLoadingStore = create<ILoadingState>((set) => ({
    loading: false,
    setLoadingState: (loading: boolean) => {
        set(()=>({loading}))
    }
}));

export default useLoadingStore;
