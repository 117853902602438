import axios from 'axios';
import React from 'react';
import { loginTimeCheckAPI } from './CallAPI';

export async function QuestionShortAnswerAPI(text, question, url) {
  if (loginTimeCheckAPI !== undefined) {
    await loginTimeCheckAPI();
}
    const req = {
        context: text,
        question: question,
    };
      return await axios.post(url, req,{
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
      }
      });
}