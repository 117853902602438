import React from 'react'
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'

export const SolutionProviderResult = (props) => {
    const { responseData, i } = props;

    return (
        // style={{ "border": "1px solid lightGray", "borderRadius": "0.25rem", "padding": "20px", "margin": "20px 20px 20px 20px", "backgroundColor": "white", "lineHeight": "2rem" }}
        <div className={style.basic} key={i} >
            <p style={{
                "paddingLeft": "1rem",
                "paddingTop": "0.5rem",
                "paddingBottom": "0.5rem",
                "fontSize": "1.4rem",
                "display": "block",
                "color": "#00008b"
            }}>
                {`Problem ${i + 1} : `}
                <span>{`${responseData.problem}`}</span>
                {/* <span className={style.solutionProviderSpan}>{`${responseData.problem}`}</span> */}
            </p>
            <hr />

            {responseData.text.map((value, index) => {
                return <p key={`solution${i}-${index}`} style={{ "fontSize": "1.2rem", "paddingLeft": "1rem" }}>{`${value[0]}. ${value[1]}`}</p>
            })}
        </div>
    )
}
