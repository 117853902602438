import { IExample } from '../NLUSemantic/semanticAnalysisProps';
import { ListGroup, Col, Row, Tab } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import style from './example.module.scss';
import usePOLYDTStore from 'store/usePOLYDTStore';
export const ExamplesDiv = (props: { examples: IExample[]; activeText: Function; pageTitle: string; actioveQuestionTitle?: any }) => {
    const { examples, activeText, pageTitle, actioveQuestionTitle } = props;
    const { setInputValueStore } = usePOLYDTStore();
    const [active, setActive] = useState('');
    const [activeFlag, setActiveFlag] = useState(0);
    const [pagePath, setPagePath] = useState('');
    // render할 때 초기화
    useEffect(() => {
        if (active === '' || !active) {
            setActive(examples[0].question);
            activeText(examples[0].question);
            setInputValueStore(examples[0].question);
        }
        if (!activeFlag) {
            setActiveFlag(0);
        }
    }, []);
    // page title 바뀌면 초기화
    useEffect(() => {
        if (pagePath === '') {
            setPagePath(pageTitle)
        }
        if (pageTitle !== pagePath) {
            setPagePath(pageTitle)
            setActive(examples[0].question);
            activeText(examples[0].question);
            setActiveFlag(0);
            setInputValueStore(examples[0].question);
        }
    }, [pageTitle])
    const activeCss = {
        backgroundColor: '#D3D3D3',
        color: '#333',
        border: 'none',
    };
    const nonActiveCss = {
        color: '#333',
    };

    const exampleResultValue = (exampleValue: IExample) => {
        if (pageTitle === 'semanticAnalysis') {
            return exampleValue.question;
        } else if (pageTitle === 'learningByQuestion') {
            return exampleValue.text;
        } else if (pageTitle === 'newPage') {
            return exampleValue.question
        } else if (pageTitle === 'questionShortAnswer') {
            return exampleValue.question
        } else if (pageTitle === 'essayOutliner') {
            return exampleValue.question
        } else if (pageTitle === 'studyNote') {
            return exampleValue.question
        } else if (pageTitle === 'summaryForKids') {
            return exampleValue.text
        } else if (pageTitle === 'solutionProvider') {
            return exampleValue.question
        } else if (pageTitle === 'polyWritingCenter') {
            return exampleValue.question
        }
    }

    return (
        <Tab.Container id="list-group-tabs-example" defaultActiveKey={0}>

            <Row>
                <Col>
                    <ListGroup className={style.examples} as="ul">
                        {examples &&
                            examples.map((example, exampleIdx) => {
                                let activeValue = false;
                                if (activeFlag === exampleIdx) {
                                    activeValue = true;
                                }

                                return (
                                    <ListGroup.Item
                                        as="li"
                                        style={activeValue ? activeCss : nonActiveCss}
                                        key={exampleIdx}
                                        onClick={() => {
                                            activeText((pageTitle === 'questionShortAnswer') ? example.text : example.question);
                                            actioveQuestionTitle(example.question)
                                            setActiveFlag(exampleIdx);
                                            setInputValueStore(example.question);
                                        }}
                                        active={activeValue}
                                    >
                                        {exampleResultValue(example)}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Col>
            </Row>
        </Tab.Container>
    );
};
