import { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Lottie from 'react-lottie';
import animationData from './microfone.json';

interface ICustomSubmitButton {
    onChange: Function;
    onSubmitMessage: Function;
    currentValue: string;
    disabled: boolean;
}
const CustomSubmitButton = (props: ICustomSubmitButton) => {
    const {
        currentValue,
        disabled,
        onChange,
        onSubmitMessage
    } = props;
    const [disabledFlagged, setDisabledFlagged] = useState(disabled);
    // const [animationData, setAnimationData] = useState();
    const [lottieOptions, setLottieOptions] = useState({
        loop: false,
        autoplay: false,
        animationData: animationData,
    });
    const {
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,

    } = useSpeechRecognition();

    useEffect(() => {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        }
        if (disabled === undefined) {
            setDisabledFlagged(true)
        } else {
            setDisabledFlagged(disabled)
        }
    })

    useEffect(() => {
        if (listening) {
            setLottieOptions({ loop: true, autoplay: true, animationData: animationData })
        } else {
            setLottieOptions({ loop: false, autoplay: false, animationData: animationData })
        }
    }, [listening])

    useEffect(() => {
        if (interimTranscript !== '' && finalTranscript === '') {
            onChange(interimTranscript)
        } else if (interimTranscript === '' && finalTranscript !== '') {
            const stopFunc = async () => { await StopListen(); }
            stopFunc();
        }
    }, [interimTranscript, finalTranscript])

    const listenContinously = async () => {
        if (currentValue !== '') {

        } else {
            await SpeechRecognition.startListening({
                continuous: true,
                language: 'en-US'
            })
        }
    }

    const StopListen = async () => {
        SpeechRecognition.stopListening();
        await onSubmitMessage();
        onChange('');
        resetTranscript();
    }

    // const defaultOptions = {
    //     loop: false,
    //     autoplay: false,
    //     animationData: animationData,
    // }
    // const defaultOptions1 = {
    //     loop: true,
    //     autoplay: true,  
    //     animationData: animationData,
    // }
    // const buttonStyle = {
    //     display: 'block',
    //     margin: '10px auto'
    // }

    return (
        <div>
            {listening && (
                <button
                    // style={buttonStyle}
                    style={{ 'backgroundColor': 'white', 'border': 'none' }}
                    type="button"
                    onClick={async () => await StopListen()}
                ><Lottie
                        options={lottieOptions}
                        height={'2.5vw'}
                        width={'2.5vw'}
                        isStopped={false}
                    // loop
                    // animationData={animationData}
                    // play
                    // style={{ width: '1rem', height: '1rem' }}
                    /></button>
            )}
            {!listening && (
                <button
                    type="button"
                    // style={buttonStyle}
                    style={{ 'backgroundColor': 'white', 'border': 'none' }}
                    disabled={disabledFlagged}
                    onClick={async () => await listenContinously()}
                ><Lottie
                        options={lottieOptions}
                        height={'2.5vw'}
                        width={'2.5vw'}
                        isStopped={true}
                    // isPaused={lottiePause}
                    // loop
                    // animationData={animationData}
                    // play
                    // style={{ width: '1rem', height: '1rem' }}
                    /></button>
            )}
        </div>
    )
}
export default CustomSubmitButton;