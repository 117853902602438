import { Row, Table } from 'react-bootstrap';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss';
interface INLUSemanticAnalysisDivProps {
    mess: IResultData[];
    i?: number;
}
interface IResultData {
    originText: string;
    text: any[];
    parseResult: string;
    levelCount: { 1: number; 2: number; 3: number; 4: number; 5: number; 6: number };
    multiWordLevels: any[];
    cefr: {
        averageLevel: string;
        levelAverageNumber: number;
        levelCount: { A1: number; A2: number; B1: number; B2: number; C1: number; C2: number };
        multiWordLevels: any[];
    };
}

const isRTL = (s: any) => {
    const ltrChars =
        'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
        '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
    const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
    const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);

    return rtlDirCheck.test(s) ? 'rtl' : 'ltr';
};

export const NLUSemanticAnalysisDiv = (props: INLUSemanticAnalysisDivProps) => {
    const { mess, i } = props;

    let dumyDiv = mess.map((mes, i) => {

        let parser_arr = [mes.text];
        // original text
        let text: any = [];
        text.push(mes.originText);
        // sentence count
        let dependency_length = parser_arr.length;
        let cefr = mes.cefr;
        let cefr_level_arr = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

        let multi_word_levels = mes.multiWordLevels;
        let level_count = mes.levelCount;
        let level_arr = [1, 2, 3, 4, 5, 6];

        let rootTokenText: string[] = [];
        let all_dependencies: any[] = [];

        // table display data
        let dependent_list_array: any[] = [];
        for (let i = 0; i < dependency_length; i++) {
            const sentence = parser_arr[i];
            let sentence_dependence: any[] = [];
            let dependent_list: any = [];
            for (const parser_key in sentence) {
                sentence_dependence[parser_key] = sentence[parser_key];
                const sentence_deep = sentence[parser_key];
                for (const sen_key in sentence_deep) {
                    const deps = sentence_deep[sen_key].dep;
                    dependent_list.push(sentence_deep[sen_key]);
                    if (deps === 'ROOT') {
                        rootTokenText.push(sentence_deep[sen_key].text);
                    }
                }
            }
            dependent_list_array.push(dependent_list);
            all_dependencies.push(sentence_dependence);
        }
        return (
            <div className={style.basic}
                dir={isRTL(mes.text)} key={i}>
                {/* <Row style={{ 'border': 'none', 'padding': '20px ' }}> */}
                <Table bordered>
                    {i === 0 && (<thead>
                        <tr className="h5">
                            <th
                                style={{
                                    "backgroundColor": '#fff',
                                    "color": '#333',
                                    "textAlign": 'center',
                                    "verticalAlign": 'middle',
                                }}
                                colSpan={2}
                            >
                                {'Origin Text'}
                            </th>
                            <th style={{ backgroundColor: '#fff', color: '#333' }} colSpan={8}>
                                {text}
                            </th>
                        </tr>
                    </thead>)}
                    {all_dependencies.map((sentence, sentence_count) => {
                        return (
                            <tbody key={sentence_count}>
                                <tr className="h5">
                                    <th
                                        style={{ backgroundColor: '#fff', color: '#333' }}
                                        colSpan={10}
                                    >
                                        {sentence.map((v, i) => {
                                            if (v[0].dep.replace(/\s/g, '') === 'ROOT') {
                                                if (v[0].pos.match(/^VB/g)) {
                                                    return (
                                                        <span key={i}>
                                                            <span>{` `}</span>
                                                            <span
                                                                className={
                                                                    style.spanTokenColorROOTVB
                                                                }
                                                            >
                                                                {v[0].text}
                                                            </span>
                                                        </span>
                                                    );
                                                } else {
                                                    return (
                                                        <span key={i}>
                                                            <span>{` `}</span>
                                                            <span
                                                                className={
                                                                    style.spanTokenColorROOTNotVB
                                                                }
                                                            >
                                                                {v[0].text}
                                                            </span>
                                                        </span>
                                                    );
                                                }
                                            } else if (
                                                v[0].dep.replace(/\s/g, '') === 'nsubj'
                                            ) {
                                                if (
                                                    v[0].governorGloss.match(
                                                        rootTokenText[sentence_count]
                                                    )
                                                ) {
                                                    return (
                                                        <span key={i}>
                                                            <span>{` `}</span>
                                                            <span
                                                                className={
                                                                    style.spanTokenColorPRP
                                                                }
                                                            >
                                                                {v[0].text}
                                                            </span>
                                                        </span>
                                                    );
                                                } else {
                                                    return (
                                                        <span key={i}>{` ${v[0].text}`}</span>
                                                    );
                                                }
                                            } else {
                                                if (v[0].dep.replace(/\s/g, '') === 'punct') {
                                                    return (
                                                        <span key={i}>{`${v[0].text}`}</span>
                                                    );
                                                } else if (v[0].pos.match(/^VB/g)) {
                                                    return (
                                                        <span key={i}>
                                                            <span>{` `}</span>
                                                            <span
                                                                className={
                                                                    style.spanTokenColorEtcVB
                                                                }
                                                            >
                                                                {v[0].text}
                                                            </span>
                                                        </span>
                                                    );
                                                } else {
                                                    return (
                                                        <span key={i}>{` ${v[0].text}`}</span>
                                                    );
                                                }
                                            }
                                        })}
                                    </th>
                                </tr>
                                <tr className="h5" style={{ textAlign: 'center' }}>
                                    <th>{`Token`}</th>
                                    <th>{`Text Token`}</th>
                                    <th>{`Lemma`}</th>
                                    <th>{`Pos`}</th>
                                    <th>{`POS TAG`}</th>
                                    <th>{`Dep`}</th>
                                    <th>{`Governor`}</th>
                                    <th>{`Governor Gloss`}</th>
                                    <th>{`Level`}</th>
                                    <th>{`CEFR Level`}</th>
                                </tr>
                                {dependent_list_array[sentence_count].map((v, j) => {
                                    const arrays: any[] = [];
                                    const display_level = v.level === 0 ? '' : v.level;
                                    const word_cefr = v.cefr_level;
                                    const cefr_level = word_cefr.cefr_level;
                                    const cefr_level_average =
                                        word_cefr.total_cefr_average_level;
                                    if (v.dep === 'ROOT') {
                                        const row_span = cefr_level.length;
                                        const cefr_all = cefr_level.map(
                                            (cefr_value, cefr_idx) => {
                                                return (
                                                    <tr key={'cefr' + cefr_idx} className="h5">
                                                        {cefr_idx === 0 && (
                                                            <td
                                                                className={
                                                                    style.rootToken_level
                                                                }
                                                                colSpan={6}
                                                                rowSpan={row_span}
                                                            ></td>
                                                        )}
                                                        {cefr_idx === 0 && (
                                                            <th
                                                                className={
                                                                    style.rootToken_level
                                                                }
                                                                style={{
                                                                    textAlign: 'center',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                                colSpan={3}
                                                                rowSpan={row_span}
                                                            >{`Overall CEFR Levels Discovered`}</th>
                                                        )}

                                                        <td
                                                            className={style.rootToken_level}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {cefr_value}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        );
                                        if (cefr_level_average !== '') {
                                            const return_tag = (
                                                <tr key={j} className="h5">
                                                    <td className={style.rootToken_number}>
                                                        {v.index}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.text}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.lemma}
                                                    </td>
                                                    <td className={style.rootToken}>{v.pos}</td>
                                                    <td className={style.rootToken}>
                                                        {v.pos_tag}
                                                    </td>
                                                    <td className={style.rootToken}>{v.dep}</td>
                                                    <td className={style.rootToken_number}>
                                                        {v.governor}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.governorGloss}
                                                    </td>
                                                    <td className={style.rootToken_number}>
                                                        {display_level}
                                                    </td>
                                                    <td
                                                        className={style.rootToken}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {cefr_level_average}
                                                    </td>
                                                </tr>
                                            );
                                            arrays.push(return_tag);
                                            arrays.push(cefr_all);
                                            return arrays.map((return_v) => {
                                                return return_v;
                                            });
                                        } else {
                                            const return_tag = (
                                                <tr key={j} className="h5">
                                                    <td className={style.rootToken_number}>
                                                        {v.index}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.text}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.lemma}
                                                    </td>
                                                    <td className={style.rootToken}>{v.pos}</td>
                                                    <td className={style.rootToken}>
                                                        {v.pos_tag}
                                                    </td>
                                                    <td className={style.rootToken}>{v.dep}</td>
                                                    <td className={style.rootToken_number}>
                                                        {v.governor}
                                                    </td>
                                                    <td className={style.rootToken}>
                                                        {v.governorGloss}
                                                    </td>
                                                    <td className={style.rootToken_number}>
                                                        {display_level}
                                                    </td>
                                                    <td className={style.rootToken}>{''}</td>
                                                </tr>
                                            );
                                            arrays.push(return_tag);
                                            return arrays.map((return_v) => {
                                                return return_v;
                                            });
                                        }
                                    } else {
                                        if (cefr_level_average !== '') {
                                            const row_span = cefr_level.length;
                                            const cefr_all = cefr_level.map(
                                                (cefr_value, cefr_idx) => {
                                                    return (
                                                        <tr
                                                            key={'cefr' + cefr_idx}
                                                            className="h5"
                                                        >
                                                            {cefr_idx === 0 && (
                                                                <td
                                                                    className={
                                                                        style.rootToken_level
                                                                    }
                                                                    colSpan={6}
                                                                    rowSpan={row_span}
                                                                ></td>
                                                            )}
                                                            {cefr_idx === 0 && (
                                                                <th
                                                                    className={
                                                                        style.rootToken_level
                                                                    }
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle',
                                                                    }}
                                                                    colSpan={3}
                                                                    rowSpan={row_span}
                                                                >{`Overall CEFR Levels Discovered`}</th>
                                                            )}

                                                            <td
                                                                className={
                                                                    style.rootToken_level
                                                                }
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {cefr_value}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            );
                                            const return_tag = (
                                                <tr key={j} className="h5">
                                                    <td className={style.numberToken}>
                                                        {v.index}
                                                    </td>
                                                    <td>{v.text}</td>
                                                    <td>{v.lemma}</td>
                                                    <td>{v.pos}</td>
                                                    <td>{v.pos_tag}</td>
                                                    <td>{v.dep}</td>
                                                    <td className={style.numberToken}>
                                                        {v.governor}
                                                    </td>
                                                    <td>{v.governorGloss}</td>
                                                    <td className={style.numberToken}>
                                                        {display_level}
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {cefr_level_average}
                                                    </td>
                                                </tr>
                                            );
                                            arrays.push(return_tag);
                                            arrays.push(cefr_all);
                                            return arrays.map((return_v) => {
                                                return return_v;
                                            });
                                        } else {
                                            const return_tag = (
                                                <tr key={j} className="h5">
                                                    <td className={style.numberToken}>
                                                        {v.index}
                                                    </td>
                                                    <td>{v.text}</td>
                                                    <td>{v.lemma}</td>
                                                    <td>{v.pos}</td>
                                                    <td>{v.pos_tag}</td>
                                                    <td>{v.dep}</td>
                                                    <td className={style.numberToken}>
                                                        {v.governor}
                                                    </td>
                                                    <td>{v.governorGloss}</td>
                                                    <td className={style.numberToken}>
                                                        {display_level}
                                                    </td>
                                                    <td>{''}</td>
                                                </tr>
                                            );
                                            arrays.push(return_tag);
                                            return arrays.map((return_v) => {
                                                return return_v;
                                            });
                                        }
                                    }
                                })}
                                {cefr[`levelCount`] && (
                                    <tr>
                                        <th style={{ 'textAlign': 'center' }} colSpan={6}>{'CEFR Level'}</th>
                                        <th style={{ 'textAlign': 'center' }} colSpan={3}>{'Sentence Average'}</th>
                                        <th style={{ 'textAlign': 'center' }} colSpan={1}>{cefr['average_level']}</th>
                                    </tr>
                                )}
                                {cefr[`levelCount`] && (
                                    <tr>
                                        <td colSpan={2} className={style.rootToken_level}></td>
                                        <th style={{ textAlign: "center" }} colSpan={5}>
                                            {cefr_level_arr.map((level_v, i) => {
                                                return (
                                                    <span key={i}>{`[ ${level_v} : ${cefr[`levelCount`][level_v]} ] `}</span>
                                                )
                                            }
                                            )}
                                        </th>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>
                                )}
                                {cefr[`multiWordLevels`].length !== 0 && (
                                    <tr className='h5' style={{ textAlign: "center" }}>
                                        <th colSpan={2} className={style.rootToken_level}></th>
                                        <th colSpan={2}>{`index`}</th>
                                        <th colSpan={1}>{`Multi Word Text`}</th>
                                        <th colSpan={2}>{`CEFR Multi Word Level`}</th>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>
                                )}
                                {cefr[`multiWordLevels`].length !== 0 && cefr[`multiWordLevels`].map((v, i) => (

                                    <tr key={'multi' + i}>
                                        <th colSpan={2} className={style.rootToken_level}></th>
                                        <td colSpan={2} className={style.numberToken}>{`${i}`}</td>
                                        <td colSpan={1}>{v.word}</td>
                                        <td colSpan={2} className={style.numberToken}>{`${v.cefr_level}`}</td>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>
                                )
                                )}
                                {level_count && (
                                    <tr>
                                        <th style={{ 'textAlign': 'center' }} colSpan={10}>{'Core Level'}</th>
                                    </tr>
                                )}
                                {level_count && (
                                    <tr >
                                        <td colSpan={2} className={style.rootToken_level}></td>
                                        <th style={{ textAlign: "center" }} colSpan={5}>
                                            {level_arr.map((_, i) => {
                                                return (
                                                    <span key={i}>{`[Level${i + 1} : ${level_count[i + 1]}]`}</span>
                                                )
                                            }
                                            )}
                                        </th>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>
                                )}
                                {multi_word_levels.length !== 0 &&
                                    (<tr className='h5' style={{ textAlign: "center" }}>
                                        <th colSpan={2} className={style.rootToken_level}></th>
                                        <th colSpan={2}>{`index`}</th>
                                        <th colSpan={1}>{`Multi Word Text`}</th>
                                        <th colSpan={2}>{`Multi Word Level`}</th>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>)
                                }

                                {multi_word_levels.length !== 0 && multi_word_levels.map((v, i) => (

                                    <tr key={'multi' + i}>
                                        <th colSpan={2} className={style.rootToken_level}></th>
                                        <td colSpan={2} className={style.numberToken}>{`${i}`}</td>
                                        <td colSpan={1}>{v.word} </td>
                                        <td colSpan={2} className={style.numberToken}>{`${v.level}`}</td>
                                        <td colSpan={3} className={style.rootToken_level}></td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        );
                    })}
                </Table>
                {/* </Row>/ */}
            </div>
        );
    });
    return <div>{dumyDiv}</div>;
};
