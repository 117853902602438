export { default } from "./DoriDori";

export interface DispatchProps {
  updateStore: (messages: any[]) => void;
  dispatchLoading: () => void;
  safeComponentState: (state: any) => void;
}

export interface StateProps {
  loading?: boolean;
  componentState?: any;
}

export interface DoriDoriSkillProps {
  title: string;
  desc: JSX.Element;
  dialogApi: (
    history: string[][],
    context?: string,
    backend?: string
  ) => Promise<any>;
  preApi: () => Promise<any>;
  lang?: string;
  transcript: any;
  resetTranscript: any;
  setIsListening: any;
  isListening: any;
  recognitionPlaceholder?: string;
  placeholder?: string;
  dialogApiHint: (
    history: string[][],
    context?: string,
    backend?: string
  ) => Promise<any>;
}
