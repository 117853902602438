import React, { useState } from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'
import { Row } from 'react-bootstrap';
import { NerClass } from 'util/NerClass';

export const ShortAnswerResult = (props) => {
  const { responseData } = props;
  const text_array = responseData.text.split(/{Answer}/g);
  if (text_array.length < 2) {
    return (
      <div className={style.basic}>
        <p>{`A: ${responseData.answer}`}</p>
        <p>{`Q: ${responseData.question}`}</p>
        <p>{text_array[0]}</p>
      </div>
    )
  } else {
    return (
      <div className={style.basic}>
        <p>{`A: ${responseData.answer}`}</p>
        <p>{`Q: ${responseData.question}`}</p>
        <p>
          {text_array[0]}
          <NerClass key={0}
            color={'#0069b4'}
            label={responseData.answer}
            tip={'A'}
          />
          {text_array[1]}
        </p>
      </div>
    )
  }
}
