import React, { useState } from "react";
import { getCookie } from "../../util/Cookie";
import { CONFIG } from "../../config";
import { Col, Container, Image, Row } from "react-bootstrap";
import style from './Home.module.scss';
export default function Home() {
  const getEmailCookie = getCookie(CONFIG.COOKIE_LOGIN.KEY1);
  const getUserNameCookie = getCookie(CONFIG.COOKIE_LOGIN.KEY2);
  return (
    <Container className={style.ContainerClass}>
      <Row className={style.RowClass}>
          <Col className={style.homeImageLogos}/>
          <h1 className={style.homeH1Class}>Welcome to United Associates - Demo Site</h1>
          <p className={style.homePClass}><b>United Associates inc</b></p>
      </Row>
      <Row className={style.homeFooterTag}>
        <p>Copyright &copy; 2020 United Associates Inc. All rights reserved.</p>
      </Row>
    </Container>
    
  );
}
