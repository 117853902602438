import React from 'react';

export const StudyNoteProps = {
    title: 'Study Note',
    desc: <div><p className="h5"><b> Case Study Notes will create some key points what we should know when studying a certain subject.</b></p><p className="h5">Provide a topic and get study notes.</p></div>,
    inputs: [{
        title: 'Enter Study Note Title',
        type: 'text',
        name: 'question',
    }],
    examples: [{
        question: 'Human Evolution',
    }, {
        question: 'Computer Graphic Designer',
    }, {
        question: 'Foreign Language',
    }, {
        question: 'YouTube Creator',
    }, {
        question: 'Police Officer',
    }, {
        question: 'Making Furniture',
    }],
    renderAnswer: { type: 'studyNotes' },
}