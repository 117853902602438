export interface IExample {
    question: string;
    [key: string]: string;
}

const examples: IExample[] = [
    { question: 'Pack my box with five dozen liquor jugs.' },
    { question: 'The quick brown fox jumps over the lazy dog.' },
    {
        question: 'We promptly judged antique ivory buckles for the next prize.',
    },
    {
        question: 'A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.',
    },
    {
        question:
            'Mary walked her little dog past the hissing cat, whose back was arched menacingly.',
    },
];
const title: string = 'NLU Semantic Analysis';

const desc: JSX.Element = (
    <div>
        <p className="h5">
            <b>
                <span>&#8251;</span>
                {` NLP Dependency Grammar Check Tool `}
                <span>&#8251;</span>
            </b>{' '}
        </p>
    </div>
);

export const NLUSemanticAnalysisProps = {
    examples,
    title,
    desc
}

