// import { useCookies } from "react-cookie";

// const [cookies, setCookie, removeCookie] = useCookies([])

import { Cookies } from "react-cookie";

const cookies = new Cookies();
// 쿠키등록
// 1주일
let maxAge: number = 60 * 60 * 24 * 7;

export const setCookie = (name: string, value: string, option?:any ) => {
  return cookies.set(name, value,  {...option,
    path: '/',
    domain: '.ella.so',
    sameSite: 'none',
    secure: true,
    maxAge: maxAge
  });
};

export const getCookie = (name: string) => {
  const getCookiesName = cookies.get(name);
  return getCookiesName;
};

export const removeCookie = (name: string, option?: any) => {
  return cookies.remove(name, { ...option, 
    path: '/',
    domain: '.ella.so',
    sameSite: 'none',
    secure: true,
    maxAge: 0
  });
};

