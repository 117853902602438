import axios from 'axios';
import { CONFIG } from 'config';
import React from 'react';

const essay_outline_url =''
const poly_writing_center_url = CONFIG.POLY_WRITING_CENTER_URL

export async function apiOpen(question: any): Promise<any> {
    const promptParams = {
        "text": question.question
    };

    if (essay_outline_url !== '') {
        return await axios.post(essay_outline_url, promptParams, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            }
        });
    }
}

export async function polyWritingCenterApi(question: any): Promise<any>{
    const requestData = {
        "topic": question[1],
        "text": question[0]
    }
    if (poly_writing_center_url !== '') {
        return await axios.post(poly_writing_center_url, requestData, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
            }
        }).then(result => {
            return result.data.data;
        })
    }
}