import axios from 'axios';
import { CONFIG } from 'config';
import React from 'react';
import { getCookie, removeCookie } from './Cookie';


export async function ApiOpen(question: any, url: string, pageTitle?: string): Promise<any> {
    if (loginTimeCheckAPI !== undefined) {
        await loginTimeCheckAPI();
    }
    let promptParams: any;
    if (pageTitle === 'diaryAssistant' || pageTitle==='conversationEvaluator') {
        promptParams = question
    } else {
        promptParams = {
            text: question,
        };
    }
    if (url !== '') {
        return await axios.post(url, promptParams, {
            headers: {
                'Content-Type': 'application/json',
                "Accept": 'application/json',
            },
        });
    }
}

export async function loginTimeCheckAPI(): Promise<void> {
                
    let url = CONFIG.LOGIN_VALUE_CHECK;
    let cookieEmail = encodeURI(getCookie("UA_ELLA_EMAIL"));
    let cookieUserName = encodeURI(getCookie("UA_ELLA_USERNAME"));
    let cookieHd = encodeURI(getCookie("UA_ELLA_HD"));
    let addData = url + cookieEmail + "/:" + cookieUserName + "/:" + cookieHd;
    console.log('addData =',addData)
    return (
        await axios.get(
            addData, {
            headers: {
                    'content-Type': 'application/json',
                    'Accept':'application/json',
                }
            }
        ).then(res => {
            console.log('== loginTimeCheckAPI =',res)
            if (!res.data) {
                removeCookie("EMAIeL");
                removeCookie("USERNAME");
                removeCookie("HD");
                alert("로그인 시간 만료")
                window.location.reload();
            }
        })
    )
}