import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import style from '../../pages/Basic/SemanticAnalysis.module.scss'
import usePOLYDTStore from '../../store/usePOLYDTStore';
import { CONFIG } from 'config';
import { polyWritingCenterApi } from 'util/polyWritingCenterAPI';
import { resourceLimits } from 'worker_threads';
import * as boot from 'react-bootstrap';

export const POLYWritingCenterResult = (props) => {
    const { POLYDTStateStore, i } = props;

    const { allPOLYDTStateStore, setAllPOLYDTStateStore } = usePOLYDTStore()
    let text = POLYDTStateStore.askResult.text;
    let topic: string = POLYDTStateStore.askResult.topic;
    const flag = POLYDTStateStore.showResult === undefined ? false : true;
    const [responseFlag, setResponseFlag] = useState(false);
    const head_arr = ["I.", "II.", "III.", "IV.", "V.", "VI.", "VII.", "VIII.", "IX.", "X."]

    useEffect(() => {

        let roundLength = allPOLYDTStateStore.length - 1;
        // input close
        for (let roundI = 0; roundI < roundLength; roundI++) {
            let allInputLength = allPOLYDTStateStore[roundI].askResult.text.length - 1;
            for (let j = 0; j < allInputLength; j++) {
                const element = document.getElementById(`textarea${roundI}${j}`);
                if (element) {
                    element.style.display = "none";
                }
            }
        }
    }, [])

    const clickShowButtonEvent = async () => {
        setResponseFlag(true);
        let text = POLYDTStateStore.askResult.text;
        let topic = POLYDTStateStore.askResult.topic;

        let textStr = "";
        let maxLeng = text.length;
        for (const idx in text) {
            if (idx === maxLeng.toString()) {
                textStr += `${text[idx]}`
            } else {
                textStr += `${text[idx]}\n`
            }
        }
        let question: any = [topic, textStr];
        const response = await polyWritingCenterApi(question);
        // user랑 ai [[], []...] 형식으로 저장
        let dumyQuestion: any = (topic + '\n' + "" + '\n' + textStr);

        let userResult: any = dumyQuestion.split('\n');
        let aiResult: any = response.split('\n');
        let aiResultLength = aiResult.length;
        for (let i = 0; i < aiResultLength; i++) {
            aiResult.splice(((i * 2) + 1), 0, '');
        }

        let AllResult = allPOLYDTStateStore.splice(0).reverse();
        AllResult[i].showResult.aiText = aiResult;
        AllResult[i].showResult.userText = userResult;
        AllResult = AllResult.splice(0).reverse();
        setAllPOLYDTStateStore(AllResult);
        setResponseFlag(false);
    }

    const clickSave = () => {
        const viewPolySaveArrByUser = POLYDTStateStore.showResult.userText
        const viewPolySaveArrByAI = POLYDTStateStore.showResult.aiText
        return (
            <boot.Row>
                {/* User Result */}
                <boot.Col>
                    <boot.Row>
                        {viewPolySaveArrByUser.map((v, i) => {
                            let headCheck = false;
                            const headValue = v.split(' ');
                            const not_head_str = v.split('.');
                            const checkNumberTitle = not_head_str[0].match(/[0-9]/g);
                            head_arr.filter((v) => {
                                if (v === headValue[0]) {
                                    headCheck = true;
                                }
                            })
                            if (headCheck) {
                                return (
                                    <div key={i}>
                                        <p style={{ "fontSize": "1.4rem", "marginBottom": "0.7rem", "marginTop": "12px", "color": "#333", "border": "none" }}
                                        >{v}</p>
                                    </div>
                                )
                            } else {
                                if (checkNumberTitle) {
                                    return (
                                        <div key={i}>
                                            <p
                                                style={{ "paddingLeft": "2.6rem", "fontSize": "1rem", "marginBottom": "0.3rem", "color": "#333", "border": "none" }}
                                            >{v}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>
                                            <p
                                                style={{ "paddingLeft": "1.2rem", "fontSize": "1.2rem", "marginBottom": "0.5rem", "color": "#333", "border": "none" }}
                                            >{v}</p>
                                        </div>
                                    )
                                }
                            }


                        })}
                    </boot.Row>
                </boot.Col>
                {/* AI Result */}
                <boot.Col>
                    <boot.Row>
                        {viewPolySaveArrByAI.map((v, i) => {
                            let headCheck = false;
                            const headValue = v.split(' ');
                            const not_head_str = v.split('.');
                            const checkNumberTitle = not_head_str[0].match(/[0-9]/g);
                            head_arr.filter((v) => {
                                if (v === headValue[0]) {
                                    headCheck = true;
                                }
                            })
                            if (headCheck) {
                                return (
                                    <div key={i}>
                                        <p style={{ "fontSize": "1.4rem", "marginBottom": "0.7rem", "marginTop": "12px", "color": "#333", "border": "none" }}
                                        >{v}</p>
                                    </div>
                                )
                            } else {
                                if (checkNumberTitle) {
                                    return (
                                        <div key={i}>
                                            <p
                                                style={{ "paddingLeft": "2.6rem", "fontSize": "1rem", "marginBottom": "0.3rem", "color": "#333", "border": "none" }}
                                            >{v}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>
                                            <p
                                                style={{ "paddingLeft": "1.2rem", "fontSize": "1.2rem", "marginBottom": "0.5rem", "color": "#333", "border": "none" }}
                                            >{v}</p>
                                        </div>
                                    )
                                }
                            }


                        })}
                    </boot.Row>
                </boot.Col>

            </boot.Row>
        )
    }

    return (
        <div className={style.basic} key={i}>
            <p>{'Topic : '}{topic}</p>
            {text.map((v, idx) => {
                const test_str = v.split(' ');
                let check_head = false;
                const not_head_str = v.split('.');
                const checkNumberTitle = not_head_str[0].match(/[0-9]/g);
                // emptyFlagged 는 나머지가 1(홀수)일 경우 textarea
                const emptyFlagged = idx % 2 === 0 ? true : false;
                head_arr.filter((v) => {
                    if (test_str[0] === v) {
                        check_head = true;
                    }
                })
                if (emptyFlagged) {
                    // 텍스트 데이터
                    if (check_head) {
                        // 큰 제목
                        return (
                            <div key={idx}>
                                <p
                                    onClick={() => {
                                        const element: any = document.getElementById(`textarea${i}${idx + 1}`);
                                        if (element.style.display === "none") {
                                            element.style.display = "block"
                                        } else {
                                            element.style.display = "none"
                                        }
                                    }}
                                    style={{ "fontSize": "1.4rem", "marginBottom": "0.7rem", "marginTop": "12px", cursor: "pointer" }} >{v}</p>
                            </div>
                        )
                    } else {
                        if (checkNumberTitle) {
                            // 작은 소제목
                            return (
                                <div key={idx}>
                                    <p
                                        onClick={() => {
                                            const element: any = document.getElementById(`textarea${i}${idx + 1}`);
                                            if (element.style.display === "none") {
                                                element.style.display = "block"
                                            } else {
                                                element.style.display = "none"
                                            }
                                        }}
                                        style={{ cursor: "pointer", "paddingLeft": "2.6rem", "fontSize": "1rem", "marginBottom": "0.3rem" }} >{v}</p>
                                </div>
                            )
                        } else {
                            // 큰 소제목
                            return (
                                <div key={idx}>
                                    <p onClick={() => {
                                        const element: any = document.getElementById(`textarea${i}${idx + 1}`);
                                        if (element.style.display === "none") {
                                            element.style.display = "block"
                                        } else {
                                            element.style.display = "none"
                                        }
                                    }} style={{ "cursor": "pointer", "paddingLeft": "1.2rem", "fontSize": "1.2rem", "marginBottom": "0.5rem" }} key={i}>{v}</p>
                                </div>
                            )
                        }
                    }
                } else {
                    // 입력 데이터
                    return (
                        <div key={idx}>
                            <textarea
                                style={{ "display": "none" }}
                                id={`textarea${i}${idx}`}
                                rows={3}
                                onChange={(e) => {
                                    if (POLYDTStateStore.askResult.text.length > 1) {
                                        let data = allPOLYDTStateStore.splice(0).reverse();
                                        data[i].askResult.text[idx] = e.currentTarget.value;
                                        const setData = data.splice(0).reverse()
                                        setAllPOLYDTStateStore(setData);
                                    }
                                }}
                                value={v}
                            />
                        </div>
                    )
                }

            })}
            <br />
            <Button onClick={() => clickShowButtonEvent()}>show</Button>
            <div></div>
            <br />
            <Row >
                {flag && clickSave()}
            </Row>
            {responseFlag && <div className={style.modal}> <div className={style.ldsRing}><div /><div /><div /><div /></div></div>}
        </div >
    )
}