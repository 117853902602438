import React, { Component } from "react";
import ThreeComp from "./ThreeCompAni";
import corgi from "./corgi.svg";
import volume from "./volume.svg";

import style from "./DoriDori.module.scss";
import styled, { keyframes, css } from "styled-components";
import Recognition from "./lib/recognition";

import { DoriDoriSkillProps } from ".";
import defaultTheme from "./lib/theme";
import rgba from "./lib/common/rgba";
import * as boot from "react-bootstrap";
import { MicIcon, SubmitIcon } from "./lib/icons";
import { loginTimeCheckAPI } from "util/CallAPI";

type Props = DoriDoriSkillProps;

type Backend = "GPT3" | "DialogGPT";
type Persona = "Aila" | "Joy";

const fillFunc = (props: any) => {
  const { speaking, invalid, theme } = props;

  if (speaking) {
    return theme.headerBgColor;
  }
  return invalid ? "#E53935" : "#4a4a4a";
};

const pulse = (color: any) => keyframes`
  0% { box-shadow: 0 0 0 0 ${rgba(color, 0.4)}; }
  70% { box-shadow: 0 0 0 10px ${rgba(color, 0)}; }
  100% { box-shadow: 0 0 0 0 ${rgba(color, 0)}; }
`;

const SubmitButton: any = styled.button`
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  fill: ${fillFunc};
  opacity: ${(props: any) => (props.disabled && !props.invalid ? ".5" : "1")};
  outline: none;
  padding: 8px 8px 8px 8px;
  &:before {
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

SubmitButton.defaultProps = {
  theme: defaultTheme,
};

const invalidInput = keyframes`
  25% { transform: rotate(-1deg); }
  100% { transform: rotate(1deg); }
`;

const Footer = styled.div`
  position: relative;
  display: block;
  height: 60px;
  margin-bottom: -12px;
`;

const Input: any = styled.textarea`
  animation: ${(props: any) =>
    props.invalid
      ? css`
          ${invalidInput} .2s ease
        `
      : ""};
  border: 0;
  border-radius: 0;
  border-radius: 5px;
  /*
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: ${(props: any) => (props.invalid ? "0" : "1px solid #eee")}; 
  */
  border: ${(props: any) => (props.invalid ? "0" : "1px solid #eee")};
  box-shadow: ${(props: any) =>
    props.invalid ? "inset 0 0 2px #E53935" : "none"};
  box-sizing: border-box;
  color: ${(props: any) => (props.invalid ? "#E53935" : "")};
  font-size: 16px;
  opacity: ${(props: any) => (props.disabled && !props.invalid ? ".5" : "1")};
  outline: none;
  padding: ${(props: any) =>
    props.hasButton ? "8px 52px 8px 10px" : "16px 10px"};
  width: 100%;
  -webkit-appearance: none;

  &:disabled {
    background: #fff;
  }
  @media screen and (max-width: 1132px) {
    resize: none;
    height: 42px;
  }
  @media screen and (max-width: 568px) {
    border-bottom-left-radius: ${(props: any) =>
    props.floating ? "0" : "10px"};
    border-bottom-right-radius: ${(props: any) =>
    props.floating ? "0" : "10px"};
  }
`;

interface State {
  error: any;
  history: string[][];
  backend: Backend;
  // triggers: string[];
  inputValue: string;
  speaking: boolean;
  recognitionEnable: boolean;
  inputInvalid: boolean;
  disabled: boolean;
  persona: Persona;
  ai: string;
  child: string;
  ellaFlag: boolean;
  mode: string;
  modeList: string[];
  hint: string;
  submitFlag: boolean;
}

class DoriDori extends Component<Props, State> {
  input: any;
  setInputRef: any;
  recognition: Recognition;
  player: any;

  async diag(stc: string[]) {
    this.setState({
      submitFlag: true,
    })
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    const { dialogApi } = this.props;
    let { history, ai, child, hint } = this.state;

    if (stc.length > 0) {
      if (history[history.length - 1]) {
        if (history[history.length - 1][0] === hint) {
          history.splice(history.length - 1, 1);
        }
      }
      history.push(stc);
    } else {
      history = [];
    }

    const rsp = await dialogApi(history, ai, child);
    let test: string = history[history.length - 1][1];
    test = test.replace(" ", "");
    if (test === "") {
      history.pop();
    }
    let rsp_text = rsp.data.text;
    rsp_text.filter((v: any) => {
      history.push(v);
    });
    this.setState({ history: history }, () => { });

    this.say(rsp.data.voice_text);
    this.setState({
      submitFlag: false,
    })
  }

  async prestart() {
    let { history, ai, child } = this.state;
    history = [];
    let test: string = history[history.length - 1][1];
    test = test.replace(" ", "");
    if (test === "") {
      history.pop();
    }
    let rsp_text = [ai, `Welcome ${child}!`];
    history.push(rsp_text);
    this.setState({ history: history }, () => { });

    await this.say(rsp_text[1]);
  }

  async say(txt: string) {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    if (this.player) {
      const { player } = this.player.getState();
      if (player.ended) {
        this.player.play();
      }
    }
    try {
      const { mode, modeList } = this.state;
      if (mode !== modeList[1]) {
        const voiceUrl = `https://demo.ella.school:22111/aws-polly/ssml/${encodeURIComponent(
          txt
        )}`;
        const tts = document.getElementById("tts");
        if (tts) {
          tts.setAttribute("autoplay", "true");
          tts.setAttribute("src", voiceUrl);
        }
      } else {
        const voiceUrl = `https://demo.ella.school:22111/aws-polly/ssml_steve/${encodeURIComponent(
          txt
        )}`;
        const tts = document.getElementById("tts");
        if (tts) {
          tts.setAttribute("autoplay", "true");
          tts.setAttribute("src", voiceUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async sayDingdong(txt: string) {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    if (this.player) {
      const { player } = this.player.getState();
      if (player.ended) {
        this.player.play();
      }
    }
    try {
      const voiceUrl =
        `https://demo.ella.school:22111/aws-polly/` + encodeURIComponent(txt);
      const tts = document.getElementById("tts");
      if (tts) {
        tts.setAttribute("autoplay", "true");
        tts.setAttribute("src", voiceUrl);
      }
    } catch (error) {
      console.log(error);
    }
  }

  onRecognitionChange = (value: any) => {
    this.setState({ inputValue: value });
  };

  onRecognitionEnd = () => {
    this.setState({ speaking: false });
    this.handleSubmitButton();
  };

  onRecognitionStop = () => {
    this.setState({ speaking: false });
  };

  constructor(props: Props) {
    super(props);
    const { lang } = props;
    this.recognition = new Recognition(
      this.onRecognitionChange,
      this.onRecognitionEnd,
      this.onRecognitionStop,
      lang
    );
    this.player = null;
    this.state = {
      error: "",
      ai: "",
      child: "",
      inputValue: "",
      speaking: false,
      history: [],
      backend: "GPT3",
      recognitionEnable: Recognition.isSupported(),
      inputInvalid: false,
      disabled: false,
      persona: "Aila",
      mode: "Ella",
      modeList: ["Ella", "Steve Jobs"],
      ellaFlag: false,
      hint: "{DINGDONG}",
      submitFlag: false,
    };
    this.input = null;
    this.setInputRef = (element: any) => {
      this.input = element;
    };
  }

  _handleKeyDown() {
    if (this.input) {
      this.input.focus();
    }
  }

  componentWillUnmount() {
    window.location.reload();
    document.removeEventListener("keydown", this._handleKeyDown.bind(this));

  }

  async componentDidMount() {
    this.setState({
      ai: "Ella",
      child: "Yuna",
    });
    document.addEventListener("keydown", this._handleKeyDown.bind(this));
  }

  isRTL = (s: string) => {
    const ltrChars =
      "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
      "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF";
    const rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC";
    const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);

    return rtlDirCheck.test(s) ? "rtl" : "ltr";
  };

  handleButton = () => {
    if (this.state.submitFlag === false) {
      this.diag([]);
    }
  };

  onErrorClose = () => this.setState({ error: false });

  backendChange = (backend: Backend) => () => {
    if (this.state.backend === backend) return;
    this.setState({ backend });
  };

  personaChange = (persona: Persona) => () => {
    if (this.state.persona === persona) return;
    this.setState({ persona });
  };

  isInputValueEmpty = () => {
    const { inputValue } = this.state;
    return !inputValue || inputValue.length === 0;
  };

  submitUserMessage = async () => {

    const { inputValue, child, submitFlag } = this.state;
    //영어,숫자,특문 외의 문자 입력 막기
    if (submitFlag === false) {

      await this.diag([child, inputValue]);
      this.setState(
        {
          disabled: true,
          inputValue: "",
        },
        () => {
          this.setState({
            inputInvalid: false,
            disabled: false,
          });
          if (this.input) {
            this.input.blur();
          }
        }
      );
    }
  };

  userHintMessage = async () => {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    const { dialogApiHint } = this.props;

    let { history, ai, child, hint } = this.state;

    if (history[history.length - 1]) {
      if (history[history.length - 1][0] === hint) {
        history.splice(history.length - 1, 1);
      }
    }
    const rsp = await dialogApiHint(history, ai, child);
    let test: string = "";
    if (history[history.length - 1] === undefined) {
      test = "";
    } else {
      test = history[history.length - 1][1];
    }
    test = test.replace(" ", "");
    if (test === "") {
      history.pop();
    }
    let rsp_text = rsp.data.text;
    let hint_message = "";
    rsp_text.filter((v) => {
      hint_message += v[1] + " ";
    });
    history.push([hint, hint_message]);
    this.setState({ history: history }, () => { });

    this.sayDingdong(rsp.data.voice_text);
  };

  setPersona = () => {
    const { mode, modeList } = this.state;

    return (
      <boot.DropdownButton
        as={boot.ButtonGroup}
        align="start"
        title={mode}
        id="bg-nested-dropdown"
      >
        {modeList &&
          modeList.map((v, i) => {
            return (
              <boot.Dropdown.Item
                key={`promptLists${i}`}
                onClick={() => {
                  if (v === "Ella") {
                    if (mode !== v) {
                      window.location.reload();
                    }
                  } else {
                    this.setState({
                      mode: v,
                      ai: v,
                      history: [],
                    });
                  }
                }}
              >
                {v}
              </boot.Dropdown.Item>
            );
          })}
      </boot.DropdownButton>
    );
  };

  handleSubmitButton = async () => {
    const { speaking, recognitionEnable } = this.state;
    if ((this.isInputValueEmpty() || speaking) && recognitionEnable) {
      this.recognition.speak();
      if (!speaking) {
        this.setState({ speaking: true });
      }
      return;
    }

    await this.submitUserMessage();
  };

  handleHintButton = () => {
    const { speaking, recognitionEnable } = this.state;

    if ((this.isInputValueEmpty() || speaking) && recognitionEnable) {
      this.recognition.speak();
      if (!speaking) {
        this.setState({ speaking: true });
      }
      return;
    }
    this.userHintMessage();
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      this.submitUserMessage();
      this.input.focus();
    }
  };

  onValueChange = (event: any) => {
    this.setState({ inputValue: event.target.value });
  };

  steveScreen = () => {
    return (
      <div
        style={{
          width: "36vw",
          height: "50vw",
          position: "relative",
          transition: "ease-out 0s",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
        className={style.steveScreen}
      ></div>
    );
  };

  render() {
    const { placeholder, recognitionPlaceholder } = this.props;

    const {
      inputValue,
      speaking,
      recognitionEnable,
      inputInvalid,
      disabled,
      ai,
      mode,
      child,
      hint,
    } = this.state;

    var { history } = this.state;

    const captionItems = history
      .slice(0)
      .reverse()
      .map((h, i) => {
        /* TODO: HARD CODED proper */
        const lineStyle =
          h[0] === ai
            ? style.doriLine
            : h[0] === child
              ? style.doriLineJulie
              : style.dingdongLine;
        const elStyle =
          h[0] === ai
            ? "doridori-caption"
            : h[0] === child
              ? "doridori-caption-julie"
              : "doridori-caption-dingdong";
        const dingdong_speaker =
          h[0] === hint ? (
            <span
              className={style.dingdongSpeaker}
              onClick={() => {
                this.sayDingdong(h[1]);
              }}
            >
              <img src={volume} />
            </span>
          ) : (
            ""
          );
        return (
          <div key={i} className={lineStyle}>
            <div className={elStyle} id="caption">
              {h[1]} {dingdong_speaker}
            </div>
            {h[0] === hint ? <img src={corgi} /> : ""}
          </div>
        );
      });

    const icon =
      (this.isInputValueEmpty() || speaking) && recognitionEnable ? (
        <MicIcon />
      ) : (
        <SubmitIcon />
      );

    const inputPlaceholder = speaking ? recognitionPlaceholder : placeholder;

    const controlStyle = {
      position: "absolute",
      right: 0,
      top: 0,
    } as React.CSSProperties;

    let agent;
    if (agent === undefined) {
      if (mode === "Ella") {
        agent = <ThreeComp />;
      } else {
        agent = (
          <div
            style={{
              width: "36vw",
              height: "50vw",
              margin: "auto",
            }}
            className={style.steveScreen}
          ></div>
        );
      }
    }

    return (
      <div>
        <div>
          <div className={style.doridoriMain}>
            {this.state.ellaFlag ? false : agent}
            <div className={style.doridoriCaptionContainer}>
              <div className="doridori-chats-container">{captionItems}</div>
              <Footer>
                <Input
                  id="inputerChat"
                  // type="textarea"
                  ref={this.setInputRef}
                  className="rsc-input"
                  placeholder={inputInvalid ? "" : inputPlaceholder}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.onValueChange}
                  invalid={inputInvalid}
                  hasButton={true}
                  value={inputValue}
                />
                <div style={controlStyle} className="rsc-controls">
                  <SubmitButton
                    className="rsc-submit-button"
                    style={{ fill: "#4a4a4a" }}
                    onClick={this.handleSubmitButton}
                    invalid={inputInvalid}
                    speaking={speaking}
                    disabled={disabled}
                  >
                    {icon}
                  </SubmitButton>
                </div>
              </Footer>
            </div>
          </div>
          <boot.ButtonGroup>
            {this.setPersona()}
            <boot.Button onClick={this.userHintMessage}>{"Hint"}</boot.Button>
          </boot.ButtonGroup>
        </div>
        <audio id="tts" />
      </div>
    );
  }
}

export default DoriDori;
