import React from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'

export const StudyNoteResult = (props) => {
    const { responseData } = props;
    let arr = responseData.text.split('\n');
    if (arr.length <= 1) {
        arr = responseData.text.split(/\d\.\s/);
        for (let i = 0; i < arr.length; i++) {
            arr[i] = (i + 1) + '. ' + arr[i];
        };
    }
    return (
        <div className={style.basic} >
            <p>{'Topic : '}{responseData.topic}</p>
            {arr.map((values, i) => (
                <p key={i}>{values}</p>
            ))}
        </div>
    );
}