import React, { useState } from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'
import DiaryImage from './Diary.jpg'

export const DiaryAssistantResult = (props) => {
    const { responseData, i } = props
    let arr = responseData.text.split(/\.\s/);
    let frr = responseData.text.split(/""/);


    return (
        <div
            className={style.basic}
            style={{
                "width": "auto",
                "backgroundImage": `url(${DiaryImage})`,
                "backgroundSize": 'cover',
                "backgroundRepeat": 'no-repeat',
                "backgroundPosition": 'center',
                "marginBottom": "0.4rem",
                "padding": "0.8rem 1.5rem 0.5rem 1.5rem"
            }}
            key={i}>
            <p style={{ "color": "darkblue", "borderBottom": "1px solid lightgray", "fontSize": "1.4rem" }}>{`${i}. Ella\'s Daily Life :`}</p>
            {frr.length > 1 &&
                <p key={i}>{`${i}.`}</p>
            }
            <p>
                {frr.length === 1 && arr.map((values, i) => {
                    if (i === 0) {
                        return (
                            <b key={i}>{values !== '' && values + '. '}<br /></b>
                        );
                    } else {
                        return (
                            <b key={i}>{values !== '' && values + '. '}</b>
                        );
                    }
                })}
            </p>
        </div>
    );

}