
const _exCNNText:string = ` Within a day of testing positive for covid-19 in June, Miranda Kelly was sick enough to be scared. At 44, with diabetes and high blood pressure, Kelly, a certified nursing assistant, was having trouble breathing, symptoms serious enough to send her to the emergency room.\n When her husband, Joe, 46, fell ill with the virus, too, she really got worried, especially about their five teenagers at home: "I thought, 'I hope to God we don't wind up on ventilators. We have children. Who's going to raise these kids?"\n But the Kellys, who live in Seattle, had agreed just after their diagnoses to join a clinical trial at the nearby Fred Hutch cancer research center that's part of an international effort to test an antiviral treatment that could halt covid early in its course.\n By the next day, the couple were taking four pills, twice a day. Though they weren't told whether they had received an active medication or placebo, within a week, they said, their symptoms were better. Within two weeks, they had recovered.\n "I don't know if we got the treatment, but I kind of feel like we did," Miranda Kelly said. "To have all these underlying conditions, I felt like the recovery was very quick."\n The Kellys have a role in developing what could be the world's next chance to thwart covid: a short-term regimen of daily pills that can fight the virus early after diagnosis and conceivably prevent symptoms from developing after exposure.\n "Oral antivirals have the potential to not only curtail the duration of one's covid-19 syndrome, but also have the potential to limit transmission to people in your household if you are sick," said Timothy Sheahan, a virologist at the University of North Carolina-Chapel Hill who has helped pioneer these therapies.`;
const _exLittlePrinceText:string = ` The narrator, an airplane pilot, crashes in the Sahara desert. The crash badly damages his airplane and leaves the narrator with very little food or water. As he is worrying over his predicament, he is approached by the little prince, a very serious little blond boy who asks the narrator to draw him a sheep. The narrator obliges, and the two become friends. The pilot learns that the little prince comes from a small planet that the little prince calls Asteroid 325 but that people on Earth call Asteroid B-612. The little prince took great care of this planet, preventing any bad seeds from growing and making sure it was never overrun by baobab trees. One day, a mysterious rose sprouted on the planet and the little prince fell in love with it. But when he caught the rose in a lie one day, he decided that he could not trust her anymore. He grew lonely and decided to leave. Despite a last-minute reconciliation with the rose, the prince set out to explore other planets and cure his loneliness.\n While journeying, the narrator tells us, the little prince passes by neighboring asteroids and encounters for the first time the strange, narrow-minded world of grown-ups. On the first six planets the little prince visits, he meets a king, a vain man, a drunkard, a businessman, a lamplighter, and a geographer, all of whom live alone and are overly consumed by their chosen occupations. Such strange behavior both amuses and perturbs the little prince. He does not understand their need to order people around, to be admired, and to own everything. With the exception of the lamplighter, whose dogged faithfulness he admires, the little prince does not think much of the adults he visits, and he does not learn anything useful. However, he learns from the geographer that flowers do not last forever, and he begins to miss the rose he has left behind.`;
const _exLittlePrinceText2:string = ` At the geographer’s suggestion, the little prince visits Earth, but he lands in the middle of the desert and cannot find any humans. Instead, he meets a snake who speaks in riddles and hints darkly that its lethal poison can send the little prince back to the heavens if he so wishes. The little prince ignores the offer and continues his explorations, stopping to talk to a three-petaled flower and to climb the tallest mountain he can find, where he confuses the echo of his voice for conversation. Eventually, the little prince finds a rose garden, which surprises and depresses him—his rose had told him that she was the only one of her kind.\n The prince befriends a fox, who teaches him that the important things in life are visible only to the heart, that his time away from the rose makes the rose more special to him, and that love makes a person responsible for the beings that one loves. The little prince realizes that, even though there are many roses, his love for his rose makes her unique and that he is therefore responsible for her. Despite this revelation, he still feels very lonely because he is so far away from his rose. The prince ends his story by describing his encounters with two men, a railway switchman and a salesclerk.`;
const _exLittlePrinceText3:string = ` It is now the narrator’s eighth day in the desert, and at the prince’s suggestion, they set off to find a well. The water feeds their hearts as much as their bodies, and the two share a moment of bliss as they agree that too many people do not see what is truly important in life. The little prince’s mind, however, is fixed on returning to his rose, and he begins making plans with the snake to head back to his planet. The narrator is able to fix his plane on the day before the one-year anniversary of the prince’s arrival on Earth, and he walks sadly with his friend out to the place the prince landed. The snake bites the prince, who falls noiselessly to the sand.\n The narrator takes comfort when he cannot find the prince’s body the next day and is confident that the prince has returned to his asteroid. The narrator is also comforted by the stars, in which he now hears the tinkling of his friend’s laughter. Often, however, he grows sad and wonders if the sheep he drew has eaten the prince’s rose. The narrator concludes by showing his readers a drawing of the desert landscape and by asking us to stop for a while under the stars if we are ever in the area and to let the narrator know immediately if the little prince has returned.`;
const _exTheBoyWhoCriedWolf:string = `The shepherd boy gets bored watching his sheep all aftermoon. All they do is munch, munch, and say "Baaaaa". The shephred boy tries to get the sheep to play games with him, but they aren't interested. Finally, he thinks of a plan to spice up life in the pasture. He cries that a wolf is after his sheep, and all of the townspeople come running. The shepherd boy spends the rest of the aftermoon playing with one of his frends. After the wild success of this trick. the shepherd boy cries the next day that there are two wolves after his sheep. Again, the townspeople come running. But when three wolves really do come, will the shepherd boy get the help he needs?`;
const _exTheFoxAndTheStork:string = ` The Fox one day thought of a plan to amuse himself at the expense of the Stork, at whose odd appearance he was always laughing.\n "You must come and dine with me today," he said to the Stork, smiling to himself at the trick he was going to play. The Stork gladly accepted the invitation and arrived in good time and with a very good appetite.\n For dinner the Fox served soup. But it was set out in a very shallow dish, and all the Stork could do was to wet the very tip of his bill. Not a drop of soup could he get. But the Fox lapped it up easily, and, to increase the disappointment of the Stork, made a great show of enjoyment.\n The hungry Stork was much displeased at the trick, but he was a calm, even-tempered fellow and saw no good in flying into a rage. Instead, not long afterward, he invited the Fox to dine with him in turn. The Fox arrived promptly at the time that had been set, and the Stork served a fish dinner that had a very appetizing smell. But it was served in a tall jar with a very narrow neck. The Stork could easily get at the food with his long bill, but all the Fox could do was to lick the outside of the jar, and sniff at the delicious odor. And when the Fox lost his temper, the Stork said calmly: \n    Do not play tricks on your neighbors unless you can stand the same treatment yourself.`;
export const LearningByQuestionProps = {
    title: 'Questioner (Multiple Choice)',
    desc: <div>
        <p className="h5"><b> Learning by Questions will create the questions using the article you enter.</b></p>
    <p className="h5">Copy and paste the text of the article you want to make questions.
    The longer article you enters, the more questions you can create.</p>
    </div>,
    inputs: [{
        title: 'Input Text',
        type: 'textarea',
        name: 'question',
    }],
    examples:[{
        question: _exTheBoyWhoCriedWolf,
        text: 'The Boy Who Cried Wolf (Summary)',
    }, {
        question: _exTheFoxAndTheStork,
        text: 'The Fox & The Stork (The Aesop for Children)'
    }, {
        question: _exLittlePrinceText,
        text: 'The Little Prince (Plot Summary part 1)',
    }, {
        question: _exLittlePrinceText2,
        text: 'The Little Prince (Plot Summary part 2)',
    }, {
        question: _exLittlePrinceText3,
        text: 'The Little Prince (Plot Summary part 3)',
    }, {
        question: _exCNNText,
        text: `CNN News (A pill to treat Covid-19: 'We're talking about a return to, maybe, normal life')`,
    }],
    renderAnswer: { type: 'learningByQuestions'},
};