import {create} from "zustand";

const useLoginStore = create<IUserInfo>((set) => ({
  email: "",
  userName: "",
  login: false,
  hd: "",
  // 로그인 정보 setState함수
  setUserInfo: (email: string, userName: string, login: boolean, hd?: string) => {
    set(() => ({ email: email, userName: userName, login: login, hd :hd })
    );
  },
}));

export default useLoginStore;
