import React from 'react';

function todaysDate() {
    let today = new Date();
    let todayStr = today.toString();
    //Thu, Nov.11, 2021
    //today.getFullYear() : 2021
    let year = today.getFullYear();

    //today.getDay() 요일(숫자)
    const WEEKDAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let day = WEEKDAY[today.getDay()];

    //today.getMonth()+1 : 월
    let month = todayStr.slice(4, 7);

    //today.getDate() : 일
    let date = today.getDate();

    let currentDay = day + ', ' + month + '.' + date.toString() + ', ' + year.toString();
    return currentDay;
}

export const DiaryAssistantProps = {
    title: 'Diary Assistant - Daily Life of A User (일기쓰기)',
    desc: <div><p className='h5'><b>Diary writing assistant.</b><br></br>(What is it today? ex: 2021-11-11 목요일 {'>'} Thu, Nov.11, 2021)</p></div>,
    inputs: [{
        title: `What is today's date?`,
        type: 'text',
        name: 'today',
    }, {
        title: 'Please descripbe the most memorable event happened today.',
        type: 'textarea',
        name: 'event',
    }, {
        title: 'How would you describe your feelings about what happened today?',
        type: 'text',
        name: 'feeling',
    }, {
        title: 'Based on the feelings you described above, what would you do in the future?',
        type: 'text',
        name: 'question',
    }],
    examples: [{
        text: 'default Example',
        today: todaysDate(),
        event: `i got up at 7. i wanted to sleep more, but i had to hurry because don't be late school.\ni want to school after breakfast at 7:30 by bus. bus is full of man. always annoyed.\nbecause i didn't homework, My English teacher hit my hand 2 times.\nhe asked me "why didn't homework?" i said, "i study at yesterday night"`,
        feeling: `I didn't feel good.`,
        question: `I should do my homework well next time.`,
    }],
    renderAnswer: { type: 'dailyLifeWritingForKids' },
}