import React from 'react';

const _exLittlePrinceText1_1: string = ` The narrator, an airplane pilot, crashes in the Sahara desert. The crash badly damages his airplane and leaves the narrator with very little food or water. As he is worrying over his predicament, he is approached by the little prince, a very serious little blond boy who asks the narrator to draw him a sheep. The narrator obliges, and the two become friends. The pilot learns that the little prince comes from a small planet that the little prince calls Asteroid 325 but that people on Earth call Asteroid B-612. The little prince took great care of this planet, preventing any bad seeds from growing and making sure it was never overrun by baobab trees.`;
const _exLittlePrinceText1_2: string = ` While journeying, the narrator tells us, the little prince passes by neighboring asteroids and encounters for the first time the strange, narrow-minded world of grown-ups. On the first six planets the little prince visits, he meets a king, a vain man, a drunkard, a businessman, a lamplighter, and a geographer, all of whom live alone and are overly consumed by their chosen occupations. Such strange behavior both amuses and perturbs the little prince. He does not understand their need to order people around, to be admired, and to own everything. With the exception of the lamplighter, whose dogged faithfulness he admires, the little prince does not think much of the adults he visits, and he does not learn anything useful. However, he learns from the geographer that flowers do not last forever, and he begins to miss the rose he has left behind.`;
const _exLittlePrinceText2_1: string = ` At the geographer’s suggestion, the little prince visits Earth, but he lands in the middle of the desert and cannot find any humans. Instead, he meets a snake who speaks in riddles and hints darkly that its lethal poison can send the little prince back to the heavens if he so wishes. The little prince ignores the offer and continues his explorations, stopping to talk to a three-petaled flower and to climb the tallest mountain he can find, where he confuses the echo of his voice for conversation. Eventually, the little prince finds a rose garden, which surprises and depresses him—his rose had told him that she was the only one of her kind.`;
const _exLittlePrinceText2_2: string = ` The prince befriends a fox, who teaches him that the important things in life are visible only to the heart, that his time away from the rose makes the rose more special to him, and that love makes a person responsible for the beings that one loves. The little prince realizes that, even though there are many roses, his love for his rose makes her unique and that he is therefore responsible for her. Despite this revelation, he still feels very lonely because he is so far away from his rose. The prince ends his story by describing his encounters with two men, a railway switchman and a salesclerk.`;
const _exTheFoxAndTheStork: string = ' The Fox one day thought of a plan to amuse himself at the expense of the Stork, at whose odd appearance he was always laughing.\n "You must come and dine with me today,"he said to the Stork, smiling to himself at the trick he was going to play. The Stork gladly accepted the invitation and arrived in good time and with a very good appetite.\n For dinner the Fox served soup. But it was set out in a very shallow dish, and all the Stork could do was to wet the very tip of his bill. Not a drop of soup could he get. But the Fox lapped it up easily, and, to increase the disappointment of the Stork, made a great show of enjoyment.\n The hungry Stork was much displeased at the trick, but he was a calm, even-tempered fellow and saw no good in flying into a rage. Instead, not long afterward, he invited the Fox to dine with him in turn. The Fox arrived promptly at the time that had been set, and the Stork served a fish dinner that had a very appetizing smell. But it was served in a tall jar with a very narrow neck. The Stork could easily get at the food with his long bill, but all the Fox could do was to lick the outside of the jar, and sniff at the delicious odor. And when the Fox lost his temper, the Stork said calmly: \n    Do not play tricks on your neighbors unless you can stand the same treatment yourself.';

export const SummaryForKids = {
    title: 'Summary for Kids (쉬운요약)',
    desc: <div><p className='h5'><b>Summary for Kids will translates difficult text into simpler concepts for kids.</b></p></div>,
    inputs: [{
        title: 'Input Text',
        type: 'textarea',
        name: 'question',
    }],
    examples: [{
        text: 'default Example',
        question: `Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass one-thousandth that of the Sun, but two-and-a-half times that of all the other planets in the Solar System combined. Jupiter is one of the brightest objects visible to the naked eye in the night sky, and has been known to ancient civilizations since before recorded history. It is named after the Roman god Jupiter.[19] When viewed from Earth, Jupiter can be bright enough for its reflected light to cast visible shadows,[20] and is on average the third-brightest natural object in the night sky after the Moon and Venus.`,
    }, {
        text: 'The Little Prince (Plot Summary part 1-1)',
        question: _exLittlePrinceText1_1,
    }, {
        text: 'The Little Prince (Plot Summary part 1-2)',
        question: _exLittlePrinceText1_2,
    }, {
        text: 'The Little Prince (Plot Summary part 2-1)',
        question: _exLittlePrinceText2_1,
    }, {
        text: 'The Little Prince (Plot Summary part 2-2)',
        question: _exLittlePrinceText2_2,
    }, {
        text: 'The Fox & The Stork (The AEsop for Children)',
        question: _exTheFoxAndTheStork,
    }],
    renderAnswer: { type: 'summaryForKids' },
}