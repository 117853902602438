import React, { useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap'
import CustomSubmitButton from './recognitionButton';
import RecognitionButton from './recognitionButton2';


interface IDdingdongHelpProps {
    open: boolean;
    setPopup: Function;
    setModalAgainResult: any;
    modalAgainValue: any;
    modalRecommandedValue: string;
    say: Function;
    modalValue: string;
    disabled: boolean;
    onModalChange: Function;
    submitUserMessage: Function;
    dumyHelpInputValue: any;
}

export function DdingdongHelp(props: IDdingdongHelpProps) {
    const {
        open,
        setPopup,
        setModalAgainResult,
        modalAgainValue,
        modalRecommandedValue,
        say,
        modalValue,
        disabled,
        onModalChange,
        submitUserMessage,
        dumyHelpInputValue,
    } = props;
    const handleClose = () => {
        setPopup({ open: false })
    }

    return (
        <>
            <Modal show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Dingdong's word </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div>
                            <h4>
                                Speak following the sentence recommended by Dingdong.
                            </h4>
                        </div>
                        <div>
                            <h4>
                                if you want to hear it again click on th button
                            </h4>
                        </div>
                        <div>
                            {setModalAgainResult && (
                                <p> {modalAgainValue} </p>
                            )}
                        </div>
                        <div>
                            <h5 style={{ color: "brown" }}>
                                {modalRecommandedValue}
                            </h5>
                        </div>
                        <div>{modalValue}</div>
                        <div>{dumyHelpInputValue}</div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => say(modalRecommandedValue)}>듣기버튼</Button>
                    {/* <Button>마이크버튼</Button> */}
                    <RecognitionButton
                        currentValue={modalValue}
                        disabled={disabled}
                        onChange={onModalChange}
                        submitUserMessage={submitUserMessage}
                    ></RecognitionButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}