import {create} from "zustand";
interface IPolyDtState {
    inputValueStore: string;
    allPOLYDTStateStore: IRoundAPIResult[];
    aiShowStateStore: any;
    // setInputValue
    setInputValueStore: (inputText: any) => void;
    // ask 후 나오는 데이터
    setAllPOLYDTStateStore: (inputText: any) => void;
    // user ai 둘 다 저장
    setAiShowStateStore: any;
    

}
interface IRoundAPIResult {
    askResult: IAskAPIResult;
    showResult: IShowAPIResult;
}
interface IAskAPIResult {
    topic: string;
    text: string[];
}
interface IShowAPIResult {
    userText: string[];
    aiText: string[];
}

const usePOLYDTStore = create<IPolyDtState>((set) => ({
    inputValueStore:"",
    allPOLYDTStateStore: [],
    aiShowStateStore:[],
    setAllPOLYDTStateStore: (inputData: IRoundAPIResult[]) => set((state) => ({
        allPOLYDTStateStore:inputData
    })),
    setInputValueStore: (inputText: string) => set((state) => ({
        inputValueStore:inputText
    })),
    setAiShowStateStore: (inputText: string) => set((state) => ({
        aiShowStateStore:inputText
    }))
}))

export default usePOLYDTStore;

// import { create } from ‘zustand’;
// interface IBasicState {
//     basic: number;
//     dark: boolean;
//     darkmodeSwitch: (setDark: boolean)=> void;
//     increase: (by: number) => void;
// }
// const useBasicStore = create<IBasicState>()((set)=>({
//     basic: 0,
//     dark: false,
//     darkmodeSwitch: (setDark)=>set((state)=>({
//         dark: setDark
//     })),
//     increase: (by) => set((state)=> ({
//         basic: state.basic + by
//     }))
// }))
// export default useBasicStore;