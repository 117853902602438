import React, { useRef, useState } from "react";
import styled from "styled-components";
import defaultTheme from "./lib/theme";
import style from "./SpeechAdvisor.module.scss";
import {
  Button,
  Col,
  Dropdown,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { checkChatInputValues } from "util/checkRegExEvent";
import Recognition from "./lib/recognition";
import {
  grammarlyCorretionAPI,
  grammarlySimilarSentenceAPI,
} from "util/GrammarlyAPI";
import { useEffect } from "react";
import { loginTimeCheckAPI } from "util/CallAPI";

function Popup({ open, setPopup, correctText, similarSentences, title }) {
  const handleClose = () => {
    setPopup({ open: false });
  };

  return (
    <>
      <Modal className={style.modal} show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div>
              <span className={style.correctionText}>Corrected Text</span>
            </div>
            <div>
              <span>{correctText}</span>
            </div>
            <div>
              <span className={style.correctionText}>Similar Sentence</span>
            </div>
            <div>
              <span>{similarSentences}</span>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const SubmitButton = styled.button<{ invalid: boolean; speaking: boolean }>`
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: 0 0 0 10px;
  outline: none;
  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &:focus {
    background-color: #ffffff;
    outline: none;
    box-shadow: none;
    border-color: #ffffff;
  }
  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

SubmitButton.defaultProps = {
  theme: defaultTheme,
};

const Input = styled.input`
  height: 60px;
  border: none;
  text-align :left;

  autofocus; 
  // font-size : 24px;

  width: 100%;
`;

export default function SpeechAdvisor() {
  // state
  const [title] = useState("Speech Advisor");
  const [inputInvalid, setInputInvalid] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [recognitionPlaceholder] = useState("Listentin ...");
  const [inputValue, setInputValue] = useState("");
  const [inputTextCount, setInputTextCount] = useState(0);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [wordHistory, setWordHistory] = useState<any>([{}]);
  const [dumyHistoryTitleList, setDumyHistoryTitleList] = useState<any>([]);
  const [correction, setCorrection] = useState("");
  const [grammarlyCorrectionAPIFlag, setGrammarlyCorrectionAPIFlag] =
    useState<any>();
  const [paperToMicFlag, setPaperToMicFlag] = useState(false);
  const [similarSentenceList, setSimilarSentenceList] = useState<JSX.Element>();
  const [mistakeFlag, setMistakeFlag] = useState(false);
  const [desc, setDesc] = useState(
    <div className={style.typing}>
      <p className="h2">
        <b>
          Say anything awesome. <br />
          Do you want to say it more awesomely?
        </b>
      </p>
    </div>
  );
  const [desc2] = useState(
    <div>
      <p className="h2">
        You’ve made mistakes. No worries. <br />I can show you how to say it
        correctly.
      </p>
    </div>
  );
  const [desc3] = useState(
    <div>
      <p className="h2">Fresh new expressionsare coming soon.</p>
    </div>
  );
  const [descResultArray, setDescResultArray] = useState([
    <div>
      <p className="h2">
        You’re the master of English. <br /> Keep saying!{" "}
      </p>
    </div>,
    <div>
      <p className="h2">Nice work!</p>
    </div>,
    <div>
      <p className="h2">
        No worries. <br />
        You said well.{" "}
      </p>
    </div>,
    <div>
      <p className="h2">Perfect. Piece of cake!</p>
    </div>,
    <div>
      <p className="h2">Great!</p>
    </div>,
    <div>
      <p className="h2">No errors at all. </p>
    </div>,
    <div>
      <p className="h2">Keep going! </p>
    </div>,
  ]);
  const [successSubTitle, setSuccessSubTitle] = useState<any>("");
  const [inputTextAddCss, setInputTextAddCss] = useState<any>();
  const [buttonFlag, setButtonFlag] = useState(false);

  let recognitionEnable = Recognition.isSupported();
  const [micImage] = useState<JSX.Element>(
    <div className={style.micImage}></div>
  );
  const [micImage1] = useState<JSX.Element>(
    <div className={style.mic1Image}></div>
  );
  const [paperImg] = useState<JSX.Element>(
    <div className={style.paperImg}></div>
  );
  const [reset] = useState<JSX.Element>(<div className={style.imgReset}></div>);

  const [popup, setPopup] = useState<any>({
    open: false,
    title: "",
    correctText: "",
    similarSentences: <div></div>,
  });

  const onRecognitionChange = (value) => {
    let dumyTextInput = value;
    let dumyTextInputLength = value.length;
    setInputValue(dumyTextInput);
    setInputTextCount(dumyTextInputLength);
  };
  const onRecognitionEnd = () => {
    setSpeaking(false);
    inputKeyHandler();
  };
  const onRecognitionStop = () => {
    setSpeaking(false);
  };

  let recognition = new Recognition(
    onRecognitionChange,
    onRecognitionEnd,
    onRecognitionStop,
    "en"
  );


  // Ref
  const setInputRef = useRef<HTMLInputElement>(null);

  const onValueChange = (e) => {
    const value = e.target.value;
    const valueLength = value.length;

    if (valueLength > 300) {
      const dumyValue = value.slice(0, 300);
      const dumyValueLength = dumyValue.length;
      setInputValue(dumyValue);
      setInputTextCount(dumyValueLength);
    } else {
      setInputValue(value);
      setInputTextCount(value.length);
    }
  };

  const switchSpinnerFlag = (Flag) => {
    setSpinnerFlag(!Flag);
    setDisabled(true);
  };

  const submitUserMessage = async () => {
    let checkingValue = checkChatInputValues(inputValue, "Eng");
    if (checkingValue) {
      await apiSubmitEvent();
    } else {
      alert(`Please enter English.`);
      setInputValue("");
    }
  };

  const say = (txt: string) => {
    try {
      const voiceUrl =
        `https://demo.ella.school:22111/aws-polly/` + encodeURIComponent(txt);
      const tts = document.getElementById("tts");
      if (tts) {
        tts.setAttribute("autoplay", "true");
        tts.setAttribute("src", voiceUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const speakerImageOnClick = async (text) => {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    say(text);
  };

  const correctAnswerCoparsionEvent = () => {
    setMistakeFlag(false);
    let inputValueDumyString = inputValue
      .toLowerCase()
      .replace(/[{}[]\/?.,;:|\)*~`!^-_+<>@#$%&\\=\('"]/g, "");
    let correctionTextDumyString = correction
      .toLowerCase()
      .replace(/[{}[]\/?.,;:|\)*~`!^-_+<>@#$%&\\=\('"]/g, "");
    let correctionText = correctionTextDumyString.split(" ");
    let inputValueText = inputValue.split(" ");
    let originText = inputValueDumyString.split(" ").map((v, i) => {
      // 성공 시 정답 중 하나 랜덤 나오게
      let successMathRandom = Math.floor(
        Math.random() * descResultArray.length
      );

      setSuccessSubTitle(descResultArray[successMathRandom]);
      if (v != correctionText[i]) {
        setMistakeFlag(true);
        return (
          <span key={i} className={style.inputTextAddCss}>
            {`${inputValueText[i]} `}
          </span>
        );
      } else {
        return (
          <span key={i} className={style.inputTextOriginStyle}>
            {`${inputValueText[i]} `}
          </span>
        );
      }
    });
    setInputTextAddCss(originText);
    setSpinnerFlag(false);
    setInputValue("");
    setInputTextCount(0);
    setDisabled(false);
    setButtonFlag(true);
  };

  const apiSubmitEvent = async () => {
    const dumyInputValue = inputValue.replace("\n", "");
    if (!spinnerFlag) {
      switchSpinnerFlag(spinnerFlag);
      const resultCorrectionReq = await grammarlyCorretionAPI(dumyInputValue);
      let resultCorrectionReplace = resultCorrectionReq.data.correct_text;
      let resultCorrectionFlag = resultCorrectionReq.data.correct_check_flag;
  
      let resultSimReq = await grammarlySimilarSentenceAPI(
        resultCorrectionReplace
      );
  
      let dumySimilarSentence = resultSimReq.data.similarSentences;
      let xmlTags = dumySimilarSentence.map((v, i) => {
        return (
          <div key={i} className={style.similarSentence}>
            <div>
              <p>{v}</p>
            </div>
            <div
              className={style.speakerImage}
              onClick={() => {
                speakerImageOnClick(v);
              }}
            ></div>
          </div>
        );
      });
  
      setWordHistory([
        {
          title: dumyInputValue,
          correctText: resultCorrectionReplace,
          similarSentences: xmlTags,
        },
      ]);
  
      setDumyHistoryTitleList((dumyHistoryTitleList) => [
        ...dumyHistoryTitleList,
        dumyInputValue,
      ]);
  
      if (resultCorrectionReq !== undefined) {
        if (resultCorrectionFlag == true) {
          setCorrection(dumyInputValue);
          setGrammarlyCorrectionAPIFlag(true);
        } else {
          setCorrection(resultCorrectionReplace);
          setGrammarlyCorrectionAPIFlag(false);
        }
        setCorrection(resultCorrectionReplace);
        setPaperToMicFlag(true);
        setSimilarSentenceList(xmlTags);
      }
      correctAnswerCoparsionEvent();
    }
  };

  const inputKeyHandler = async () => {
    if (inputValue === "") {
      alert("값을 입력해주세요");
    } else {
      await submitUserMessage();
      if (setInputRef.current) {
        setInputRef.current.focus();
      }
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (inputValue === "") {
        alert("값을 입력해주세요");
      } else {
        await inputKeyHandler();
        if (setInputRef.current) {
          setInputRef.current.focus();
        }
      }
    }
  };

  const inputPlaceholder = speaking
    ? recognitionPlaceholder
    : `Click the mic and speak.\nOr paste here`;

  const _handleKeyDown = () => {
    if (setInputRef) {
      setInputRef.current?.focus();
    }
  };

  const isInputValueEmpty = () => {
    return !inputValue || inputValue.length === 0;
  };

  const handleSubmitButton = async () => {
    if (loginTimeCheckAPI !== undefined) {
      await loginTimeCheckAPI();
    }
    if (isInputValueEmpty() || (speaking && recognitionEnable)) {
      recognition.speak();
      if (!speaking) {
        setSpeaking(true);
      }
      return;
    }
    await submitUserMessage();
  };

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);
    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
    };
  }, []);
  useEffect(() => {
    if (disabled) {
      setInputInvalid(false);
      setDisabled(false);
      if (setInputRef) {
        setInputRef.current?.blur();
      }
    }
  }, [disabled]);

  const icon =
    (isInputValueEmpty() || speaking) && recognitionEnable
      ? speaking
        ? micImage1
        : micImage
      : paperImg;

  const showHistoryContent = (idx) => {
    wordHistory.map((v, i) => {
      if (idx === i) {
        setPopup({
          open: true,
          title: v.title,
          correctText: v.correctText,
          similarSentences: v.similarSentences,
        });
      }
    });
  };

  const setPopupButton = () => {
    setPopup({ open: false });
  };

  const historyRemove = (idx) => {
    let historyTitleList = dumyHistoryTitleList.filter((v, i) => {
      if (i !== idx) {
        return v;
      }
    });

    setDumyHistoryTitleList(historyTitleList);
  };
  return (
    <div className={style.container}>
      <Row className={style.titleContainer}>
        <div className={style.title}>{title}</div>
        {!buttonFlag ? (!spinnerFlag ? desc : false) : false}
        <div>
          <span className={style.subTitleColor}>
            {buttonFlag
              ? !spinnerFlag
                ? !mistakeFlag
                  ? successSubTitle
                  : desc2
                : desc3
              : false}
          </span>
        </div>
      </Row>
      <hr />
      <Row>
        <Col className={style.inputColumn} offset={1} md={8} lg={8}>
          <Row className={style.iconDiv}>
            <Col sm={1}>
              {!disabled && (
                <SubmitButton
                  className="rsc-submit-button"
                  onClick={handleSubmitButton}
                  invalid={inputInvalid}
                  speaking={speaking}
                  disabled={disabled}
                >
                  {icon}
                </SubmitButton>
              )}
              {disabled && <div>{micImage}</div>}
            </Col>
            <Col style={{ marginLeft: "20px" }} sm={1}></Col>
          </Row>
          <Row>
            <div style={{ display: "flex" }}>
              <Input
                id="inputerChat"
                type="textarea"
                ref={setInputRef}
                className="rsc-input"
                placeholder={inputInvalid ? "" : inputPlaceholder}
                onKeyPress={handleKeyPress}
                onChange={onValueChange}
                value={inputValue}
              />
              {inputValue.length == 0 ? (
                false
              ) : (
                <Button
                  style={{ height: "60px" }}
                  variant="outline-secondary"
                  onClick={() => {
                    setInputValue("");
                    setInputTextCount(0);
                  }}
                >
                  X
                </Button>
              )}
            </div>
            <div>{inputTextCount}/300</div>
          </Row>
          <hr />
          {spinnerFlag && <Spinner animation={"border"} />}
          {inputTextAddCss && !spinnerFlag && (
            <div style={{ marginLeft: "10px" }}>
              <Row>
                <Row className={style.correctionAddCssArea}>
                  <Row className={style.resultLabel}>
                    {"Original Sentence "}
                  </Row>
                  <Row>
                    <Col>
                      <div
                        style={{
                          height: "auto",
                          minHeight: "50px",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        {inputTextAddCss}
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Row>
                <div className={style.correctionAddCssArea}>
                  <Row className={style.resultLabel}>{"Revised Sentence "}</Row>
                  <Row>
                    {grammarlyCorrectionAPIFlag ? (
                      <Col
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#b0e4d3",

                            display: "flex",
                            minHeight: "50px",
                            height: "auto",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          {correction}
                          <div
                            className={style.speakerImage}
                            onClick={() => speakerImageOnClick(correction)}
                          ></div>
                        </div>
                      </Col>
                    ) : (
                      <Col
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "pink",

                            display: "flex",
                            minHeight: "50px",
                            height: "auto",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          {correction}
                          <div
                            className={style.speakerImage}
                            onClick={() => speakerImageOnClick(correction)}
                          ></div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Row>
              <Row>
                <div className={style.correctionAddCssArea}>
                  <Row className={style.resultLabel}>{"Similar Sentence"}</Row>
                  <Row>
                    <div className={style.similarSentenceList}>
                      {similarSentenceList}
                    </div>
                  </Row>
                </div>
              </Row>
            </div>
          )}
        </Col>
        <Col className={style.correction} md={4} lg={4}>
          <Row>
            <span className={style.correctionText}>Conversational Tone</span>
          </Row>

          <Row className={style.historyText}>
            <Col>
              <span>History</span>
            </Col>
            <Col style={{ marginRight: "50px" }} sm={1}>
              <div
                onClick={() => {
                  if (
                    window.confirm(
                      "새로고침을 하시겠습니까?? \n ※ History가 초기화 됩니다. ※"
                    )
                  ) {
                    window.location.reload();
                  } else {
                    return false;
                  }
                }}
              >
                {reset}
              </div>
            </Col>
          </Row>
          <Row>
            <ListGroup>
              {dumyHistoryTitleList.map((v, i) => {
                return (
                  <div key={i}>
                    <ListGroup.Item className={style.showHistoryContent}>
                      <div className={style.history}>
                        <span onClick={() => showHistoryContent(i)}>{v}</span>
                        <Button
                          id={`remove` + i}
                          type="button"
                          className="btn btn-light margin-15px-left"
                          onClick={() => historyRemove(i)}
                        >
                          X
                        </Button>
                      </div>
                    </ListGroup.Item>
                  </div>
                );
              })}
            </ListGroup>
            {/* 히스토리 클릭시 모달창 띄우기 */}
            <Popup
              open={popup.open}
              setPopup={setPopupButton}
              correctText={popup.correctText}
              similarSentences={popup.similarSentences}
              title={popup.title}
            ></Popup>
          </Row>
        </Col>
      </Row>
      <audio id="tts" />
    </div>
  );
}
