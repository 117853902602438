// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./Signin.module.scss";
import GoogleLoginButton from "../../components/googleLoginButton/GoogleLoginButton";
// import loginStore from "../store/loginStore";

// 디자인 페이지
export default function Signin() {
  // const bears = loginStore((state: any) => state.bears);
  // const increase = loginStore((state: any) => state.increasePopulation);
  return (
    <Container className="main-container">
      <Row>
        <Col lg={7}>
          <div className={style.ellaImage}></div>
        </Col>
        <Col md={5}>
          {/* 베어 : {bears}
          <button onClick={increase}>증가</button> */}
          <h1>Signin</h1>
          <GoogleLoginButton />
          {/* <Button>test</Button> */}
        </Col>
      </Row>
    </Container>
  );
}
