import React, { useEffect, useState } from 'react';
import Style from '../../../pages/Basic/Open.module.scss';
import styles from '../../../pages/Basic/SemanticAnalysis.module.scss';
import cn from 'classnames';

export const CreateQuestion = (props: {
    responseData: any,
    parent_i?: number,
    setLearningByQuestion: Function
}) => {
    const { responseData, setLearningByQuestion, parent_i } = props;
    const qItem = responseData;

    const clickValue = (qIndex: number, qSelect: number) => {
        // 현재 회차 데이터, i=회차정보
        let dump_responseData = responseData;
        dump_responseData[qIndex].selectChoice = qSelect;
        setLearningByQuestion(dump_responseData);
    }
    const onKeyHandle = (e: { target: { id: any; }; }) => {
        document.getElementById(e.target.id)?.click();
    };

    return (
        <>
            {qItem.map((item, i) => (
                <div className="MultiTypeAnswerBox" key={i}>
                    {i !== 0 && <hr style={{ height: '3px', marginTop: '40px' }} />}
                    <div>{'Q' + (i + 1) + ' :'}{item.question}</div>
                    <hr />
                    <MultiTypeAnswerBox idxNum={i}
                        choices={item.choices} id={parent_i + 'Q' + (i + 1)}
                        onKeyPress={(e: any) => { onKeyHandle(e) }}
                        hint={item.hints} answer={item.answer}
                        clickSelectEvent={clickValue}
                        checkedValue={item.selectChoice}
                    />
                </div>
            ))}
        </>
    );
};

export const MultiTypeAnswerBox = (choicesParams: any) => {
    const choices = choicesParams.choices;
    let answer = choicesParams.answer;
    const [isLoading, setLoading] = useState(false);
    const [isCorrect, setCorrect] = useState(false);
    const [isBtnCheck, setBtnCheck] = useState(false);
    const [inputRadioValue, setInputRadioValue] = useState("");

    useEffect(() => {
        if (isCorrect) {
            setCorrect(false)
        }
        if (isBtnCheck) {
            setBtnCheck(false)
        }
        return () => {
            if (isCorrect) {
                setCorrect(false)
            }
            if (isBtnCheck) {
                setBtnCheck(false)
            }
        }
    }, [])
    const answerChecker = (e: any) => {
        if (isBtnCheck) setBtnCheck(false);
        if (isLoading) setLoading(false);
        const _chkAnswer = answer;
        // == 2개로 써야만함 3개 사용시 작동 안됨
        const selectNumber = parseInt(e.target.value);
        const _checkAnswer = _chkAnswer === selectNumber ? true : false;
        const _idxNum = parseInt(choicesParams.idxNum);
        if (_checkAnswer) {
            //정답일 경우
            choicesParams.clickSelectEvent(_idxNum, selectNumber);
            return setCorrect(true);
        } else {
            //오답일 경우
            choicesParams.clickSelectEvent(_idxNum, selectNumber);
            return setCorrect(false);
        };
    };

    const onKeyPressChild = (e: any) => {
        if (e.key === "Enter") {
            choicesParams.onKeyPress(e);
        }
    };

    const handleClick = () => {
        if (isBtnCheck) {
            setBtnCheck(false);
            setLoading(true);
        } else {
            if (isLoading) {
                return setLoading(false);
            } else {
                return setLoading(true);
            };
        };
    };

    return (
        <div style={{ "marginTop": "10px" }} className={styles.basic}>
            {choices && choices.map((item, i) => (
                <div key={i}>
                    <label>
                        <input
                            defaultChecked={false}
                            type="radio" className={cn(Style.active)}
                            name={choicesParams.id}
                            id={choicesParams.id + 'radio' + (i + 1)}
                            value={(i + 1)}
                            onKeyPress={onKeyPressChild}
                            onClick={answerChecker}
                        /><span id={choicesParams.id + 'radio' + (i + 1) + 'Span'}>{(i + 1) + '. ' + item}</span>
                    </label>
                </div>
            ))}
            <div>
                <br />
                <button
                    className={Style.button} style={{ marginRight: '5px' }}
                    onClick={!isLoading ? handleClick : handleClick}>
                    {!isLoading ? 'Hint▽' : 'Hint△'}
                </button>
                <button
                    id="enterBtn" className={Style.button}
                    onClick={() => {
                        if (isBtnCheck) {
                            setBtnCheck(false);
                        } else {
                            if (isLoading) {
                                setLoading(false);
                            };
                            setBtnCheck(true);
                        };
                    }
                    }>Enter</button>
            </div>
            <div hidden={!isLoading} style={{ backgroundColor: '#F2F3F4' }}>
                <p>{!isLoading ? '' : 'Hint :'}</p>
                <p>{choicesParams.hint}</p>
            </div>
            <div hidden={!isBtnCheck} style={{ backgroundColor: isCorrect ? '#D5F5E3' : '#FDEDEC' }}>
                <p style={{ backgroundColor: isCorrect ? '#AAFFA9' : '#FFA7A7' }}>{!isCorrect ? ' Incorrect...' : ' Correct!'}</p>
                <p>{choicesParams.hint}</p>
            </div>
        </div>
    )
}