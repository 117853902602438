import axios from "axios";
import { CHATBOT_API_URL } from "../config";

export async function dialogApiHint(
  history?: string[][],
  ai_name?: string,
  user_name?: string
): Promise<any> {
  let hist: string[][] = [];

  history?.filter((v) => {
    if (v[0] === ai_name) {
      hist.push([`{AINAME}`, `${v[1]}`]);
    } else {
      hist.push([`{CHILDNAME}`, `${v[1]}`]);
    }
  });
  if (hist.length === 0) {
    hist.push([""]);
  }
  const promptParams = {};
  promptParams["ai_name"] = ai_name;
  promptParams["user_name"] = user_name;
  promptParams["history"] = hist;
  const reqUrl = `https://demo.ella.school:22111/nest/nlp/chatHint`;
  axios.defaults.withCredentials = true;
  return await axios.post(reqUrl, promptParams, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
}

export async function dialogApi(
  history?: string[][],
  ai_name?: string,
  user_name?: string
): Promise<any> {
  let hist: string[][] = [];
  history?.filter((v) => {
    if (v[0] === ai_name) {
      hist.push(["{AINAME}", `${v[1]}`]);
    } else {
      hist.push(["{CHILDNAME}", `${v[1]}`]);
    }
  });
  const promptParams: any = {};
  promptParams["ai_name"] = ai_name;
  promptParams["user_name"] = user_name;
  promptParams["history"] = hist;

  const reqUrl = `${CHATBOT_API_URL}`;
  axios.defaults.withCredentials = true;
  return await axios.post(reqUrl, promptParams, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
}
export async function preApi(): Promise<any> {
  const reqUrl = "/chitchat/pre";
  return await axios.get(reqUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
