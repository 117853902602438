import React from 'react';

export const EssayOutlinerProps = {
    title: 'Essay Outliner',
    desc: <div><p className='h5'><b>Essay Outline will generate an outline for a research topic.</b> </p></div>,
    inputs: [{
        title: 'Enter Essay Title',
        type: 'text',
        name: 'question'
    }],
    examples: [{
        question: 'Where does the popularity of Starbucks coffee come from?',
    }, {
        question: 'The future of Metaverse using the AI ​​market.',
    }, {
        question: 'How to write essays well.',
    }, {
        question: 'Is it right on a pan-human level to replace people with artificial intelligence.',
    }, {
        question: 'An application case using artificial intelligence.',
    }],
    renderAnswer: { type: 'essayOutliner' },
}