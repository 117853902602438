import React from 'react';
import style from '../../../pages/Basic/SemanticAnalysis.module.scss'

export const SummaryForKidsResult = (props) => {
    const { responseData, i } = props;
    let arr = responseData.split(/\.\s/);
    let frr = responseData.split(/""/);


    return (
        <div className={style.basic} key={i}>
            <p>{(i) + '. Ella\'s Summary :'}</p>
            {frr.length > 1 &&
                <p key={i}>{frr[0]}</p>
            }
            {frr.length === 1 && arr.map((values, i) => (
                <p key={i}>
                    {(values !== '' && i !== (arr.length - 1)) && values + '.'}
                    {(values !== '' && i == (arr.length - 1)) && values}
                </p>
            ))}
        </div>
    );
}