import React from 'react';

function todaysDate() {
    let today = new Date();
    let todayStr = today.toString();
    //Thu, Nov.11, 2021
    //today.getFullYear() : 2021
    let year = today.getFullYear();
    
    //today.getDay() 요일(숫자)
    const WEEKDAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let day = WEEKDAY[today.getDay()];
    
    //today.getMonth()+1 : 월
    let month = todayStr.slice(4,7);
    
    //today.getDate() : 일
    let date = today.getDate();

    let currentDay = day + ', ' + month + '.' + date.toString() + ', ' + year.toString();
    return currentDay;
}

export const ConversationEvaluatorProps = {
    title: 'Conversation Evaluator',
    desc: <div><p className='h5'><b>Evaluation assistant.</b><br></br>Today's date is {todaysDate()}.</p></div>,
    inputs: [{
        title: `Who is the target of evaluation?`,
        placeHorder: 'Who is the tartget of evaluation?',
        type: 'text',
        name: 'target',
    }, {
        title: 'Based on what criteria would you evaluate it?',
        type: 'button',
        name: 'based',
        defaultValues: ['CEFR','WIDA']
    }, {
        title: 'Give me a conversation.',
        placeHorder: 'Give me a conversation.',
        type: 'textarea',
        name: 'question',
    }],
    buttons: [
        'Evaluate',
        'Restart',
        ['Hide Settings△','Extend Settings▽']
    ],
    examples: [{
        text: 'default Example',
        target: `Jack`,
        based: `CEFR`,
        question: `Jack: Ma’am, can I talk to you for a minute?
Teacher: What is it, my boy?
Jack: l am very weak in English and that’s why my classmates laugh at me. I don’t know how improve my English.
Teacher: Well, you mustn’t feel bad about this. Just keep in mind that learning English is essential nowadays as it is the key to success.
Jack: Ma’am, kindly give me some suggestions on how I can improve.
Teacher: Well, start with reading English newspapers. The Telegraph would be an easy one for you.
Jack: Will it be enough?
Teacher: Not really. You must watch English news and English movies too. Try to communicate in English with your friends. That will help you the most.
Jack: Thank you, ma’am.
Teacher: Remember, learning English properly, will help you to expand your knowledge. It will help you in higher studies too and most importantly, it will help you to score more in the job interviews.
Jack: Thanks a lot, ma’am. I really didn’t know all these.
Teacher: Welcome, my boy! Now you go and attend your classes.
Jack: From today, I’ll try to learn English accurately.
Teacher: Very good. Don’t hesitate to come to me if you ever face any problem.
Jack: No, ma’am. I won’t.
Teacher: That’s like a good boy!`,
    }],
    renderAnswer: { type: 'conversationEvaluator' },
}