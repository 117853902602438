import React from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import Stats from "three/examples/jsm/libs/stats.module";
import { GUI } from "dat.gui";
import style from "./DoriDori.module.scss";

interface ThreeCompProps {}

interface ThreeCompState {
  loading: boolean;
  trigger: boolean;
  result: string;
  [key: string]: any;
}

class ThreeComp extends React.Component<ThreeCompProps, ThreeCompState> {
  mount: any;
  width: any;
  height: any;
  light: any;
  camera: any;
  scene: any;
  renderer: any;
  controls: any;
  mixer: THREE.AnimationMixer;
  modelReady: boolean;
  animationActions: THREE.AnimationAction[];
  activeAction: THREE.AnimationAction;
  lastAction: THREE.AnimationAction;
  fbxLoader: FBXLoader;
  gui: any;
  clock: any;
  animationsFolder: any;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      result: "",
      trigger: false,
    };
    this.modelReady = false;
    this.animationActions = [];
  }
  async componentWillUnmount() {
    await this.controls.dispose();
    await this.activeAction.stop();
    this.mount = null;
    this.width = null;
    this.height = null;
    this.light = null;
    this.camera = null;
    this.scene = null;
    this.renderer = null;
    this.controls = null;
    this.mixer = null;
    this.modelReady = false;
    this.animationActions = [];
    this.activeAction = null;
    this.lastAction = null;
    this.fbxLoader = null;
    this.gui = null;
    this.clock = null;
    this.animationsFolder = null;
    window.cancelAnimationFrame(this.renderer);
    // window.location.reload();
    // this.setState({
    //     loading: true,
    //     result: '',
    //     trigger: false
    // }, ()=>{

    // })
  }

  async componentDidMount() {
    this.width = this.mount.clientWidth;
    this.height = this.mount.clientHeight;
    this.light = new THREE.PointLight();
    this.camera = new THREE.PerspectiveCamera(
      20,
      this.width / this.height,
      1,
      1000
    );
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.camera.position.set(0.4, 2, 5);
    // scene.add(new THREE.AxesHelper(5))
    this.light.position.set(2.5, 7.5, 15);
    this.scene.add(this.light);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
    this.mount.appendChild(this.renderer.domElement);

    this.controls.enableDamping = true;
    this.controls.target.set(0, 0.8, 0);
    this.controls.update();

    // this.mixer: THREE.AnimationMixer
    // this.modelReady = false
    // const animationActions: THREE.AnimationAction[] = []
    // let activeAction: THREE.AnimationAction
    // let lastAction: THREE.AnimationAction
    this.fbxLoader = new FBXLoader();

    const ellaPath = require("./dist/client/models/Standing Greeting.fbx");
    const ChickenDanceAni = require("./dist/client/models/Chicken Dance.fbx");
    const GangnamStyleAni = require("./dist/client/models/Gangnam Style.fbx");
    const StandingAni = require("./dist/client/models/Idle.fbx");

    await this.fbxLoader.load(
      ellaPath,
      (object) => {
        object.scale.set(0.01, 0.01, 0.01);
        this.mixer = new THREE.AnimationMixer(object);
        const animationAction = this.mixer.clipAction(
          (object as THREE.Object3D).animations[0]
        );
        this.animationActions.push(animationAction);
        this.animationsFolder.add(animations, "default");
        this.activeAction = this.animationActions[0];

        this.scene.add(object);

        //add an animation from another file
        this.fbxLoader.load(
          GangnamStyleAni,
          (object) => {
            console.log("loaded GangnamStyle");

            const animationAction = this.mixer.clipAction(
              (object as THREE.Object3D).animations[0]
            );
            this.animationActions.push(animationAction);
            this.animationsFolder.add(animations, "GangnamStyle");

            //add an animation from another file
            this.fbxLoader.load(
              ChickenDanceAni,
              (object) => {
                console.log("loaded Dance");
                const animationAction = this.mixer.clipAction(
                  (object as THREE.Object3D).animations[0]
                );
                this.animationActions.push(animationAction);
                this.animationsFolder.add(animations, "Dance");

                //add an animation from another file
                this.fbxLoader.load(
                  StandingAni,
                  (object) => {
                    console.log("loaded goofyrunning");
                    // (object as THREE.Object3D).animations[0].tracks.shift() //delete the specific track that moves the object forward while running
                    //console.dir((object as THREE.Object3D).animations[0])
                    const animationAction = this.mixer.clipAction(
                      (object as THREE.Object3D).animations[0]
                    );
                    this.animationActions.push(animationAction);
                    this.animationsFolder.add(animations, "goofyrunning");

                    this.modelReady = true;
                  },
                  (xhr) => {
                    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              },
              (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    const onWindowResize = () => {
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.width, this.height);
      render();
    };
    window.addEventListener("resize", onWindowResize, false);

    const stats = Stats();
    document.body.appendChild(stats.dom);

    const animations = {
      default: () => {
        setAction(this.animationActions[0]);
      },
      GangnamStyle: () => {
        setAction(this.animationActions[1]);
      },
      Dance: () => {
        setAction(this.animationActions[2]);
      },
      goofyrunning: () => {
        setAction(this.animationActions[3]);
      },
    };

    const setAction = (toAction: THREE.AnimationAction) => {
      if (toAction != this.activeAction) {
        this.lastAction = this.activeAction;
        this.activeAction = toAction;
        //lastAction.stop()
        this.lastAction.fadeOut(1);
        this.activeAction.reset();
        this.activeAction.fadeIn(1);
        this.activeAction.play();
      }
    };

    this.gui = new GUI();
    this.animationsFolder = this.gui.addFolder("Animations");
    // animationsFolder.open()

    this.clock = new THREE.Clock();
    const animate = () => {
      requestAnimationFrame(animate);
      if (this.modelReady) {
        this.controls.update();
        this.mixer.update(this.clock.getDelta());
        render();
        stats.update();
      }
    };

    const render = () => {
      this.renderer.render(this.scene, this.camera);
    };

    animate();
  }

  render() {
    return (
      <div
        style={{
          minWidth: "360px",
          minHeight: "500px",
          width: "36vw",
          height: "50vw",
          position: "relative",
          transition: "ease-out 0s",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
        ref={(ref) => (this.mount = ref)}
        className={style.threecomp}
      ></div>
    );
  }
}

export default ThreeComp;
