import axios from "axios";
import {
  GRAMMARLY_CORRECTION_API,
  GRAMMARLY_DIALOG_API,
  GRAMMARLY_PRE_API,
  GRAMMARLY_SIMILAR_SENTENCE_API,
} from "config";
import { loginTimeCheckAPI } from "./CallAPI";

/** 교정문장 API 호출 함수 */
export async function grammarlyCorretionAPI(text: string): Promise<any> {
  if (loginTimeCheckAPI !== undefined) {
    await loginTimeCheckAPI();
}
  let reqUrl = GRAMMARLY_CORRECTION_API;
  let textData = {
    text: text,
  };
  return await axios.post(reqUrl, textData, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
}

/** 유사문장 API 호출 함수 */
export async function grammarlySimilarSentenceAPI(text: string): Promise<any> {
  let reqUrl = GRAMMARLY_SIMILAR_SENTENCE_API;
  let textData = {
    text: text,
  };
  if (text) {
    return await axios.post(reqUrl, textData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
  }
}

// export async function dialogApi(
//   history?: string[][],
//   context?: string,
//   backend?: string
// ): Promise<any> {
//   if (loginTimeCheckAPI !== undefined) {
//     await loginTimeCheckAPI();
//   }
//   const reqUrl = GRAMMARLY_DIALOG_API;
//   const param = {
//     history,
//     context,
//     backend,
//   };
//   return await axios.post(reqUrl, param);
// }

// export async function preApi(): Promise<any> {
//   if (loginTimeCheckAPI !== undefined) {
//     await loginTimeCheckAPI();
// }
//   const reqUrl = GRAMMARLY_PRE_API;
//   return await axios.get(reqUrl, {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// }
